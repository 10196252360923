import useTrackContentCompletion from "@/content-usage/buttons/useTrackContentCompletion"
import StepperContextProvider, {
  StepperContext,
  useStepperContext,
} from "@/core/context/StepperContext"
import MemberOnboardingButtons, {
  MemberOnboardingButtonsProps,
} from "@/member-onboarding/buttons/MemberOnboardingButtons"
import useMemberOnboardingFormStore from "@/member-onboarding/hooks/useMemberOnboardingFormStore"
import useViewersSlackConnection from "@/member-onboarding/hooks/useViewersSlackConnection"
import MemberOnboardingContentBodyWrapper from "@/member-onboarding/MemberOnboardingContentBodyWrapper"
import MemberOnboardingContentHeader from "@/member-onboarding/MemberOnboardingContentHeader"
import { MemberOnboardingModalProps } from "@/member-onboarding/MemberOnboardingModal"
import MemberOnboardingSlackAutoConnectSuccess from "@/member-onboarding/slack-flow/connect-success/MemberOnboardingSlackAutoConnectSuccess"
import MemberOnboardingSlackManualConnectionSteps from "@/member-onboarding/slack-flow/MemberOnboardingSlackManualConnectionSteps"
import { MemberOnboardingSlackContentFragment$key } from "@/member-onboarding/slack-flow/__generated__/MemberOnboardingSlackContentFragment.graphql"
import DiscoIntegrationIcon from "@assets/images/integration-icons/disco-integration-icon.svg"
import SlackIntegrationIcon from "@assets/images/integration-icons/slack-integration-icon.svg"
import { DiscoIcon } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
import { graphql, useFragment } from "react-relay"

interface MemberOnboardingSlackContentProps
  extends TestIDProps,
    Pick<MemberOnboardingButtonsProps, "onSuccess"> {
  usageKey: MemberOnboardingSlackContentFragment$key
  mode: MemberOnboardingModalProps["mode"]
  successButtonTitle?: string
}

function MemberOnboardingSlackContent({
  usageKey,
  mode,
  successButtonTitle,
}: MemberOnboardingSlackContentProps) {
  const { next, previous } = useStepperContext()!

  const { membership } = useViewersSlackConnection()

  const { content, ...usage } = useFragment<MemberOnboardingSlackContentFragment$key>(
    graphql`
      fragment MemberOnboardingSlackContentFragment on ContentUsage {
        viewerHasCompleted
        content {
          systemTaskKind
        }
        ...useMemberOnboardingFormStoreFragment
        ...MemberOnboardingContentHeaderFragment
        ...MemberOnboardingSlackManualConnectionStepsFragment
        ...MemberOnboardingSlackAutoConnectSuccessFragment
        ...useTrackContentCompletionFragment
      }
    `,
    usageKey
  )

  const { submitItemCompletion } = useMemberOnboardingFormStore({
    usageKey: usage,
  })

  const completeContent = useTrackContentCompletion(usage)

  if (
    !content ||
    (content.systemTaskKind !== "slack_connection" &&
      content.systemTaskKind !== "connect_slack")
  )
    return null

  const completeItem = mode === "curriculum-item" ? completeContent : submitItemCompletion

  return (
    <StepperContextProvider steps={[0, 2]} onAfterLast={next} onBeforeFirst={previous}>
      <StepperContext.Consumer>
        {(ctx) => {
          const {
            steps: [activeStep],
          } = ctx!
          return renderStep(activeStep)
        }}
      </StepperContext.Consumer>
    </StepperContextProvider>
  )

  function renderStep(activeStep: number) {
    switch (activeStep) {
      case 0:
        return (
          <MemberOnboardingContentBodyWrapper>
            <MemberOnboardingContentHeader
              usageKey={usage}
              icon={
                <>
                  <DiscoIntegrationIcon width={58} height={58} />
                  <DiscoIcon icon={"switch-horizontal"} />
                  <SlackIntegrationIcon width={58} height={58} />
                </>
              }
            />
            <MemberOnboardingButtons
              {...(usage.viewerHasCompleted
                ? {
                    onSubmit: completeItem,
                    onCustomAction: next,
                    customActionTitle: "Next Step",
                    nextTitle: "View Again",
                  }
                : {
                    nextTitle: "Get Started",
                    onCustomAction: async () => {
                      // mark as complete when skipping for users who don't have Slack
                      await completeItem()
                      next()
                    },
                    customActionTitle: "Skip for now",
                    // don't allow admins previewing onboarding to proceed to next slack step
                    disableNext: mode === "admin-preview",
                    disableNextTooltip:
                      "Members will be able to connect Slack in the next step. Slack onboarding is not available in preview.",
                  })}
            />
          </MemberOnboardingContentBodyWrapper>
        )
      case 1:
        return membership?.slackUserId ? (
          <MemberOnboardingSlackAutoConnectSuccess
            onSubmit={completeItem}
            usageKey={usage}
            submitTitle={successButtonTitle}
            allowAdminPreview={mode === "curriculum-item"}
          />
        ) : (
          <MemberOnboardingSlackManualConnectionSteps
            onSubmit={completeItem}
            disableNext={mode === "curriculum-item"}
            onSkip={next}
            usageKey={usage}
          />
        )
      default:
        return null
    }
  }
}

export default MemberOnboardingSlackContent
