import MemberOnboardingSlackBackgroundWrapper from "@/member-onboarding/slack-flow/MemberOnboardingSlackBackgroundWrapper"
import SlackMobileCarouselBackground from "@assets/images/member-onboarding/slack-mobile-carousel-background.png"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import Swiper from "@components/carousel/Swiper"
import SwiperSlide from "@components/carousel/SwiperSlide"
import DiscoImage from "@disco-ui/image/DiscoImage"
import { TestIDProps } from "@utils/typeUtils"

interface MemberOnboardingSlackMobileCarouselProps extends TestIDProps {
  images: string[]
}

function MemberOnboardingSlackMobileCarousel({
  images,
}: MemberOnboardingSlackMobileCarouselProps) {
  const classes = useStyles()
  return (
    <MemberOnboardingSlackBackgroundWrapper
      src={SlackMobileCarouselBackground}
      classes={{ image: classes.background, content: classes.content }}
    >
      <Swiper
        config={{
          pagination: true,
          slidesPerView: 1,
          totalCount: images.length,
          currentSliceSize: images.length,
        }}
      >
        {images.map((image) => (
          <SwiperSlide key={image}>
            <DiscoImage src={image} className={classes.image} />
          </SwiperSlide>
        ))}
      </Swiper>
    </MemberOnboardingSlackBackgroundWrapper>
  )
}

const useStyles = makeUseStyles({
  image: {
    height: "381px",
    width: "223px",
    objectFit: "contain",
  },
  background: {
    height: "380px",
    width: "420px",
  },
  content: {
    padding: 0,
  },
})

export default MemberOnboardingSlackMobileCarousel
