import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import useShowOnHoverStyles from "@assets/style/util/useShowOnHoverStyles"
import CircularProgressWithLabel from "@components/circular-progress-with-label/CircularProgressWithLabel"
import { initialCropModalState } from "@components/dropzone/FileDropzone"
import ImageCropModal from "@components/image/crop-modal/ImageCropModal"
import { ImageCropModalCropperProps } from "@components/image/crop-modal/util/imageCropModalTypes"
import useMultipartUploadMediaToS3, {
  MediaResult,
} from "@components/media/upload/hooks/useMultipartUploadMediaToS3"
import { DiscoButton } from "@disco-ui"
import DiscoImage from "@disco-ui/image/DiscoImage"
import { useTheme } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { urlToFile } from "@utils/file/fileUtils"
import classNames from "classnames"
import { useState } from "react"
import { v4 as uuidv4 } from "uuid"

interface Props {
  image: string
  onMediaSelect: (result: MediaResult) => void
  onClose: () => void
  handleSubmit: () => void
  cropperProps?: ImageCropModalCropperProps
}

function GeneratedImage(props: Props) {
  const { image, onMediaSelect, onClose, handleSubmit, cropperProps } = props
  const { uploadMediaToS3, uploadProgress } = useMultipartUploadMediaToS3()
  const theme = useTheme()
  const showOnHoverClasses = useShowOnHoverStyles()
  const [cropModalState, setCropModalState] = useState(initialCropModalState)
  const classes = useStyles({ uploadProgress })

  return (
    <>
      <div className={classNames(classes.container, showOnHoverClasses.hoverable)}>
        <DiscoImage src={image} className={classes.generatedImage} />
        {uploadProgress ? (
          <div style={{ backgroundColor: theme.palette.primary.light }}>
            <CircularProgressWithLabel
              color={theme.palette.primary.main}
              value={uploadProgress ?? 0}
              className={classes.overlayButton}
            />
          </div>
        ) : (
          <div className={classNames(classes.overlayButton, showOnHoverClasses.showable)}>
            <DiscoButton
              onClick={async () => {
                const file = await urlToFile(image, `ai-${uuidv4()}.png`, "image/png")
                setCropModalState({
                  isOpen: true,
                  imgSrc: image,
                  file,
                })
              }}
              disabled={uploadProgress !== null}
            >
              {"Use Image"}
            </DiscoButton>
            <DiscoButton
              onClick={handleSubmit}
              leftIcon={"stars"}
              color={"grey"}
              variant={"outlined"}
            >
              {"Regenerate"}
            </DiscoButton>
          </div>
        )}
      </div>
      {cropModalState.isOpen && (
        <ImageCropModal
          isOpen={cropModalState.isOpen}
          onClose={onClose}
          onCancel={() => {
            setCropModalState(initialCropModalState)
          }}
          imageSrc={cropModalState.imgSrc}
          title={"Crop Image"}
          cropperProps={cropperProps}
          onCrop={handleCrop}
        />
      )}
    </>
  )

  async function handleCrop(base64: string) {
    // Hide crop modal and process upload
    setCropModalState(initialCropModalState)
    const result = await uploadMediaToS3({
      mediaFile: await urlToFile(
        base64,
        cropModalState.file!.name,
        cropModalState.file!.type
      ),
      source: "ai",
    })
    onMediaSelect(result)
    onClose()
  }
}

interface StyleProps {
  uploadProgress: number | null
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    position: "relative",
  },
  overlayButton: {
    position: "absolute",
    top: "50%",
    right: "50%",
    transform: "translateY(-50%) translateX(50%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(1.5),
    zIndex: theme.zIndex.raise2,
  },
  generatedImage: ({ uploadProgress }: StyleProps) => ({
    marginTop: theme.spacing(1),
    opacity: uploadProgress ? 0.3 : 1,
  }),
}))

export default Relay.withSkeleton({
  component: GeneratedImage,
  skeleton: () => <Skeleton />,
})
