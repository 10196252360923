import MemberOnboardingButtons from "@/member-onboarding/buttons/MemberOnboardingButtons"
import useMemberOnboardingFormStore from "@/member-onboarding/hooks/useMemberOnboardingFormStore"
import MemberOnboardingContentHeader from "@/member-onboarding/MemberOnboardingContentHeader"
import { MemberOnboardingConstants } from "@/member-onboarding/utils/memberOnboardingConstants"
import { MemberOnboardingCompleteFragment$key } from "@/member-onboarding/__generated__/MemberOnboardingCompleteFragment.graphql"
import ProgressIcon from "@/product/course/curriculum/common/ProgressIcon"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { TestIDProps } from "@utils/typeUtils"
import { graphql, useFragment } from "react-relay"

interface MemberOnboardingCompleteProps extends TestIDProps {
  usageKey: MemberOnboardingCompleteFragment$key
  willCompleteOnboarding: boolean
}

function MemberOnboardingComplete({
  usageKey,
  willCompleteOnboarding,
}: MemberOnboardingCompleteProps) {
  const { content, ...usage } = useFragment<MemberOnboardingCompleteFragment$key>(
    graphql`
      fragment MemberOnboardingCompleteFragment on ContentUsage {
        id
        content {
          id
          type
          systemTaskKind
          ctaText
          ...InlineContentDescriptionSectionFragment
        }
        ...useMemberOnboardingFormStoreFragment
        ...MemberOnboardingContentHeaderFragment
      }
    `,
    usageKey
  )

  const { submitItemCompletion } = useMemberOnboardingFormStore({
    usageKey: usage,
  })

  const classes = useStyles()

  if (!content || content.systemTaskKind !== "onboarding_complete") return null

  return (
    <>
      <ProgressIcon
        completion={1}
        width={40}
        height={40}
        className={classes.completeIcon}
      />
      <MemberOnboardingContentHeader
        {...(willCompleteOnboarding
          ? { usageKey: usage }
          : {
              customContent: {
                name: "Onboarding finished — for now!",
                description:
                  "You'll need to revisit a few steps, but first, why not take a look around?",
                systemTaskKind: content.systemTaskKind,
              },
            })}
      />
      <MemberOnboardingButtons
        nextTitle={
          willCompleteOnboarding
            ? content.ctaText || "Let's start learning"
            : "Explore Community"
        }
        // altho the user may not be completing onboarding, we still want to submit completion for this step because it has been seen
        onSubmit={submitItemCompletion}
      />
    </>
  )
}

const useStyles = makeUseStyles((theme) => ({
  completeIcon: {
    marginBottom: theme.spacing(3),
    // adjust for the modal top margin so the content is centered
    marginTop: `${MemberOnboardingConstants.MARGIN_TOP}`,
  },
}))

export default MemberOnboardingComplete
