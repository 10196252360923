/**
 * @generated SignedSource<<f972007d3e80b6c2b40e1f0d2eeb471f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ContentSystemTaskKind = "slack_connection" | "calendar_connection" | "custom_profile_field" | "community_agreement" | "default_profile_fields" | "onboarding_complete" | "onboarding_welcome" | "download_mobile_app" | "complete_profile" | "slack_invite" | "add_events_to_calendar" | "connect_slack" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type MemberOnboardingContentHeaderFragment$data = {
  readonly content: {
    readonly name: string | null;
    readonly description: string | null;
    readonly systemTaskKind: ContentSystemTaskKind | null;
  };
  readonly " $fragmentType": "MemberOnboardingContentHeaderFragment";
};
export type MemberOnboardingContentHeaderFragment$key = {
  readonly " $data"?: MemberOnboardingContentHeaderFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"MemberOnboardingContentHeaderFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MemberOnboardingContentHeaderFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Content",
      "kind": "LinkedField",
      "name": "content",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "systemTaskKind",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ContentUsage",
  "abstractKey": null
};

(node as any).hash = "71b6625ad5833a9c0b09e9c31d184a77";

export default node;
