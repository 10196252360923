import Badge, { BadgeSize } from "@/admin/experiences/badges/Badge"
import { ProductBadgeFragment$key } from "@/main/page/side-bar/temporary-sidebar/__generated__/ProductBadgeFragment.graphql"
import makeUseStyles from "@assets/style/util/makeUseStyles"

import { graphql, useFragment } from "react-relay"

interface ProductBadgeProps {
  productKey: ProductBadgeFragment$key
  size?: BadgeSize
}

export default function ProductBadge({ productKey, size = 48 }: ProductBadgeProps) {
  const { badge } = useFragment<ProductBadgeFragment$key>(
    graphql`
      fragment ProductBadgeFragment on Product {
        badge {
          ...BadgeFragment
        }
      }
    `,
    productKey
  )
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Badge badgeKey={badge!} size={size} />
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    gap: theme.spacing(1),
  },
}))
