import MembershipPlanSelectBody, {
  MembershipPlanSelectBodyProps,
} from "@/membership-plan/register/MembershipPlanSelectBody"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoModal, DiscoModalProps } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"

type Props = TestIDProps &
  DiscoModalProps &
  Partial<Pick<MembershipPlanSelectBodyProps, "experienceId">>

function MembershipPlanSelectModal({
  testid = "MembershipPlanSelectModal",
  onClose,
  experienceId,
  ...rest
}: Props) {
  const classes = useStyles()

  return (
    <DiscoModal
      {...rest}
      onClose={onClose}
      testid={testid}
      // Full screen modal: 8 = theme spacing unit * 2 units * 2 sides
      maxHeight={`calc(100vh - ${8 * 2 * 2}px)`}
      maxWidth={`calc(100vw - ${8 * 2 * 2}px)`}
      height={`calc(100vh - ${8 * 2 * 2}px)`}
      width={`calc(100vw - ${8 * 2 * 2}px)`}
      classes={{ headerRow: classes.headerRow }}
      body={
        <MembershipPlanSelectBody
          testid={"MembershipPlanSelectModal"}
          experienceId={experienceId}
          onClose={onClose}
        />
      }
    />
  )
}

const useStyles = makeUseStyles({
  headerRow: {
    // Remove the header row since it's being controlled by the content
    display: "none",
  },
})

export default observer(MembershipPlanSelectModal)
