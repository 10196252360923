import { AssetFileType } from "@/admin/media-library/__generated__/AdminMediaLibraryListPagePaginationQuery.graphql"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import MediaLibraryModal, { MEDIA_LIBRARY_TABS } from "@/media/add/UploadMediaModal"
import CircularProgressWithLabel from "@components/circular-progress-with-label/CircularProgressWithLabel"
import {
  ALL_FILE_TYPES,
  FileDropzoneSuggestedDimensions,
  MimeTypes,
} from "@components/dropzone/FileDropzone"
import { ImageCropModalCropperProps } from "@components/image/crop-modal/util/imageCropModalTypes"
import MediaUploadButton from "@components/media/upload/MediaUploadButton"
import { MediaResult } from "@components/media/upload/hooks/useMultipartUploadMediaToS3"
import { DiscoButton } from "@disco-ui"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonProps,
} from "@disco-ui/button/OverridableDiscoButton"
import { PopoverOrigin } from "@material-ui/core"
import { createElement, isValidElement, useState } from "react"

import { DropzoneOptions } from "react-dropzone"

export interface MediaLibraryButtonProps extends OverridableDiscoButtonProps {
  onUpload: (result: MediaResult, file: File) => void
  onMediaSelect: (result: MediaResult) => void
  cropperProps?: ImageCropModalCropperProps
  dropzoneOptions: Omit<DropzoneOptions, "onDrop">
  suggestedDimensions?: FileDropzoneSuggestedDimensions
  enforceSuggestedDimensions?: boolean
  message?: React.ReactElement | string
  allowedFileTypes?: AssetFileType[]
  defaultTab?: MEDIA_LIBRARY_TABS
  PopoverProps?: Partial<{
    anchorOrigin: PopoverOrigin
    transformOrigin: PopoverOrigin
  }>
  includeOrganizationIdOnAsset?: boolean
  includeInMediaLibrary?: boolean
  allowedMimeTypes?: MimeTypes[]
}

function MediaLibraryButton({
  children,
  onUpload,
  onMediaSelect,
  cropperProps,
  dropzoneOptions,
  suggestedDimensions,
  enforceSuggestedDimensions,
  message,
  allowedFileTypes,
  PopoverProps,
  defaultTab = "my-files",
  includeOrganizationIdOnAsset,
  includeInMediaLibrary,
  allowedMimeTypes,
  ...rest
}: MediaLibraryButtonProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const activeOrganization = useActiveOrganization()

  if (!activeOrganization?.viewerPermissions.has("assets.read")) {
    return (
      <MediaUploadButton
        onSuccess={onUpload}
        accept={dropzoneOptions.accept || ALL_FILE_TYPES}
        includeOrganizationIdOnAsset={includeOrganizationIdOnAsset}
        includeInMediaLibrary={includeInMediaLibrary}
        {...rest}
      >
        {isValidElement(children) || typeof children === "string"
          ? children
          : ({ uploadProgress, ...buttonProps }) =>
              uploadProgress === null ? (
                createElement(children, { ...buttonProps, testid: rest.testid })
              ) : (
                <DiscoButton {...buttonProps} disabled={true}>
                  <CircularProgressWithLabel value={uploadProgress} color={"white"} />
                </DiscoButton>
              )}
      </MediaUploadButton>
    )
  }

  return (
    <>
      <OverridableDiscoButton {...rest} onClick={handleClick}>
        {children}
      </OverridableDiscoButton>
      <MediaLibraryModal
        onClose={handleClose}
        onUpload={onUpload}
        onMediaSelect={onMediaSelect}
        cropperProps={cropperProps}
        dropzoneOptions={dropzoneOptions}
        suggestedDimensions={suggestedDimensions}
        enforceSuggestedDimensions={enforceSuggestedDimensions}
        message={message}
        allowedFileTypes={allowedFileTypes}
        defaultTab={defaultTab}
        allowedMimeTypes={allowedMimeTypes}
        PopoverProps={{
          anchorEl,
          anchorOrigin: {
            vertical: PopoverProps?.anchorOrigin?.vertical || "top",
            horizontal: PopoverProps?.anchorOrigin?.horizontal || "left",
          },
          transformOrigin: {
            vertical: PopoverProps?.transformOrigin?.vertical || "top",
            horizontal: PopoverProps?.transformOrigin?.horizontal || "center",
          },
        }}
        includeOrganizationIdOnAsset={includeOrganizationIdOnAsset}
        includeInMediaLibrary={includeInMediaLibrary}
      />
    </>
  )

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget)
  }
  function handleClose() {
    setAnchorEl(null)
  }
}
export default MediaLibraryButton
