import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useMemberOnboardingQueryParams } from "@/member-onboarding/hooks/useMemberOnboardingQueryParams"
import MemberOnboardingModal from "@/member-onboarding/MemberOnboardingModal"
import { MemberOnboardingBannerQuery } from "@/member-onboarding/__generated__/MemberOnboardingBannerQuery.graphql"
import { MemberOnboardingBannerRefetchableFragment_usage$key } from "@/member-onboarding/__generated__/MemberOnboardingBannerRefetchableFragment_usage.graphql"
import { MemberOnboardingBannerRefetchQuery } from "@/member-onboarding/__generated__/MemberOnboardingBannerRefetchQuery.graphql"

import useMemberShouldOnboard from "@/member-onboarding/hooks/useMemberShouldOnboard"
import useIsAdminViewingAsMember from "@/product/util/hook/useIsAdminViewingAsMember"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoLink, DiscoText } from "@disco-ui"
import { Toolbar, useTheme } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import { useState } from "react"
import { graphql, useLazyLoadQuery, useRefetchableFragment } from "react-relay"

function MemberOnboardingBanner({ testid = "MemberOnboardingBanner" }: TestIDProps) {
  const isViewingAsMember = useIsAdminViewingAsMember()
  const activeOrganization = useActiveOrganization()
  const { viewerMembership } = activeOrganization || {}
  const { memberOnboardingStep } = useMemberOnboardingQueryParams()

  const classes = useStyles()
  const theme = useTheme()

  const shouldOnboard = useMemberShouldOnboard()

  const { memberOnboarding } = useLazyLoadQuery<MemberOnboardingBannerQuery>(
    graphql`
      query MemberOnboardingBannerQuery(
        $membershipId: ID!
        $includeDraft: Boolean
        $shouldOnboard: Boolean!
      ) {
        memberOnboarding(membershipId: $membershipId) @include(if: $shouldOnboard) {
          id
          ...MemberOnboardingBannerRefetchableFragment_usage
          ...MemberOnboardingModalFragment @arguments(includeDraft: $includeDraft)
          content {
            id
            children(includeDraft: $includeDraft) {
              edges {
                node {
                  id
                  viewerHasCompleted
                  content {
                    id
                    systemTaskKind
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      membershipId: viewerMembership?.id || "",
      includeDraft: activeOrganization?.viewerIsOwnerOrAdmin,
      shouldOnboard,
    }
  )

  const [usage, refetch] = useRefetchableFragment<
    MemberOnboardingBannerRefetchQuery,
    MemberOnboardingBannerRefetchableFragment_usage$key
  >(
    graphql`
      fragment MemberOnboardingBannerRefetchableFragment_usage on ContentUsage
      @refetchable(queryName: "MemberOnboardingBannerRefetchQuery") {
        id
        viewerHasCompleted
      }
    `,
    memberOnboarding || null
  )
  const { viewerHasCompleted } = usage || {}

  const steps = Relay.connectionToArray(memberOnboarding?.content?.children)

  // admins preview onboarding in admin console, and should never see banner
  const adminShouldNotOnboard =
    activeOrganization?.viewerIsOwnerOrAdmin || isViewingAsMember
  // users who have completed the module never see onboarding
  const memberShouldNotOnboard =
    !activeOrganization || !viewerMembership || viewerHasCompleted

  const [isOpen, setIsOpen] = useState(
    shouldBannerOpenByDefault() || Boolean(memberOnboardingStep)
  )

  // never show the banner if there are no published steps to complete
  if (
    !memberOnboarding ||
    !steps.length ||
    memberShouldNotOnboard ||
    adminShouldNotOnboard
  )
    return null

  const { text, cta } = renderBannerText()

  return (
    <>
      <Toolbar classes={{ root: classes.toolbar }} data-testid={testid}>
        <DiscoText display={"inline"} color={"common.white"}>
          {text}
        </DiscoText>
        <DiscoLink
          data-testid={`${testid}.cta`}
          onClick={() => setIsOpen(true)}
          textDecoration={"underline"}
          color={theme.palette.common.white}
        >
          {`${cta} \u{2192}`}
        </DiscoLink>
      </Toolbar>
      {/* mount/unmount the modal on open/close so we re-evaluate the starting step on each open */}
      {isOpen && (
        <MemberOnboardingModal
          isOpen
          memberOnboardingKey={memberOnboarding}
          onClose={onClose}
        />
      )}
    </>
  )

  function onClose() {
    // check if the module has been completed so the banner will unmount
    refetch({}, { fetchPolicy: "network-only" })
    setIsOpen(false)
  }

  function renderBannerText() {
    const firstIncompleteIndex = steps.findIndex((step) => !step.viewerHasCompleted)
    // we get a TS error using `lastIndexOf` here, looks like it's only available in 5.0.2 so we use a for loop instead
    // https://github.com/microsoft/TypeScript/issues/48829
    let lastIncompleteIndex = -1
    for (let i = steps.length - 1; i >= 0; i--) {
      if (!steps[i].viewerHasCompleted) {
        lastIncompleteIndex = i
        break
      }
    }
    const hasOneIncompleteStep =
      firstIncompleteIndex === lastIncompleteIndex && firstIncompleteIndex !== -1
    // slack is only incomplete item
    if (
      hasOneIncompleteStep &&
      steps[firstIncompleteIndex].content.systemTaskKind === "slack_connection"
    )
      return {
        text: "Connect your Slack account to complete onboarding.",
        cta: "Connect Slack",
      }
    // outlook is only incomplete item
    if (
      hasOneIncompleteStep &&
      steps[firstIncompleteIndex].content.systemTaskKind === "calendar_connection"
    )
      return {
        text: "Connect your Outlook calendar to complete onboarding.",
        cta: "Connect Outlook",
      }
    // profile fields is only incomplete item
    if (
      hasOneIncompleteStep &&
      steps[firstIncompleteIndex].content.systemTaskKind === "custom_profile_field"
    )
      return {
        text: `Complete your profile to wrap up the onboarding process and help members get to know you better.`,
        cta: "Complete profile",
      }
    // Many incomplete steps (or none, but then the banner should not be rendered)
    return {
      text: `Finish onboarding to get the most out of ${activeOrganization!.name}. 🙌`,
      cta: "Jump back in",
    }
  }

  function shouldBannerOpenByDefault() {
    // Don't open banner if member should not onboard
    if (memberShouldNotOnboard) return false

    // If member onboarding is mandatory, force open the banner unless the member has completed all steps
    if (activeOrganization?.isMemberOnboardingRequired)
      return steps.some((s) => {
        // For slack connection, the user may encounter technical issues that we cannot control, so we don't want to force them to complete it
        if (s.content.systemTaskKind === "slack_connection") return false
        return !s.viewerHasCompleted
      })

    return !viewerMembership.hasSeenOnboarding
  }
}

const useStyles = makeUseStyles((theme) => ({
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(1),
  },
}))

export default Relay.withSkeleton({
  skeleton: () => null,
  component: MemberOnboardingBanner,
})

// eslint-disable-next-line no-unused-expressions
graphql`
  fragment MemberOnboardingBanner_SlackWorkspaceFragment on ContentUsage {
    content {
      organization {
        slack {
          workspace {
            name
            url
          }
        }
      }
    }
  }
`
