import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useFormStore } from "@/core/form/store/FormStore"
import { useMemberOnboardingFormStoreFragment$key } from "@/member-onboarding/hooks/__generated__/useMemberOnboardingFormStoreFragment.graphql"
import {
  CompleteMemberOnboardingItemInput,
  useMemberOnboardingFormStoreMutation,
} from "@/member-onboarding/hooks/__generated__/useMemberOnboardingFormStoreMutation.graphql"
import { WebFormAnswerInput } from "@/web-form/utils/webFormFillerUtils"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface MemberOnboardingFormStoreProps {
  usageKey: useMemberOnboardingFormStoreFragment$key
  customProfileFieldInput?: WebFormAnswerInput
}

function useMemberOnboardingFormStore({
  usageKey,
  customProfileFieldInput,
}: MemberOnboardingFormStoreProps) {
  const activeOrganization = useActiveOrganization()
  if (!activeOrganization?.viewerMembership?.id)
    throw new Error("Can't view onboarding without a viewer membership.")

  const usage = useFragment<useMemberOnboardingFormStoreFragment$key>(
    graphql`
      fragment useMemberOnboardingFormStoreFragment on ContentUsage {
        id
        viewerHasCompleted
      }
    `,
    usageKey
  )

  const form = useFormStore<
    useMemberOnboardingFormStoreMutation,
    Pick<CompleteMemberOnboardingItemInput, "contentCompletionInput"> & {
      customProfileFieldInput?: WebFormAnswerInput
    }
  >(
    graphql`
      mutation useMemberOnboardingFormStoreMutation(
        $input: CompleteMemberOnboardingItemInput!
      ) {
        response: completeMemberOnboardingItem(input: $input) {
          node {
            id
            contentUsage {
              id
              viewerHasCompleted
            }
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      contentCompletionInput: {
        contentUsageId: usage.id,
        membershipId: activeOrganization.viewerMembership.id,
      },
      customProfileFieldInput,
    }
  )

  return { form, submitItemCompletion }

  async function submitItemCompletion() {
    const { didSave, response } = await form.submit({
      contentCompletionInput: form.state.contentCompletionInput,
      customProfileFieldInput: form.state.customProfileFieldInput
        ? {
            body: form.state.customProfileFieldInput.body,
            selectedOptions: form.state.customProfileFieldInput.selectedOptions,
          }
        : undefined,
    })

    return { didSave, completion: response?.node }
  }
}

export default useMemberOnboardingFormStore
