import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import MemberOnboardingButtonsContainer from "@/member-onboarding/buttons/MemberOnboardingButtonsContainer"
import MemberOnboardingCustomActionButton from "@/member-onboarding/buttons/MemberOnboardingCustomActionButton"
import MemberOnboardingPreviousButton from "@/member-onboarding/buttons/MemberOnboardingPreviousButton"
import useViewersSlackConnection from "@/member-onboarding/hooks/useViewersSlackConnection"
import MemberOnboardingContentBodyWrapper from "@/member-onboarding/MemberOnboardingContentBodyWrapper"
import MemberOnboardingContentHeader from "@/member-onboarding/MemberOnboardingContentHeader"
import SlackEmail from "@/member-onboarding/slack-flow/invite/SlackEmail"
import { MemberOnboardingSlackRequestInvite_PrimarySlackConnectionQuery } from "@/member-onboarding/slack-flow/invite/__generated__/MemberOnboardingSlackRequestInvite_PrimarySlackConnectionQuery.graphql"
import MemberOnboardingSlackBackgroundWrapper from "@/member-onboarding/slack-flow/MemberOnboardingSlackBackgroundWrapper"
import CreateSlackUserInviteButton from "@/slack/slack-user-invite/CreateSlackUserInviteButton"
import SlackEmailPreviewBackground from "@assets/images/member-onboarding/slack-email-preview-background.png"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoButton, DiscoLink } from "@disco-ui"
import useInterval from "@utils/hook/useInterval"
import { TestIDProps } from "@utils/typeUtils"
import { useState } from "react"
import { useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"
import MemberOnboardingSlackRefreshInfo from "../MemberOnboardingSlackRefreshInfo"

type MemberOnboardingSlackRequestInviteProps = TestIDProps & {
  onSkip: VoidFunction
  onPrevious: VoidFunction
}

function MemberOnboardingSlackRequestInvite({
  testid = "MemberOnboardingSlackRequestInvite",
  onPrevious,
  onSkip,
}: MemberOnboardingSlackRequestInviteProps) {
  const activeOrganization = useActiveOrganization()!
  const { membership, refetch } = useViewersSlackConnection()
  const [isSent, setIsSent] = useState(Boolean(membership?.viewerSlackUserInvite?.id))
  const [hasSlackError, setHasSlackError] = useState(false)
  const isPolling = isSent || hasSlackError

  // If the invite is already sent, poll to see when the organizationMembership has a slackUserId
  useInterval(
    () => {
      refetch()
    },
    1000 * 15, // 15 seconds
    { shouldStartInterval: isPolling }
  )

  const { organization } =
    useLazyLoadQuery<MemberOnboardingSlackRequestInvite_PrimarySlackConnectionQuery>(
      graphql`
        query MemberOnboardingSlackRequestInvite_PrimarySlackConnectionQuery($id: ID!) {
          organization: node(id: $id) {
            ... on Organization {
              id
              user: primarySlackConnectionUserInfo {
                fullName
                firstName
                lastName
                email
                avatar
              }
              slack {
                workspace {
                  name
                  url
                }
              }
            }
          }
        }
      `,
      { id: activeOrganization.id }
    )

  const classes = useStyles()

  const { workspace } = organization?.slack || {}
  const { user } = organization || {}

  return (
    <MemberOnboardingContentBodyWrapper classes={{ content: classes.wrapperContent }}>
      <MemberOnboardingContentHeader
        testid={testid}
        customContent={{
          name: hasSlackError ? (
            "Hmm.. You've already requested a workspace invitation!"
          ) : (
            <>
              {"Request a "}
              {workspace?.name ? (
                <DiscoLink
                  href={workspace.url || ""}
                  style={{ fontSize: "inherit", lineHeight: "inherit" }}
                >
                  {`${workspace.name} `}
                </DiscoLink>
              ) : null}
              {"Slack workspace invite via email"}
            </>
          ),
          description: hasSlackError
            ? "Check your email for an invite that looks like the one below."
            : `Your invite will come from ${
                user?.email || "the community admin"
              }. This process can take a few moments - Hang tight!`,
        }}
      />
      <MemberOnboardingButtonsContainer testid={testid}>
        <CreateSlackUserInviteButton
          onSuccess={() => setIsSent(true)}
          onSlackError={() => {
            setHasSlackError(true)
            setIsSent(true)
          }}
        >
          {(props) => (
            <DiscoButton
              leftIcon={isSent ? "check" : "send"}
              {...props}
              disabled={isSent}
            >
              {isSent ? "Requested" : "Request Invite"}
            </DiscoButton>
          )}
        </CreateSlackUserInviteButton>
        <MemberOnboardingCustomActionButton
          customActionTitle={"Skip for now"}
          onCustomAction={onSkip}
        />
        <MemberOnboardingPreviousButton customPreviousAction={onPrevious} />
      </MemberOnboardingButtonsContainer>

      {isPolling && <MemberOnboardingSlackRefreshInfo />}

      <MemberOnboardingSlackBackgroundWrapper
        src={SlackEmailPreviewBackground}
        classes={{
          content: classes.emailContent,
          image: classes.img,
        }}
      >
        <SlackEmail
          className={classes.svg}
          workspaceName={organization?.slack?.workspace?.name}
          {...organization?.user}
        />
      </MemberOnboardingSlackBackgroundWrapper>
    </MemberOnboardingContentBodyWrapper>
  )
}

const useStyles = makeUseStyles(() => ({
  svg: {
    height: "auto",
    marginBottom: -4,
  },
  emailContent: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  img: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    maxWidth: "1000px",
  },
  wrapperContent: {
    height: "100%",
    justifyContent: "space-between",
  },
}))

export default MemberOnboardingSlackRequestInvite
