/**
 * @generated SignedSource<<70c58346151de68269ea3289a6dd73a4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MemberOnboardingCustomProfileFieldQuestionFragment_profileValue$data = {
  readonly webFormAnswer: {
    readonly body: string | null;
    readonly isSkipped: boolean;
    readonly selectedOptions: ReadonlyArray<string> | null;
  } | null;
  readonly " $fragmentType": "MemberOnboardingCustomProfileFieldQuestionFragment_profileValue";
};
export type MemberOnboardingCustomProfileFieldQuestionFragment_profileValue$key = {
  readonly " $data"?: MemberOnboardingCustomProfileFieldQuestionFragment_profileValue$data;
  readonly " $fragmentSpreads": FragmentRefs<"MemberOnboardingCustomProfileFieldQuestionFragment_profileValue">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MemberOnboardingCustomProfileFieldQuestionFragment_profileValue",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "WebFormAnswer",
      "kind": "LinkedField",
      "name": "webFormAnswer",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "body",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isSkipped",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "selectedOptions",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ProfileValue",
  "abstractKey": null
};

(node as any).hash = "ba33109bc3a47572bc84b561471859d6";

export default node;
