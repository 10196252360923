import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import MetaDetails, { MetaDetailsProps } from "@/main/page/MetaDetails"
import { DiscoSection } from "@disco-ui"
import { Grid } from "@material-ui/core"
import React from "react"
import PageContent from "./content/PageContent"

type OnboardingPageProps = {
  leftContent?: React.ReactNode
  rightContent?: React.ReactNode
} & Pick<MetaDetailsProps, "metaTitle" | "metaDetailsForSharing">

const OnboardingPage: React.FC<OnboardingPageProps> = (props) => {
  const { leftContent, rightContent, children, metaTitle, metaDetailsForSharing } = props

  const classes = useStyles()

  return (
    <MetaDetails metaTitle={metaTitle} metaDetailsForSharing={metaDetailsForSharing}>
      <PageContent
        classes={{
          container: classes.pageContentContainer,
          content: classes.pageContent,
        }}
      >
        <DiscoSection className={classes.section}>
          {(leftContent || rightContent) && (
            <Grid container spacing={3} className={classes.gridContainer}>
              <Grid item xs={12} md={6}>
                {leftContent}
              </Grid>
              <Grid item xs={12} md={6}>
                {rightContent}
              </Grid>
            </Grid>
          )}
          {children}
        </DiscoSection>
      </PageContent>
    </MetaDetails>
  )
}

export default OnboardingPage

const useStyles = makeUseStyles((theme) => ({
  pageContentContainer: {
    justifyContent: "center",
    padding: 0,
  },
  pageContent: {
    width: "100%",
    flexGrow: 1,
    display: "flex",
    [theme.breakpoints.up("md")]: {
      padding: "80px 64px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "50px 16px",
    },
  },
  gridContainer: {
    [theme.breakpoints.up("md")]: {
      height: "calc(100% + 24px)",
    },
  },
  section: {
    width: "100%",
    height: "fit-content",
    maxWidth: "1140px",
    margin: "0 auto",
    boxShadow: `0px 1px 1px rgba(46, 64, 88, 0.06), 0px 8px 24px rgba(46, 64, 88, 0.08)`,
    borderRadius: theme.measure.borderRadius.big,
    [theme.breakpoints.up("md")]: {
      padding: "24px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "32px 24px",
    },
  },
}))
