import { GlobalID } from "@/relay/RelayTypes"
import { ConnectionHandler } from "relay-runtime"

namespace InvitationUtils {
  // Get the connections to update
  export function getConnections(organizationId: GlobalID, productId: GlobalID | null) {
    const connections = [
      // We definitely want to add the new invitaiton to the community invites
      ConnectionHandler.getConnectionID(
        organizationId,
        "CommunityInvitationReportTable_invitations",
        { productIds: ["all"] }
      ),
      ConnectionHandler.getConnectionID(
        organizationId,
        "CommunityInvitationReportTable_invitations",
        { productIds: [] }
      ),
    ]

    if (productId) {
      // If this is a product invites, add it to the community invites with product id filter as well
      connections.push(
        ConnectionHandler.getConnectionID(
          productId,
          "CommunityInvitationReportTable_invitations",
          { productIds: [productId] }
        )
      )
      // add it to the product invites
      connections.push(
        ConnectionHandler.getConnectionID(
          productId,
          "ProductInvitationReportTable_invitations"
        )
      )
    }
    return connections
  }
}

export default InvitationUtils
