import { CommunityAppBarProps } from "@/community/CommunityAppBar"
import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import { useNotificationsContext } from "@/core/context/NotificationsContext"
import GlobalNavButton from "@/main/page/global-nav/GlobalNavButton"
import BellWithNotificationIcon from "@assets/iconsax/linear/custom-notification-pin.svg"
import { DiscoIcon } from "@disco-ui"
import useSubscriptionStanding from "@utils/hook/useSubscriptionStanding"
import { TestIDProps } from "@utils/typeUtils"

interface NotificationCenterButtonProps
  extends TestIDProps,
    Pick<CommunityAppBarProps, "variant"> {}

function NotificationCenterButton({
  testid = "NotificationCenterButton",
  variant,
}: NotificationCenterButtonProps) {
  const drawer = useGlobalDrawer("notificationCenter")
  const { totalUnreadCount } = useNotificationsContext()
  const subscriptionStanding = useSubscriptionStanding()
  if (subscriptionStanding !== "active") {
    return <></>
  }

  return (
    <GlobalNavButton
      testid={testid}
      onClick={handleOpenNotificationCenter}
      tooltip={"Notification Center"}
      variant={variant}
    >
      {totalUnreadCount > 0 ? <BellWithNotificationIcon /> : <DiscoIcon icon={"bell"} />}
    </GlobalNavButton>
  )

  function handleOpenNotificationCenter() {
    drawer.open({
      notificationsDrawer: "1",
    })
  }
}

export default NotificationCenterButton
