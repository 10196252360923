import useSearchClient from "@/apps/util/hooks/useSearchClient"
import { CommunityAppBarProps } from "@/community/CommunityAppBar"
import { useSearchContext } from "@/core/context/SearchContext"
import GlobalNavButton from "@/main/page/global-nav/GlobalNavButton"
import { DiscoIcon } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"

interface SearchButtonProps extends TestIDProps, Pick<CommunityAppBarProps, "variant"> {}

function SearchButton({ testid = "SearchButton", variant }: SearchButtonProps) {
  const { openSearch } = useSearchContext()
  const search = useSearchClient()

  if (!search) return null

  return (
    <GlobalNavButton
      testid={testid}
      onClick={handleOpen}
      tooltip={"Search"}
      variant={variant}
    >
      <DiscoIcon icon={"search"} />
    </GlobalNavButton>
  )

  function handleOpen() {
    openSearch?.()
  }
}

export default SearchButton
