import AttachmentListItem from "@/content/attachment/list/item/AttachmentListItem"
import { useLabel } from "@/core/context/LabelsContext"
import FormStore from "@/core/form/store/FormStore"
import { CreateInvitationFormState } from "@/invitation/create/form/CreateInvitationForm"
import InvitationFormCSVPreview from "@/invitation/create/form/fields/InvitationFormCSVPreview"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import FileDropzone from "@components/dropzone/FileDropzone"
import { MediaResult } from "@components/media/upload/hooks/useMultipartUploadMediaToS3"
import { DiscoAlert, DiscoFormControl, DiscoInput, DiscoLink, DiscoText } from "@disco-ui"
import { observer } from "mobx-react-lite"

interface Props {
  form: FormStore<CreateInvitationFormState>
  testid?: string
}

function InvitationFormImportCSVField(props: Props) {
  const { form, testid = "InvitationFormImportCSVField" } = props
  const classes = useStyles()

  const membersLabel = useLabel("organization_member")

  return (
    <>
      <DiscoText variant={"body-sm"} color={"text.secondary"} marginBottom={1}>
        {`Invite up to 1000 ${membersLabel.plural} at once using a CSV file. `}

        <DiscoLink
          to={"https://support.disco.co/hc/en-us/articles/16184475465492-Invites"}
          target={"_blank"}
          textVariant={"body-sm"}
        >
          {"Read More"}
        </DiscoLink>
        <br />
        <br />
        {`Your CSV file must include a header with Email, First Name and Last Name. If adding ${membersLabel.plural} to a Group, make sure the Group is created prior to upload and the Group name matches exactly.`}
        <br />
        <DiscoLink
          to={"https://cdn.disco.co/invites/disco-invite-sample.csv"}
          target={"_blank"}
          rel={"noopener noreferrer"}
          textVariant={"body-sm"}
        >
          {"Download Example CSV"}
        </DiscoLink>
      </DiscoText>

      <DiscoFormControl>
        {form.state.csvMetadata!.file && form.state.csvUrl ? (
          <AttachmentListItem
            attachment={{
              name: form.state.csvMetadata!.file!.name,
              id: form.state.csvUrl!,
              mediaUrl: form.state.csvUrl,
            }}
            testid={`${testid}.csv-attachment`}
            onClickDelete={handleRemoveFile}
            error={Boolean(form.errorsByField.csvUrl?.length)}
          />
        ) : (
          <FileDropzone
            testid={testid}
            dropzoneOptions={{
              accept: { "text/csv": [".CSV (Max: 40GB and 1000 Members)"] },
            }}
            onUpload={handleUpload}
            privateObject
            temporary
            message={
              <DiscoText variant={"body-sm-700"}>
                {"Drop your file here or "}
                <DiscoLink className={classes.browseText}>{"browse"}</DiscoLink>
              </DiscoText>
            }
          />
        )}
      </DiscoFormControl>
      {form.errorsByField.csvUrl && (
        <DiscoAlert
          message={form.errorsByField.csvUrl}
          severity={"error"}
          marginBottom={2}
        />
      )}
      {renderCSVStep()}
    </>
  )

  function handleUpload(result: MediaResult, file: File) {
    form.state.csvUrl = decodeURIComponent(result.url)
    form.state.csvMetadata!.file = file
  }

  function handleRemoveFile() {
    // Reset all csv states
    form.state.csvMetadata = {}
    form.state.csvUrl = ""
    form.state.csvStep = "default"
  }

  function renderCSVStep() {
    if (form.state.csvStep === "preview") {
      return <InvitationFormCSVPreview form={form} />
    }

    return (
      <DiscoFormControl
        label={"Personalize the invite"}
        error={Boolean(form.errorsByField.message)}
        errorMessages={form.errorsByField.message}
      >
        <DiscoInput
          data-testid={`${testid}.personalizedMessage-textarea`}
          name={"personalizedMessage-textarea"}
          value={form.state.message}
          onChange={(e) => (form.state.message = e.currentTarget.value)}
          placeholder={"Enter a personalized message"}
          multiline
          minHeight={"120px"}
        />
      </DiscoFormControl>
    )
  }
}

const useStyles = makeUseStyles({
  browseText: {
    textDecoration: "underline",
  },
})

export default observer(InvitationFormImportCSVField)
