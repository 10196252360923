import useHasEntitlement from "@utils/hook/useHasEntitlement"
import { Helmet } from "react-helmet-async"

interface Props {
  children?: string
}

/**
 * Standard method to set the page title that removes "Disco" branding for paid communities.
 */
function MetaTitle(props: Props) {
  const { children } = props

  const hasCustomBranding = useHasEntitlement("brand_customization")

  if (!children) return null

  return (
    <Helmet>
      <title>{hasCustomBranding ? children : `Disco - ${children}`}</title>
    </Helmet>
  )
}

export default MetaTitle
