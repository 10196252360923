/**
 * @generated SignedSource<<5575d1fc754abe835d3f3a7a8feabd4c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ContentSystemTaskKind = "slack_connection" | "calendar_connection" | "custom_profile_field" | "community_agreement" | "default_profile_fields" | "onboarding_complete" | "onboarding_welcome" | "download_mobile_app" | "complete_profile" | "slack_invite" | "add_events_to_calendar" | "connect_slack" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type MemberOnboardingCalendarContentFragment$data = {
  readonly viewerHasCompleted: boolean;
  readonly content: {
    readonly systemTaskKind: ContentSystemTaskKind | null;
  };
  readonly " $fragmentSpreads": FragmentRefs<"MemberOnboardingContentHeaderFragment" | "useMemberOnboardingFormStoreFragment" | "useTrackContentCompletionFragment">;
  readonly " $fragmentType": "MemberOnboardingCalendarContentFragment";
};
export type MemberOnboardingCalendarContentFragment$key = {
  readonly " $data"?: MemberOnboardingCalendarContentFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"MemberOnboardingCalendarContentFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MemberOnboardingCalendarContentFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerHasCompleted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Content",
      "kind": "LinkedField",
      "name": "content",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "systemTaskKind",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MemberOnboardingContentHeaderFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useMemberOnboardingFormStoreFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useTrackContentCompletionFragment"
    }
  ],
  "type": "ContentUsage",
  "abstractKey": null
};

(node as any).hash = "833aff353b10ae2d54f8329346a260c7";

export default node;
