import MemberOnboardingContentBodyWrapper from "@/member-onboarding/MemberOnboardingContentBodyWrapper"
import MemberOnboardingContentHeader from "@/member-onboarding/MemberOnboardingContentHeader"
import MemberOnboardingButtons, {
  MemberOnboardingButtonsProps,
} from "@/member-onboarding/buttons/MemberOnboardingButtons"
import MemberOnboardingSlackMobileCarousel from "@/member-onboarding/slack-flow/MemberOnboardingSlackMobileCarousel"
import { MemberOnboardingSlackAutoConnectSuccessFragment$key } from "@/member-onboarding/slack-flow/connect-success/__generated__/MemberOnboardingSlackAutoConnectSuccessFragment.graphql"
import SlackAutoSuccessMobile1 from "@assets/images/member-onboarding/slack-auto-connect-mobile-1.png"
import SlackAutoSuccessMobile2 from "@assets/images/member-onboarding/slack-auto-connect-mobile-2.png"
import SlackAutoSuccess from "@assets/images/member-onboarding/slack-auto-connection.png"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoLink, DiscoTooltip } from "@disco-ui"
import DiscoImage from "@disco-ui/image/DiscoImage"
import useIsMobileAgent from "@utils/hook/useIsMobileAgent"
import { TestIDProps } from "@utils/typeUtils"
import { graphql, useFragment } from "react-relay"

const MOBILE_IMAGES = [SlackAutoSuccessMobile1, SlackAutoSuccessMobile2]

type MemberOnboardingSlackAutoConnectSuccessProps = TestIDProps &
  Pick<MemberOnboardingButtonsProps, "onSubmit" | "onSuccess" | "allowAdminPreview"> & {
    usageKey: MemberOnboardingSlackAutoConnectSuccessFragment$key
    submitTitle?: string
  }

function MemberOnboardingSlackAutoConnectSuccess({
  testid = "MemberOnboardingSlackAutoConnectSuccess",
  onSubmit,
  usageKey,
  allowAdminPreview,
  onSuccess,
  submitTitle,
}: MemberOnboardingSlackAutoConnectSuccessProps) {
  const isMobileAgent = useIsMobileAgent()

  const usage = useFragment<MemberOnboardingSlackAutoConnectSuccessFragment$key>(
    graphql`
      fragment MemberOnboardingSlackAutoConnectSuccessFragment on ContentUsage {
        ...MemberOnboardingBanner_SlackWorkspaceFragment @relay(mask: false)
      }
    `,
    usageKey
  )
  const { workspace } = usage?.content?.organization?.slack || {}
  const classes = useStyles()
  return (
    <MemberOnboardingContentBodyWrapper>
      <MemberOnboardingContentHeader
        testid={testid}
        customContent={{
          name: `Hurray — You're in the ${
            workspace?.name ? `${workspace.name} ` : ""
          }Slack community!`,
          description: (
            <>
              {"Find the Disco Slack App "}
              <DiscoTooltip
                content={"Learn and connect in one place ✨"}
                buttonProps={{ classes: { root: classes.tooltipRoot } }}
              />
              {" inside the "}
              {workspace?.name ? (
                <DiscoLink
                  href={workspace?.url || ""}
                  style={{ fontSize: "inherit", lineHeight: "inherit" }}
                >
                  {`${workspace.name} `}
                </DiscoLink>
              ) : (
                ""
              )}
              {"Slack workspace to see all the app has to offer!"}
            </>
          ),
        }}
      />
      {isMobileAgent ? (
        <MemberOnboardingSlackMobileCarousel images={MOBILE_IMAGES} />
      ) : (
        <DiscoImage src={SlackAutoSuccess} />
      )}
      <MemberOnboardingButtons
        onSubmit={onSubmit}
        nextTitle={submitTitle}
        allowAdminPreview={allowAdminPreview}
        onSuccess={onSuccess}
      />
    </MemberOnboardingContentBodyWrapper>
  )
}

const useStyles = makeUseStyles({
  tooltipRoot: {
    display: "inline-block",
  },
})

export default MemberOnboardingSlackAutoConnectSuccess
