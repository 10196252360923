import { CommunityAppBarProps } from "@/community/CommunityAppBar"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { isEventsTabRoute } from "@/core/route/util/routeUtils"
import GlobalNavButton from "@/main/page/global-nav/GlobalNavButton"
import { DiscoIcon } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"

type EventsButtonProps = TestIDProps & Pick<CommunityAppBarProps, "variant">

export default function EventsButton(props: EventsButtonProps) {
  const { testid = "EventsButton", variant } = props
  const isActive = isEventsTabRoute(location.pathname)

  return (
    <GlobalNavButton
      testid={testid}
      to={ROUTE_NAMES.COMMUNITY.EVENTS_CALENDAR.ROOT}
      tooltip={"Events"}
      isActive={isActive}
      variant={variant}
    >
      <DiscoIcon icon={"calendar"} active={isActive} />
    </GlobalNavButton>
  )
}
