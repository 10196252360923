import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useFormStore } from "@/core/form/store/FormStore"
import { DeleteMeetingProviderFormFragment$key } from "@/meeting-provider/common/delete/form/__generated__/DeleteMeetingProviderFormFragment.graphql"
import { DeleteMeetingProviderFormMutation } from "@/meeting-provider/common/delete/form/__generated__/DeleteMeetingProviderFormMutation.graphql"
import { DeleteMeetingProviderFormRefetchQuery } from "@/meeting-provider/common/delete/form/__generated__/DeleteMeetingProviderFormRefetchQuery.graphql"
import DeleteMeetingProviderEventsFields from "@/meeting-provider/common/delete/form/fields/DeleteMeetingProviderEventsFields"
import Relay from "@/relay/relayUtils"
import Form from "@components/form/Form"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoText } from "@disco-ui"
import { observer } from "mobx-react-lite"
import pluralize from "pluralize"
import { FC, useEffect } from "react"
import { useRefetchableFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props {
  meetingProviderKey: DeleteMeetingProviderFormFragment$key
  onClose: VoidFunction
}

const DeleteMeetingProviderForm: FC<Props> = ({ meetingProviderKey, onClose }) => {
  const activeOrganization = useActiveOrganization()!
  const [meetingProvider, refetch] = useRefetchableFragment<
    DeleteMeetingProviderFormRefetchQuery,
    DeleteMeetingProviderFormFragment$key
  >(
    graphql`
      fragment DeleteMeetingProviderFormFragment on MeetingProvider
      @refetchable(queryName: "DeleteMeetingProviderFormRefetchQuery") {
        id
        occurrences(upcomingOnly: true) {
          edges {
            node {
              id
              name
              datetimeRange
              product {
                slug
                type
              }
            }
          }
        }
        zoomConnection {
          email
        }
      }
    `,
    meetingProviderKey
  )
  const occurrences = Relay.connectionToArray(meetingProvider.occurrences)

  useEffect(() => {
    window.addEventListener("focus", handleWindowFocus)

    return () => {
      window.removeEventListener("focus", handleWindowFocus)
    }

    function handleWindowFocus() {
      // Refetch the form fragment on window re-focus
      refetch({ id: meetingProvider.id }, { fetchPolicy: "network-only" })
    }
  }, [meetingProvider.id, refetch])

  const form = useFormStore<DeleteMeetingProviderFormMutation>(
    graphql`
      mutation DeleteMeetingProviderFormMutation($input: DeleteMeetingProviderInput!) {
        response: deleteMeetingProvider(input: $input)
      }
    `,
    {
      meetingProviderId: meetingProvider.id,
    },
    { requireChangeToSubmit: false }
  )
  const hasUnlinkedOccurrences = occurrences.length > 0
  return (
    <Form
      onSubmit={handleSubmit}
      testid={"DeleteMeetingProviderForm"}
      buttons={
        <Form.SubmitButton
          testid={"DeleteMeetingProviderForm.submit-button"}
          color={"error"}
          form={form}
        >
          {hasUnlinkedOccurrences ? "Disconnect Anyway" : "Disconnect Account"}
        </Form.SubmitButton>
      }
    >
      <DiscoText
        marginBottom={2}
        display={"inline"}
        data-testid={"DeleteMeetingProviderForm.title"}
      >
        {`You are about to disconnect ${meetingProvider.zoomConnection.email}.`}
      </DiscoText>
      {hasUnlinkedOccurrences && (
        <DiscoText
          marginBottom={2}
          display={"inline"}
          data-testid={"DeleteMeetingProviderForm.events-warning"}
        >
          {` There ${pluralize("is", occurrences.length)} ${
            occurrences.length
          } upcoming ${pluralize(
            "Event",
            occurrences.length
          )} that use this Zoom account as the Host.`}
        </DiscoText>
      )}

      {hasUnlinkedOccurrences && (
        <DeleteMeetingProviderEventsFields connections={meetingProvider.occurrences} />
      )}
    </Form>
  )

  async function handleSubmit() {
    const { didSave } = await form.submit(form.state, {
      updater: (store, payload) => {
        const organizationStore = store.get(activeOrganization.id)
        if (!organizationStore) return
        const connection = organizationStore.getLinkedRecord("meetingProviders", {
          platform: "zoom",
        })
        // Remove the meeting provider from the connection
        if (!connection || !payload.response) return
        // need to invalidate record for other components that render this form (eg: EditEventForm)
        connection.invalidateRecord()
        Relay.deleteNodeFromConnection(connection, payload.response)
        // Set the hasZoomIntegration to false if this was the last meeting provider
        organizationStore.setValue(
          ((connection.getValue("totalCount") as number | undefined) || 0) > 0,
          "hasZoomIntegration"
        )
      },
    })
    if (!didSave) return

    displaySuccessToast({
      testid: "DeleteMeetingProviderForm.success-toast",
      message: `Successfully disconnected ${meetingProvider.zoomConnection.email}!`,
    })

    onClose()
  }
}

export default observer(DeleteMeetingProviderForm)
