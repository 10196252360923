import { useFormStore } from "@/core/form/store/FormStore"
import {
  CreateMeetingProviderFormFragment$data,
  CreateMeetingProviderFormFragment$key,
} from "@/meeting-provider/common/add/form/__generated__/CreateMeetingProviderFormFragment.graphql"
import {
  CreateMeetingProviderFormMutation,
  CreateMeetingProviderInput,
} from "@/meeting-provider/common/add/form/__generated__/CreateMeetingProviderFormMutation.graphql"
import CreateMeetingProviderFormFields from "@/meeting-provider/common/add/form/fields/CreateMeetingProviderFormFields"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import ZoomConnectButton from "@/zoom/connect-button/ZoomConnectButton"
import Form from "@components/form/Form"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoText } from "@disco-ui"
import { observer } from "mobx-react-lite"
import pluralize from "pluralize"
import { FC } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props {
  organizationKey: CreateMeetingProviderFormFragment$key
  onClose: VoidFunction
}

export type CreateMeetingProviderFormState = CreateMeetingProviderInput & {
  selected: Record<GlobalID, boolean>
}

const CreateMeetingProviderForm: FC<Props> = ({ organizationKey, onClose }) => {
  const organization = useFragment<CreateMeetingProviderFormFragment$key>(
    graphql`
      fragment CreateMeetingProviderFormFragment on Organization {
        id
        name
        meetingProviders(platform: zoom) {
          __id
          totalCount
        }
        unlinkedZoomConnections: viewerUnlinkedZoomConnections {
          edges {
            node {
              id
              firstName
              lastName
              picUrl
              email
            }
          }
        }
      }
    `,
    organizationKey
  )

  const form = useFormStore<
    CreateMeetingProviderFormMutation,
    CreateMeetingProviderFormState
  >(
    graphql`
      mutation CreateMeetingProviderFormMutation(
        $input: CreateMeetingProviderInput!
        $connections: [ID!]!
      ) {
        response: createMeetingProviders(input: $input) {
          data {
            edges {
              node
                @appendNode(
                  connections: $connections
                  edgeTypeName: "MeetingProviderNodeEdge"
                ) {
                id
                ...OrganizationZoomConnectionListItemFragment
              }
            }
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      organizationId: organization.id,
      zoomConnectionIds: [],
      selected: getInitialSelectedState(organization.unlinkedZoomConnections),
    }
  )
  return (
    <Form
      onSubmit={handleSubmit}
      testid={"CreateMeetingProviderForm"}
      buttons={
        <>
          <ZoomConnectButton
            data-testid={"CreateMeetingProviderForm.submit-button.zoom-connect-button"}
            color={"grey"}
            variant={"outlined"}
          >
            {"Connect New Account"}
          </ZoomConnectButton>
          <Form.SubmitButton
            form={form}
            testid={"CreateMeetingProviderForm.submit-button"}
            // Disable the button if there are no selected connections or form is submitting
            disabled={
              Object.keys(form.state.selected!).filter((id) => form.state.selected![id]!)
                .length === 0 || form.isSubmitting
            }
          >
            {"Add Selected Accounts"}
          </Form.SubmitButton>
        </>
      }
    >
      <DiscoText
        marginTop={2}
        marginBottom={2}
        variant={"body-md-600"}
        data-testid={"CreateMeetingProviderForm.title"}
      >
        {`Choose the new Zoom account(s) that you would like to connect to ${organization.name}.`}
      </DiscoText>
      <CreateMeetingProviderFormFields
        form={form}
        connections={organization.unlinkedZoomConnections}
      />
    </Form>
  )

  /**
   * @example
   *  [id1, id2]
   *  ===>
   *  {
   *    id1: false,
   *    id2: false
   *  }
   */
  function getInitialSelectedState(
    connection: CreateMeetingProviderFormFragment$data["unlinkedZoomConnections"]
  ) {
    const availableConnections = Relay.connectionToArray(connection)
    return availableConnections.reduce((selected, { id }) => {
      selected[id] = false
      return selected
    }, {} as Record<GlobalID, boolean>)
  }

  async function handleSubmit() {
    // Get the selected connection Ids
    const selectedIds = Object.keys(form.state.selected!).filter(
      (id) => form.state.selected![id]!
    )

    const { didSave } = await form.submit(
      {
        organizationId: form.state.organizationId,
        zoomConnectionIds: selectedIds,
      },
      {
        connections: organization.meetingProviders
          ? [organization.meetingProviders.__id]
          : [],
        updater: (store) => {
          const organizationStore = store.get(organization.id)
          if (!organizationStore) return

          if (organizationStore.getValue("hasZoomIntegration") === false) {
            organizationStore.setValue(true, "hasZoomIntegration")
          }

          const connection = organizationStore.getLinkedRecord(
            "viewerUnlinkedZoomConnections"
          )
          if (connection) {
            // Remove the selected accounts from the unlinked connections
            for (const ZoomConnectionId of selectedIds) {
              Relay.deleteNodeFromConnection(connection, ZoomConnectionId)
            }
          }
        },
      }
    )
    if (!didSave) return

    displaySuccessToast({
      message: `Successfully added ${selectedIds.length} Zoom ${pluralize(
        "account",
        selectedIds.length
      )} to ${organization.name}.`,
      testid: "CreateMeetingProviderForm.success-toast",
    })
    onClose()
  }
}

export default observer(CreateMeetingProviderForm)
