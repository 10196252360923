import { useViewersCalendarConnectionRefetchableQuery } from "@/member-onboarding/calendar-connection/hooks/__generated__/useViewersCalendarConnectionRefetchableQuery.graphql"
import Relay from "@/relay/relayUtils"
import { useEffect } from "react"
import { graphql } from "react-relay"

function useViewersCalendarConnection() {
  const [data, refetch] =
    Relay.useRefetchableQuery<useViewersCalendarConnectionRefetchableQuery>(
      graphql`
        query useViewersCalendarConnectionRefetchableQuery {
          viewer {
            __typename
            id
            calendarConnection {
              id
              status
              platform
            }
            ...ProfileSettingsCalendarConnectionsFragment
          }
        }
      `,
      {},
      { refetchInBackground: true }
    )

  useEffect(() => {
    window.addEventListener("focus", handleWindowFocus)

    return () => {
      window.removeEventListener("focus", handleWindowFocus)
    }

    function handleWindowFocus() {
      // Refetch the calendar connections on window re-focus
      refetch({}, { inBackground: true })
    }
  }, [refetch])

  return { viewer: data.viewer, refetch: () => refetch({}) }
}

export default useViewersCalendarConnection
