import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import CoverImageEmptyStateImage from "@/core/ui/images/empty-state/save-disk.svg"
import { FileListSkeleton } from "@/media/add/list/MediaFileListTemplate"
import { UnsplashImageSearchResponse } from "@/media/unsplash/UnsplashSearch"
import { UnsplashSearchResultsQuery } from "@/media/unsplash/__generated__/UnsplashSearchResultsQuery.graphql"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoButton, DiscoEmptyState, DiscoLink, DiscoText } from "@disco-ui"
import DiscoImage from "@disco-ui/image/DiscoImage"
import { useEffect } from "react"
import { graphql } from "relay-runtime"

interface Props {
  search: string
  onImageSelect: (image: UnsplashImageSearchResponse) => void
}

function UnsplashSearchResults(props: Props) {
  const { search, onImageSelect } = props
  const activeOrganization = useActiveOrganization()!
  const activeProduct = useActiveProduct()
  const classes = useStyles()

  const [{ response }, refetch] = Relay.useRefetchableQuery<UnsplashSearchResultsQuery>(
    graphql`
      query UnsplashSearchResultsQuery($input: UnsplashImageSearchInput!) {
        response: searchUnsplashImages(input: $input) {
          data {
            id
            imageThumbUrl
            imageUrl
            downloadUrl
            photoName
            photographerName
            photographerUrl
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      input: {
        organizationId: activeOrganization.id,
        productId: activeProduct?.id,
        search,
      },
    }
  )

  useEffect(() => {
    refetch({ input: { organizationId: activeOrganization.id, search } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  if (!response || !response.data?.length)
    return (
      <DiscoEmptyState
        testid={"UnsplashSearchResults.empty-state"}
        title={"No Results"}
        subtitle={
          "No images found. Try a new search term or check your internet connection."
        }
        icon={<CoverImageEmptyStateImage />}
      />
    )
  const images = response.data as UnsplashImageSearchResponse[]

  return (
    <div className={classes.headerAssetContainer}>
      <DiscoText className={classes.title} variant={"body-xs-600-uppercase"}>
        {"Results from unsplash"}
      </DiscoText>
      <div className={classes.assetContainer}>
        {images.map((i) => {
          return (
            <div key={i.id} className={classes.mediaListItemContainer}>
              <DiscoButton
                classes={{ root: classes.root }}
                color={"transparent"}
                onClick={() => onImageSelect(i)}
                className={classes.imageSelectButton}
              >
                <DiscoImage
                  src={i.imageThumbUrl}
                  alt={i.photoName}
                  className={classes.preview}
                />
              </DiscoButton>
              <DiscoLink
                to={i.photographerUrl}
                target={"_blank"}
                rel={"noreferrer noopener"}
                textVariant={"body-xs-500"}
              >{`By ${i.photographerName}`}</DiscoLink>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
  },
  mediaListItemContainer: {
    display: "grid",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  title: {
    color: theme.palette.groovy.grey[400],
    gap: theme.spacing(1),
  },
  assetContainer: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    [theme.breakpoints.only("sm")]: {
      gridTemplateColumns: "1fr 1fr",
    },
    [theme.breakpoints.only("xs")]: {
      gridTemplateColumns: "1fr",
    },
    gap: theme.spacing(1),
    alignItems: "center",
    justifyContent: "stretch",
    marginTop: theme.spacing(2),
  },
  headerAssetContainer: {
    margin: theme.spacing(2, 0),
  },
  preview: {
    height: "100%",
    width: "100%",
    borderRadius: theme.measure.borderRadius.medium,
    margin: "auto",
  },
  imageSelectButton: {
    flexShrink: 0,
    width: "170px",
    height: "96px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
    },
    "&:hover": {
      opacity: 0.8,
    },
    borderRadius: theme.measure.borderRadius.medium,
    backgroundColor: theme.palette.groovy.neutral[100],
    "&:hover:not(:disabled)": {
      backgroundColor: theme.palette.groovy.neutral[100],
      border: "none",
    },
  },
}))

export default Relay.withSkeleton({
  component: UnsplashSearchResults,
  skeleton: FileListSkeleton,
})
