import { ContentSystemTaskKind } from "@/admin/member-onboarding/__generated__/AdminMemberOnboardingListItemFragment.graphql"
import { useOrganizationTheme } from "@/core/context/CustomThemeProvider"
import StepperContextProvider, { StepperContext } from "@/core/context/StepperContext"
import MemberOnboardingContent from "@/member-onboarding/MemberOnboardingContent"
import { MemberOnboardingModalFragment$key } from "@/member-onboarding/__generated__/MemberOnboardingModalFragment.graphql"
import { useMemberOnboardingQueryParams } from "@/member-onboarding/hooks/useMemberOnboardingQueryParams"
import Relay from "@/relay/relayUtils"
import { createComputedTheme } from "@assets/style/appMuiTheme"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import FullPageModalStepper, {
  FullPageModalStepperProps,
} from "@components/stepper/FullPageModalStepper"
import { DiscoButton, DiscoThemeProvider, DiscoTooltip } from "@disco-ui"
import { Fade } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import { graphql, useFragment } from "react-relay"

export interface MemberOnboardingModalProps
  extends TestIDProps,
    Pick<FullPageModalStepperProps, "isOpen"> {
  memberOnboardingKey: MemberOnboardingModalFragment$key | null
  onClose: VoidFunction
  mode?: "admin-preview" | "member-onboarding" | "curriculum-item"
  handleOpen?: VoidFunction
  systemTaskKinds?: ContentSystemTaskKind[]
}

function MemberOnboardingModal({
  testid = "MemberOnboardingModal",
  isOpen,
  onClose,
  memberOnboardingKey,
  mode = "member-onboarding",
  handleOpen,
}: MemberOnboardingModalProps) {
  const { memberOnboardingStep } = useMemberOnboardingQueryParams()

  const memberOnboarding = useFragment<MemberOnboardingModalFragment$key>(
    graphql`
      fragment MemberOnboardingModalFragment on ContentUsage
      @argumentDefinitions(
        includeDraft: { type: "Boolean", defaultValue: null }
        systemTaskKinds: { type: "[ContentSystemTaskKind!]", defaultValue: null }
      ) {
        id
        content {
          id
          children(
            first: null
            includeDraft: $includeDraft
            systemTaskKinds: $systemTaskKinds
          ) @connection(key: "MemberOnboardingModal_children") {
            edges {
              node {
                id
                ordering
                viewerHasCompleted
                status
                content {
                  id
                  systemTaskKind
                }
                ...MemberOnboardingContentFragment @arguments(includeDraft: $includeDraft)
              }
            }
          }
        }
      }
    `,
    memberOnboardingKey
  )

  const classes = useStyles()
  const organizationTheme = useOrganizationTheme()?.theme

  let steps = Relay.connectionToArray(memberOnboarding?.content?.children)

  if (mode === "admin-preview") steps = steps.filter((u) => u.status === "published")

  const openToStep = steps.findIndex(
    ({ content: { systemTaskKind } }) =>
      Boolean(memberOnboardingStep) && systemTaskKind === memberOnboardingStep
  )
  const firstIncompleteStep = steps.findIndex(
    ({ viewerHasCompleted }) => !viewerHasCompleted
  )
  const allStepsComplete = firstIncompleteStep === -1

  // start at a specific step if one is specified
  const startingStep =
    openToStep >= 0
      ? openToStep
      : // if the member has completed all the published steps, bring them to the success page so they can complete the onboarding module and dismiss the banner
      allStepsComplete
      ? steps.length - 1
      : firstIncompleteStep

  if (!memberOnboarding) return null

  return (
    <>
      {mode === "admin-preview" && (
        <DiscoTooltip
          content={
            steps.length
              ? ""
              : "Enable one or more onboarding steps to preview the member onboarding experience."
          }
        >
          <div>
            <DiscoButton
              testid={`${testid}.preview-button`}
              onClick={handleOpen}
              disabled={!steps.length}
            >
              {"Preview Onboarding"}
            </DiscoButton>
          </div>
        </DiscoTooltip>
      )}
      {isOpen && (
        <DiscoThemeProvider theme={createComputedTheme(organizationTheme)}>
          <StepperContextProvider
            steps={[startingStep, steps.length]}
            onAfterLast={onClose}
          >
            <StepperContext.Consumer>
              {(ctx) => {
                const {
                  steps: [activeStep, total],
                } = ctx!
                const lastStep = total - 1
                return (
                  <FullPageModalStepper
                    testid={testid}
                    steps={[activeStep + 1, total - 1]} // don't include the success page as a step in stepper
                    isOpen={isOpen}
                    hideStepper={activeStep === total - 1} // don't show the stepper on the onboarding success page
                    onClose={mode === "admin-preview" ? onClose : undefined} // organization admins previewing onboarding should be able to close the modal
                  >
                    <div className={classes.container}>
                      {steps.map((step, index) => (
                        <Fade
                          key={step.id}
                          in={activeStep === index}
                          timeout={700}
                          mountOnEnter
                          unmountOnExit
                        >
                          <div key={step.id} className={classes.item}>
                            <MemberOnboardingContent
                              usageKey={step}
                              mode={mode}
                              /**
                               *   if the user will complete the onboarding module on the last step, trigger confetti & show success content
                               * conditions to complete module:
                               * 1. all steps are complete (because they re-entered unfinished onboarding)
                               * 2. the user will complete the last incomplete step on last step (completion on their first time stepping through)
                               */
                              willCompleteOnboarding={
                                (allStepsComplete && activeStep === lastStep) ||
                                (lastStep === activeStep &&
                                  lastStep === firstIncompleteStep)
                              }
                            />
                          </div>
                        </Fade>
                      ))}
                    </div>
                  </FullPageModalStepper>
                )
              }}
            </StepperContext.Consumer>
          </StepperContextProvider>
        </DiscoThemeProvider>
      )}
    </>
  )
}
const useStyles = makeUseStyles({
  container: {
    display: "grid",
    height: "100%",
  },
  item: {
    gridRowStart: 1,
    gridColumnStart: 1,
    overflow: "hidden",
  },
})

export default MemberOnboardingModal
