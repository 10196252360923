import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { MembershipPlanSelectBodyProps } from "@/membership-plan/register/MembershipPlanSelectBody"
import MembershipPlanSelectModal from "@/membership-plan/register/MembershipPlanSelectModal"
import { DiscoTooltip } from "@disco-ui"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonProps,
} from "@disco-ui/button/OverridableDiscoButton"
import { TestIDProps } from "@utils/typeUtils"
import { rest } from "lodash"
import { observer } from "mobx-react-lite"
import { useCallback } from "react"

type Props = OverridableDiscoButtonProps &
  TestIDProps &
  Partial<Pick<MembershipPlanSelectBodyProps, "experienceId">>

function MembershipPlanSelectButton({ children, experienceId }: Props) {
  const activeOrganization = useActiveOrganization()!
  const { viewerMembershipPlan } = activeOrganization
  const { viewerCanChangeMembershipPlan } = viewerMembershipPlan
  const disabled = !viewerMembershipPlan.viewerCanChangeMembershipPlan.value

  const button = (
    <OverridableDiscoButton
      {...rest}
      disabled={disabled}
      // eslint-disable-next-line react-hooks/exhaustive-deps
      modal={useCallback(
        observer((modalProps) => (
          <MembershipPlanSelectModal experienceId={experienceId} {...modalProps} />
        )),
        [experienceId]
      )}
    >
      {children}
    </OverridableDiscoButton>
  )

  if (!viewerCanChangeMembershipPlan.value)
    return (
      <DiscoTooltip content={viewerCanChangeMembershipPlan.message}>
        <span>{button}</span>
      </DiscoTooltip>
    )

  return button
}

export default MembershipPlanSelectButton
