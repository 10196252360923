/**
 * @generated SignedSource<<c37b8335c63b0d5df1beb5d756df2d28>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CalendarConnectionPlatform = "ics" | "outlook" | "%future added value";
export type CalendarConnectionStatus = "active" | "inactive" | "auth_error" | "%future added value";
export type useViewersCalendarConnectionRefetchableQuery$variables = {};
export type useViewersCalendarConnectionRefetchableQuery$data = {
  readonly viewer: {
    readonly __typename: string;
    readonly id: string;
    readonly calendarConnection: {
      readonly id: string;
      readonly status: CalendarConnectionStatus;
      readonly platform: CalendarConnectionPlatform;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"ProfileSettingsCalendarConnectionsFragment">;
  } | null;
};
export type useViewersCalendarConnectionRefetchableQuery = {
  variables: useViewersCalendarConnectionRefetchableQuery$variables;
  response: useViewersCalendarConnectionRefetchableQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "platform",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "useViewersCalendarConnectionRefetchableQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CalendarConnection",
            "kind": "LinkedField",
            "name": "calendarConnection",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ProfileSettingsCalendarConnectionsFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "useViewersCalendarConnectionRefetchableQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CalendarConnection",
            "kind": "LinkedField",
            "name": "calendarConnection",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "925732f41457591a01d540c1eb4bda91",
    "id": null,
    "metadata": {},
    "name": "useViewersCalendarConnectionRefetchableQuery",
    "operationKind": "query",
    "text": "query useViewersCalendarConnectionRefetchableQuery {\n  viewer {\n    __typename\n    id\n    calendarConnection {\n      id\n      status\n      platform\n    }\n    ...ProfileSettingsCalendarConnectionsFragment\n  }\n}\n\nfragment DisconnectCalendarConnectionButton_CalendarConnectionFragment on CalendarConnection {\n  id\n  status\n}\n\nfragment ProfileOutlookCalendarConnection_CalendarConnectionFragment on CalendarConnection {\n  id\n  email\n  status\n  platform\n  ...DisconnectCalendarConnectionButton_CalendarConnectionFragment\n}\n\nfragment ProfileSettingsCalendarConnectionsFragment on User {\n  calendarConnection {\n    id\n    platform\n    ...ProfileOutlookCalendarConnection_CalendarConnectionFragment\n  }\n}\n"
  }
};
})();

(node as any).hash = "91b9814a4b270523f4004fedaf372b77";

export default node;
