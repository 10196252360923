import { useLabels } from "@/core/context/LabelsContext"
import defaultThumbnail from "@/core/ui/images/public-pages/default-thumbnail.png"
import { MembershipPlanExperienceDetailsFragment$key } from "@/membership-plan/list/__generated__/MembershipPlanExperienceDetailsFragment.graphql"
import ProductTypeTag from "@/product/common/type-tag/ProductTypeTag"
import ProductUtils from "@/product/util/productUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import CoverPhoto from "@components/cover-photo/CoverPhoto"
import { DiscoAlert, DiscoIcon, DiscoSection, DiscoText } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
import { ReactNode } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props extends TestIDProps {
  productKey: MembershipPlanExperienceDetailsFragment$key
  actionButtons?: ReactNode
  hideAlert?: boolean
}

function MembershipPlanExperienceDetails(props: Props) {
  const {
    productKey,
    actionButtons,
    hideAlert = false,
    testid = "MembershipPlanExperienceDetails",
  } = props

  const classes = useStyles()
  const labels = useLabels()
  const product = useFragment<MembershipPlanExperienceDetailsFragment$key>(
    graphql`
      fragment MembershipPlanExperienceDetailsFragment on Product {
        id
        name
        startsAt
        endsAt
        type
        slug
        cover
        isAutoJoined
        ...ProductTypeTagFragment
      }
    `,
    productKey
  )

  const { startsAt, name, cover, type } = product
  const startDateText = ProductUtils.getStartDateText(type, startsAt)

  return (
    <DiscoSection
      className={classes.selection}
      groovyDepths={"insideCard"}
      marginTop={1.5}
      padding={2}
    >
      <div className={classes.section}>
        <div className={classes.root}>
          <div className={classes.iconWrapper}>
            <CoverPhoto
              coverPhoto={cover || defaultThumbnail}
              customClassName={classes.cover}
            />
          </div>
          <div className={classes.details}>
            <ProductTypeTag
              productKey={product}
              testid={`${testid}.ProductTypeTag.label`}
            />
            <DiscoText
              variant={"body-sm-700"}
              testid={`${testid}.name`}
              marginLeft={0.25}
              marginTop={1}
            >
              {name}
            </DiscoText>
            <DiscoText
              variant={"body-xs-500"}
              color={"groovy.grey.400"}
              testid={`${testid}.details`}
              marginLeft={0.25}
            >
              {product.startsAt || product.endsAt
                ? `${startDateText} · 
    ${ProductUtils.displayDuration(product)}`
                : startDateText}
            </DiscoText>
          </div>
        </div>
        {actionButtons}
      </div>
      {product.isAutoJoined && !hideAlert && (
        <DiscoAlert
          icon={<DiscoIcon icon={"lightning"} />}
          message={`${labels.admin_member.plural} do not need to register for this ${labels.admin_experience.singular}. They are automatically added to this ${labels.admin_experience.singular} when joining this membership plan.`}
        />
      )}
    </DiscoSection>
  )
}

const useStyles = makeUseStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  iconWrapper: {
    width: 150,
    marginRight: theme.spacing(1.5),
  },
  cover: {
    borderRadius: theme.measure.borderRadius.default,
  },
  details: {
    paddingTop: theme.spacing(0.5),
  },
  selection: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    width: "100%",
  },
  section: {
    display: "flex",
    justifyContent: "space-between",
    alignItem: "center",
    width: "100%",
    gap: theme.spacing(1),
  },
}))

export default MembershipPlanExperienceDetails
