import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import UnsavedChangesModalProvider, {
  useInitUnsavedChangesModalContext,
} from "@/core/context/UnsavedChangesModalProvider"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoDrawer } from "@disco-ui"
import lazyWithRetry from "@utils/lazy/lazyWithRetry"

const MembershipPlanSettingsDrawerContent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "membership-plan-settings-drawer-content" */ "@/membership-plan/settings/MembershipPlanSettingsDrawerContent"
    )
)

function MembershipPlanSettingsDrawer() {
  const globalDrawer = useGlobalDrawer("membershipPlanSettings")
  const classes = useStyles()
  const modal = useInitUnsavedChangesModalContext()

  return (
    <UnsavedChangesModalProvider {...modal}>
      <DiscoDrawer
        title={"Settings"}
        open={globalDrawer.isOpen}
        onClose={handleCloseDrawer}
        size={"large"}
        maxSize={"xl"}
        containerClasses={{ drawerContainer: classes.drawer }}
      >
        {/* Content */}
        {globalDrawer.isOpen && <MembershipPlanSettingsDrawerContent />}
      </DiscoDrawer>
    </UnsavedChangesModalProvider>
  )

  function handleCloseDrawer() {
    globalDrawer.close()
  }
}

const useStyles = makeUseStyles(() => ({
  drawer: {
    padding: 0,
    // Hiding overflow so ExperienceSettingsForm can use sticky footer
    overflowY: "hidden",
  },
}))

export default MembershipPlanSettingsDrawer
