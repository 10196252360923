import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoDrawer } from "@disco-ui"
import lazyWithRetry from "@utils/lazy/lazyWithRetry"

const AdminMembershipPlanDrawerContent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "admin-membership-plan-drawer-content" */ "@/membership-plan/report/AdminMembershipPlanDrawerContent"
    )
)

function AdminMembershipPlanDrawer() {
  const globalDrawer = useGlobalDrawer("membershipPlan")
  const classes = useStyles()

  return (
    <DiscoDrawer
      title={"Settings"}
      open={globalDrawer.isOpen}
      onClose={handleClose}
      containerClasses={{ drawerContainer: classes.drawerContainer }}
    >
      {/* Content */}
      {globalDrawer.params.membershipPlanId && (
        <AdminMembershipPlanDrawerContent
          membershipPlanId={globalDrawer.params.membershipPlanId}
          onClose={handleClose}
        />
      )}
    </DiscoDrawer>
  )

  function handleClose() {
    globalDrawer.close()
  }
}

const useStyles = makeUseStyles({
  drawerContainer: {
    padding: 0,
  },
})

export default AdminMembershipPlanDrawer
