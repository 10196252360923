import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { CreateMeetingProviderButtonQuery } from "@/meeting-provider/common/add/button/__generated__/CreateMeetingProviderButtonQuery.graphql"
import CreateMeetingProviderForm from "@/meeting-provider/common/add/form/CreateMeetingProviderForm"
import Relay from "@/relay/relayUtils"
import ZoomConnectButton from "@/zoom/connect-button/ZoomConnectButton"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoButtonSkeleton, DiscoModal } from "@disco-ui"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
} from "@disco-ui/button/OverridableDiscoButton"
import { FC, useState } from "react"
import { useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"

interface Props {
  children: OverridableDiscoButtonChildren
}

const CreateMeetingProviderButton: FC<Props> = ({ children }) => {
  const [showModal, setShowModal] = useState(false)
  const activeOrganization = useActiveOrganization()!
  const { organization } = useLazyLoadQuery<CreateMeetingProviderButtonQuery>(
    graphql`
      query CreateMeetingProviderButtonQuery($id: ID!) {
        organization: node(id: $id) {
          ... on Organization {
            viewerUnlinkedZoomConnections {
              __id
              totalCount
            }
            ...CreateMeetingProviderFormFragment
          }
        }
      }
    `,
    { id: activeOrganization.id },
    { fetchPolicy: "network-only" }
  )

  const classes = useStyles()

  if (!organization) return null

  // If the user has no unlinked connections, send them to the OAuth flow
  if (
    !organization.viewerUnlinkedZoomConnections ||
    organization.viewerUnlinkedZoomConnections.totalCount === 0
  ) {
    return (
      <ZoomConnectButton data-testid={"CreateMeetingProviderButton.zoom-connect-button"}>
        {(zoomConnectProps) => (
          <OverridableDiscoButton {...zoomConnectProps}>
            {children || "Link Zoom Account"}
          </OverridableDiscoButton>
        )}
      </ZoomConnectButton>
    )
  }

  return (
    <>
      <OverridableDiscoButton onClick={openModal}>{children}</OverridableDiscoButton>
      <DiscoModal
        isOpen={showModal}
        onClose={closeModal}
        testid={"AddZoomConnectionModal"}
        modalContentLabel={"Add Zoom Account"}
        title={"Add Zoom Account"}
        subtitle={
          "We found connected Zoom accounts in other communities that you are in."
        }
        portalClassName={classes.modalPortal}
        buttons
        body={
          <CreateMeetingProviderForm
            organizationKey={organization}
            onClose={closeModal}
          />
        }
      />
    </>
  )

  function closeModal() {
    setShowModal(false)
  }

  function openModal() {
    setShowModal(true)
  }
}

const useStyles = makeUseStyles((theme) => ({
  modalPortal: {
    // Since this modal can be opened from a Popover, we need to make sure it's
    // always on top of the Popover.
    zIndex: theme.zIndex.modal + 1,
  },
}))

export default Relay.withSkeleton({
  component: CreateMeetingProviderButton,
  skeleton: () => <DiscoButtonSkeleton width={"90px"} />,
})
