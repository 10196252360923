import { useConfettiCannon } from "@/core/context/ConfettiContext"
import MemberOnboardingCalendarContent from "@/member-onboarding/calendar-connection/MemberOnboardingCalendarContent"
import MemberOnboardingCommunityAgreement from "@/member-onboarding/MemberOnboardingCommunityAgreement"
import MemberOnboardingComplete from "@/member-onboarding/MemberOnboardingComplete"
import MemberOnboardingContentBodyWrapper from "@/member-onboarding/MemberOnboardingContentBodyWrapper"
import MemberOnboardingContentHeader from "@/member-onboarding/MemberOnboardingContentHeader"
import MemberOnboardingCustomProfileFields from "@/member-onboarding/MemberOnboardingCustomProfileFields"
import MemberOnboardingDefaultProfileFields from "@/member-onboarding/MemberOnboardingDefaultProfileFields"
import MemberOnboardingDownloadMobileApp from "@/member-onboarding/MemberOnboardingDownloadMobileApp"
import MemberOnboardingGenericTask from "@/member-onboarding/MemberOnboardingGenericTask"
import { MemberOnboardingModalProps } from "@/member-onboarding/MemberOnboardingModal"
import MemberOnboardingSlackContent from "@/member-onboarding/slack-flow/MemberOnboardingSlackContent"
import { MemberOnboardingContentFragment$key } from "@/member-onboarding/__generated__/MemberOnboardingContentFragment.graphql"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { TestIDProps } from "@utils/typeUtils"
import { useEffect, useRef } from "react"
import { graphql, useFragment } from "react-relay"

interface MemberOnboardingContentProps extends TestIDProps {
  usageKey: MemberOnboardingContentFragment$key
  willCompleteOnboarding: boolean
  mode: MemberOnboardingModalProps["mode"]
}

function MemberOnboardingContent({
  usageKey,
  willCompleteOnboarding,
  mode,
  testid = "MemberOnboardingContent",
}: MemberOnboardingContentProps) {
  const hasConfettiFired = useRef(false)
  const fireConfetti = useConfettiCannon()
  const { content, ...usage } = useFragment<MemberOnboardingContentFragment$key>(
    graphql`
      fragment MemberOnboardingContentFragment on ContentUsage
      @argumentDefinitions(includeDraft: { type: "Boolean", defaultValue: null }) {
        id
        content {
          id
          type
          systemTaskKind
        }
        ...MemberOnboardingContentHeaderFragment
        ...MemberOnboardingGenericTaskFragment
        ...MemberOnboardingCustomProfileFieldsFragment
          @arguments(includeDraft: $includeDraft)
        ...MemberOnboardingSlackContentFragment
        ...MemberOnboardingCalendarContentFragment
        ...MemberOnboardingCommunityAgreementFragment
        ...MemberOnboardingDefaultProfileFieldsFragment
        ...MemberOnboardingCompleteFragment
        ...MemberOnboardingDownloadMobileAppFragment
      }
    `,
    usageKey
  )

  const isCompletionStep = content.systemTaskKind === "onboarding_complete"

  const classes = useStyles({
    isComplete: isCompletionStep,
  })

  useEffect(() => {
    // only fire confetti for the completion step, it doesn't make sense to fire it in the context of a different step that may be last if completion is disabled
    if (!hasConfettiFired.current && willCompleteOnboarding && isCompletionStep) {
      hasConfettiFired.current = true
      fireConfetti()
    }
  }, [willCompleteOnboarding, fireConfetti, isCompletionStep])

  if (!content || !usage) return null

  return (
    <div
      data-testid={`${testid}.${content.systemTaskKind}`}
      className={classes.container}
    >
      {renderContent()}
    </div>
  )

  function renderContent() {
    switch (content.systemTaskKind) {
      case "community_agreement":
        return (
          <>
            <MemberOnboardingContentHeader usageKey={usage} />
            <MemberOnboardingCommunityAgreement usageKey={usage} />
          </>
        )
      case "default_profile_fields":
        return (
          <MemberOnboardingContentBodyWrapper>
            <MemberOnboardingContentHeader usageKey={usage} />
            <MemberOnboardingDefaultProfileFields usageKey={usage} mode={mode} />
          </MemberOnboardingContentBodyWrapper>
        )
      case "custom_profile_field":
        return (
          <MemberOnboardingContentBodyWrapper>
            <MemberOnboardingContentHeader usageKey={usage} />
            <MemberOnboardingCustomProfileFields usageKey={usage} mode={mode} />
          </MemberOnboardingContentBodyWrapper>
        )
      case "slack_connection":
      case "connect_slack":
        return <MemberOnboardingSlackContent usageKey={usage} mode={mode} />

      case "calendar_connection":
        return <MemberOnboardingCalendarContent usageKey={usage} mode={mode} />

      case "onboarding_complete":
        return (
          <MemberOnboardingContentBodyWrapper>
            <MemberOnboardingComplete
              usageKey={usage}
              willCompleteOnboarding={willCompleteOnboarding || mode === "admin-preview"}
            />
          </MemberOnboardingContentBodyWrapper>
        )
      case "download_mobile_app":
        return (
          <MemberOnboardingContentBodyWrapper>
            <MemberOnboardingContentHeader usageKey={usage} />
            <MemberOnboardingDownloadMobileApp usageKey={usage} />
          </MemberOnboardingContentBodyWrapper>
        )
      case "onboarding_welcome":
      default:
        return (
          <MemberOnboardingContentBodyWrapper>
            <MemberOnboardingContentHeader usageKey={usage} />
            <MemberOnboardingGenericTask usageKey={usage} />
          </MemberOnboardingContentBodyWrapper>
        )
    }
  }
}

const useStyles = makeUseStyles({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: (props: { isComplete: boolean }) =>
      props.isComplete ? "center" : "flex-start",
  },
})

export default MemberOnboardingContent
