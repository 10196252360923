import useMarkNotificationsAsRead from "@/apps/util/hooks/useMarkNotificationsAsRead"
import { GlobalDrawerParams } from "@/core/context/GlobalDrawerProvider"
import { useNotificationsContext } from "@/core/context/NotificationsContext"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoIcon, DiscoIconButton, DiscoTextButton } from "@disco-ui"
import DiscoQueryParamTabs, { QueryParamTab } from "@disco-ui/tabs/DiscoQueryParamTabs"
import { useTheme } from "@material-ui/core"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import React from "react"

export type NotificationCenterTab = "all" | "unread"

function NotificationCenterSubtabs() {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useIsMobile()

  const { markAllAsRead } = useMarkNotificationsAsRead()
  const { totalUnreadCount } = useNotificationsContext()

  const tabs: QueryParamTab<GlobalDrawerParams<"notificationCenter">>[] = [
    {
      label: "All Notifications",
      params: { notifications: "all" },
      testid: "all",
    },
    {
      label: "Unread",
      params: { notifications: "unread" },
      testid: "unread",
      notifications: totalUnreadCount,
    },
  ]

  return (
    <div className={classes.subtabsContainer}>
      <div className={classes.flex}>
        <DiscoQueryParamTabs
          testid={"NotificationCenterDrawerSubtabs"}
          tabs={tabs}
          className={classes.subtabs}
        />
        {isMobile ? (
          <DiscoIconButton onClick={markAllAsRead} disabled={totalUnreadCount === 0}>
            <DiscoIcon icon={"done-all"} />
          </DiscoIconButton>
        ) : (
          <DiscoTextButton
            leftIcon={"done-all"}
            onClick={markAllAsRead}
            color={theme.palette.text.secondary}
            textVariant={"body-sm-600"}
            testid={"NotificationCenterSubtabs.mark-all-as-read-button"}
            disabled={totalUnreadCount === 0}
          >
            {"Mark all as read"}
          </DiscoTextButton>
        )}
      </div>
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  flex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  subtabsContainer: {
    position: "relative",
  },
  subtabs: {
    textAlign: "center",
    overflow: "auto",
    ...theme.typography["body-sm"],
    ...theme.typography.modifiers.fontWeight[600],
    transform: "translateY(1.5px)",

    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    scrollbarWidth: "none",
  },
}))

export default React.memo(NotificationCenterSubtabs)
