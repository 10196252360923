import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import defaultThumbnail from "@/core/ui/images/public-pages/default-thumbnail.png"
import {
  MembershipPlanDetailsCardFragment$data,
  MembershipPlanDetailsCardFragment$key,
} from "@/membership-plan/register/__generated__/MembershipPlanDetailsCardFragment.graphql"
import { getPricingKindLabel, usePricingDisplayValue } from "@/pricing/pricingUtils"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import CoverPhoto, { CoverPhotoSkeleton } from "@components/cover-photo/CoverPhoto"
import {
  DiscoButton,
  DiscoButtonSkeleton,
  DiscoChip,
  DiscoChipSkeleton,
  DiscoIcon,
  DiscoText,
  DiscoTextSkeleton,
} from "@disco-ui"
import DiscoContainerButton from "@disco-ui/button/DiscoContainerButton"
import { useTheme } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import { observer } from "mobx-react-lite"
import pluralize from "pluralize"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface MembershipPlanDetailsCardProps extends TestIDProps {
  membershipPlanKey: MembershipPlanDetailsCardFragment$key
  onClick: (membershipPlan: MembershipPlanDetailsCardFragment$data) => void
  className?: string
}

function MembershipPlanDetailsCard({
  testid = "MembershipPlanDetailsCard",
  membershipPlanKey,
  onClick,
  className: customClassName,
}: MembershipPlanDetailsCardProps) {
  const activeOrganization = useActiveOrganization()
  const theme = useTheme()

  const membershipPlan = useFragment<MembershipPlanDetailsCardFragment$key>(
    graphql`
      fragment MembershipPlanDetailsCardFragment on Product
      @argumentDefinitions(
        hideDrafts: { type: "Boolean!" }
        hideNonPublic: { type: "Boolean!" }
      ) {
        id
        slug
        cover
        name
        description
        membershipBenefits(hideDrafts: $hideDrafts, hideNonPublic: $hideNonPublic) {
          totalCount
        }
        pricing {
          amountCents
          frequency
          kind
          ...pricingUtils_usePricingDisplayValue
        }
      }
    `,
    membershipPlanKey
  )

  const { pricing, cover, name, description, membershipBenefits } = membershipPlan

  const pricingValue = usePricingDisplayValue({
    pricingKey: pricing,
    currency: activeOrganization?.currency,
    length: "short",
  })

  const alreadyOnPlan = Boolean(
    activeOrganization?.viewerMembership &&
      activeOrganization?.viewerMembershipPlan?.id === membershipPlan.id
  )
  const disablePlans =
    activeOrganization?.visibility === "private" && !activeOrganization.viewerMembership

  const classes = useStyles({ disabled: alreadyOnPlan })

  if (!membershipPlan) return null

  return (
    <DiscoContainerButton
      testid={`${testid}.${membershipPlan.name}`}
      className={classNames(classes.card, customClassName)}
      onClick={() => onClick(membershipPlan)}
      disabled={alreadyOnPlan}
      tooltip={disablePlans ? "You need an invite to join this community." : undefined}
      tooltipWapperClassName={classes.cardTooltipWrapper}
    >
      {/* Cover */}
      <CoverPhoto
        coverPhoto={cover || defaultThumbnail}
        customClassName={classes.cover}
      />

      <div className={classes.details}>
        {/* Name */}
        <DiscoText variant={"heading-sm"} marginTop={2.5} marginBottom={0.5}>
          {name}
        </DiscoText>

        {/* Sales Pitch */}
        <DiscoText
          className={classes.sales}
          variant={"body-sm"}
          marginBottom={2.5}
          align={"center"}
        >
          {description}
        </DiscoText>

        <div className={classes.bottomDetails}>
          {/* Included products */}
          <DiscoChip
            label={pluralize("Product", membershipBenefits?.totalCount, true)}
            marginBottom={2.5}
            color={"blue"}
            testid={"MembershipPlanDetailsCard"}
          />

          {/* Pricing Details */}
          <DiscoText variant={"body-lg-600"}>{pricingValue}</DiscoText>
          <DiscoText variant={"body-xs-500"} color={"groovy.grey.400"} marginBottom={2.5}>
            <div className={classes.pricing}>
              {pricing?.kind === "recurring" && (
                <DiscoIcon
                  icon={"repeat-credit-card"}
                  color={theme.palette.groovy.grey[400]}
                />
              )}
              {getPricingKindLabel(pricing!.kind, pricing?.frequency)}
            </div>
          </DiscoText>

          {/* CTA */}
          <DiscoButton
            data-testid={`${testid}.select-button`}
            width={"100%"}
            onClick={() => onClick(membershipPlan)}
            disabled={alreadyOnPlan || disablePlans}
          >
            {"Choose Plan"}
          </DiscoButton>
        </div>
      </div>
    </DiscoContainerButton>
  )
}

type StyleProps = {
  disabled?: boolean
}

const useStyles = makeUseStyles((theme) => ({
  card: ({ disabled }: StyleProps) => ({
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2.5),
    boxShadow: theme.palette.groovyDepths.insideCard,
    borderRadius: theme.measure.borderRadius.large,
    border: theme.palette.constants.borderSmall,

    "&:hover": {
      boxShadow: theme.palette.groovyDepths.boxShadow,
      cursor: "pointer",
    },

    ...styleIf(disabled, {
      opacity: 0.5,
    }),
  }),
  cover: {
    borderRadius: theme.measure.borderRadius.medium,
  },
  sales: {
    overflowWrap: "anywhere",
  },
  details: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
  },
  bottomDetails: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  pricing: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  cardTooltipWrapper: {
    display: "flex",
  },
}))

export const MembershipPlanDetailsCardSkeleton: React.FC = () => {
  const classes = useStyles({ disabled: false })
  return (
    <div className={classes.card} style={{ width: "312px" }}>
      <CoverPhotoSkeleton className={classes.cover} />

      <div className={classes.details}>
        <DiscoTextSkeleton
          variant={"heading-sm"}
          marginTop={2.5}
          marginBottom={0.5}
          width={"40%"}
        />

        <DiscoTextSkeleton
          variant={"body-sm"}
          marginBottom={2.5}
          align={"center"}
          width={"100%"}
        />

        <div className={classes.bottomDetails}>
          <DiscoChipSkeleton width={"20%"} />

          <DiscoTextSkeleton variant={"body-lg-600"} width={"35%"} marginTop={2.5} />
          <DiscoTextSkeleton
            variant={"body-xs-500"}
            color={"groovy.grey.400"}
            marginBottom={2.5}
            width={"40%"}
          />

          <DiscoButtonSkeleton width={"100%"} />
        </div>
      </div>
    </div>
  )
}

export default Relay.withSkeleton<MembershipPlanDetailsCardProps>({
  component: observer(MembershipPlanDetailsCard),
  skeleton: MembershipPlanDetailsCardSkeleton,
})
