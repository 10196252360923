import { useStartDMFromQueryParams } from "@/chat/hooks/useStartDirectMessage"
import { CommunityAppBarProps } from "@/community/CommunityAppBar"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useStreamChat } from "@/core/context/StreamChatContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { isChatTabRoute } from "@/core/route/util/routeUtils"
import GlobalNavButton from "@/main/page/global-nav/GlobalNavButton"
import useUnreadThreadCount from "@components/chat/channel/util/useUnreadThreadCount"
import { DiscoIcon } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"

type ChatButtonProps = TestIDProps & Pick<CommunityAppBarProps, "variant">

export default function ChatButton(props: ChatButtonProps) {
  const { testid = "ChatButton", variant } = props
  const isActive = isChatTabRoute(location.pathname)
  useStartDMFromQueryParams()
  const { directMessages, communityChannels, productMemberChannels } = useStreamChat()
  const { unreadThreadCount } = useUnreadThreadCount()
  const activeOrganization = useActiveOrganization()!
  const dmsAndChannels = directMessages
    .concat(communityChannels)
    .concat(productMemberChannels)

  return (
    <GlobalNavButton
      testid={testid}
      to={
        activeOrganization.isDmEnabled
          ? ROUTE_NAMES.COMMUNITY.DIRECT_MESSAGES.DETAIL_ROOT
          : ROUTE_NAMES.CHAT.CHANNEL.ROOT
      }
      tooltip={"Chat"}
      isActive={isActive}
      variant={variant}
      notificationConfig={{
        streamChannelIds: dmsAndChannels.map((c) => c.externalChannelId),
        count: unreadThreadCount,
      }}
    >
      <DiscoIcon icon={"messages"} active={isActive} />
    </GlobalNavButton>
  )
}
