import { MemberOnboardingConstants } from "@/member-onboarding/utils/memberOnboardingConstants"
import {
  ContentSystemTaskKind,
  MemberOnboardingContentHeaderFragment$key,
} from "@/member-onboarding/__generated__/MemberOnboardingContentHeaderFragment.graphql"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoText } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
import { graphql, useFragment } from "react-relay"
export interface MemberOnboardingContentHeaderProps extends TestIDProps {
  usageKey?: MemberOnboardingContentHeaderFragment$key
  customContent?: {
    name: React.ReactNode
    description: React.ReactNode
  } & Pick<StyleProps, "systemTaskKind">
  icon?: React.ReactNode
}

function MemberOnboardingContentHeader({
  usageKey,
  customContent,
  testid = "MemberOnboardingContentHeader",
  icon,
}: MemberOnboardingContentHeaderProps) {
  const { content } = useFragment<MemberOnboardingContentHeaderFragment$key>(
    graphql`
      fragment MemberOnboardingContentHeaderFragment on ContentUsage {
        content {
          name
          description
          systemTaskKind
        }
      }
    `,
    usageKey || null
  ) || { content: customContent }

  const classes = useStyles({ systemTaskKind: content?.systemTaskKind })

  if (!content) return null

  return (
    <div className={classes.header}>
      {Boolean(icon) && <div className={classes.iconContainer}>{icon}</div>}
      <DiscoText
        testid={`${testid}.title`}
        variant={"heading-md"}
        align={"center"}
        marginBottom={2.25}
      >
        {content.name}
      </DiscoText>
      <DiscoText
        testid={`${testid}.description`}
        variant={"body-md"}
        align={"center"}
        marginBottom={4}
        className={classes.description}
      >
        {content.description}
      </DiscoText>
    </div>
  )
}

type StyleProps = {
  systemTaskKind?: ContentSystemTaskKind | null
}

const useStyles = makeUseStyles((theme) => ({
  header: {
    maxWidth: MemberOnboardingConstants.CONTENT_MAX_WIDTH,
    marginTop: ({ systemTaskKind }: StyleProps) =>
      systemTaskKind === "onboarding_complete" ? 0 : theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      marginTop: ({ systemTaskKind }: StyleProps) =>
        systemTaskKind === "onboarding_complete" ? 0 : theme.spacing(1.5),
    },
  },
  iconContainer: {
    margin: theme.spacing(0, "auto", 4),
    width: "fit-content",
    display: "flex",
    justifyContent: "space-around",
    columnGap: theme.spacing(3),
    alignItems: "center",
  },
  description: {
    whiteSpace: "pre-line",
  },
}))

export default MemberOnboardingContentHeader
