import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { ClassNameMap } from "@material-ui/core/styles/withStyles"
import classNames from "classnames"
import React from "react"

export interface PageContentProps {
  children: React.ReactNode
  customClassName?: string
  classes?: Partial<ClassNameMap<"container" | "content" | "main" | "rhs">>
  rightColumn?: React.ReactNode
  noMargins?: boolean
}

function PageContent({
  children,
  customClassName,
  classes: propsClasses,
  rightColumn,
  noMargins,
}: PageContentProps) {
  const classes = useStyles({ hasRightColumn: !!rightColumn, noMargins })
  return (
    <div
      className={classNames(classes.pageContentContainer, propsClasses?.container)}
      data-testid={"PageContent.container"}
    >
      <div
        className={classNames(
          classes.pageContent,
          customClassName,
          propsClasses?.content
        )}
      >
        <div className={classNames(classes.main, propsClasses?.main)}>{children}</div>
        {rightColumn && (
          <aside className={classNames(classes.aside, propsClasses?.rhs)}>
            {rightColumn}
          </aside>
        )}
      </div>
    </div>
  )
}

type StyleProps = {
  hasRightColumn: boolean
  noMargins?: boolean
}

const useStyles = makeUseStyles((theme) => ({
  pageContentContainer: {
    display: "flex",
    /** flex: 1 required to stretch content remaining height of AppPageLayout container*/
    flex: "1",
    flexDirection: "column",
    justifyContent: "flex-start",
    minWidth: 0,

    margin: (props: StyleProps) => (props.noMargins ? 0 : theme.spacing(3)),
    [theme.breakpoints.down("xs")]: {
      margin: (props: StyleProps) => (props.noMargins ? 0 : theme.spacing(1)),
    },
  },
  pageContent: {
    position: "relative",
    width: "100%",
    height: "100%",
    margin: theme.spacing(0, "auto"),
    maxWidth: `${theme.measure.page.contentMaxWidth}px`,
    display: "grid",
    gap: theme.spacing(3),
    gridTemplateColumns: "1fr",
    gridTemplateRows: "1fr",
    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: ({ hasRightColumn }: StyleProps) =>
        hasRightColumn ? "1fr 304px" : "1fr",
    },
  },
  main: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    minWidth: 0,
  },
  aside: {
    // Needed to prevent the aside width from growing past the constraints of it's parent
    // prevents a bug where the EventsDashboardBlockCardView would grow past the page content in mobile
    minWidth: 0,
  },
}))

export default PageContent
