import AdminExpiredTrialPage from "@/admin/AdminExpiredTrialPage"
import AdminPlanPausedPage from "@/admin/AdminPlanPausedPage"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { OrganizationSubscriptionStanding } from "@utils/hook/__generated__/useSubscriptionStandingActiveOrganizationFragment.graphql"
import CommunityInaccessiblePage from "./CommunityInaccessiblePage"

interface Props {
  testid?: string
  standing?: OrganizationSubscriptionStanding
}

function CommunityNonActiveStandingPage(props: Props) {
  const { standing } = props
  const activeOrganization = useActiveOrganization()
  // If the viewer is not an owner or admin and the standing is bad, push them to the inaccessible page
  if (!activeOrganization?.viewerIsOwnerOrAdmin && standing !== "active") {
    return <CommunityInaccessiblePage />
  }
  switch (standing) {
    case "trial_expired": {
      return <AdminExpiredTrialPage />
    }
    case "paused": {
      return <AdminPlanPausedPage />
    }
    default: {
      return <CommunityInaccessiblePage />
    }
  }
}

export default CommunityNonActiveStandingPage
