import makeUseStyles from "@assets/style/util/makeUseStyles"
import DiscoImage from "@disco-ui/image/DiscoImage"
import { ClassNameMap } from "@material-ui/core/styles/withStyles"
import classNames from "classnames"

type Props = {
  src: string
  children: React.ReactNode
  classes?: Partial<ClassNameMap<"container" | "image" | "content">>
}

export default function MemberOnboardingSlackBackgroundWrapper({
  src,
  children,
  classes: customClasses,
}: Props) {
  const classes = useStyles()
  return (
    <div className={classNames(classes.tabContentContainer, customClasses?.container)}>
      <DiscoImage
        src={src}
        className={classNames(classes.backgroundImage, customClasses?.image)}
      />
      <div className={classNames(classes.content, customClasses?.content)}>
        {children}
      </div>
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  tabContentContainer: {
    display: "grid",
    gridTemplateAreas: "'a'",
    justifyItems: "center",
    width: "100%",
  },
  backgroundImage: {
    gridArea: "a",
    zIndex: -1,
    alignSelf: "start",
  },
  content: {
    gridArea: "a",
    padding: theme.spacing(9, 9, 0),
    width: "100%",
  },
}))
