import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import FormStore from "@/core/form/store/FormStore"
import { CreateInvitationFormState } from "@/invitation/create/form/CreateInvitationForm"
import { InvitationFormDiscountSelectQuery } from "@/invitation/create/form/fields/__generated__/InvitationFormDiscountSelectQuery.graphql"
import Relay from "@/relay/relayUtils"
import { DiscoSelect, DiscoText } from "@disco-ui"
import { observer } from "mobx-react-lite"
import { graphql, useLazyLoadQuery } from "react-relay"

interface Props {
  form: FormStore<CreateInvitationFormState>
}

function InvitationFormDiscountSelect(props: Props) {
  const { form } = props
  const activeOrganization = useActiveOrganization()!
  const { organization } = useLazyLoadQuery<InvitationFormDiscountSelectQuery>(
    graphql`
      query InvitationFormDiscountSelectQuery($id: ID!) {
        organization: node(id: $id) {
          ... on Organization {
            discounts {
              edges {
                node {
                  id
                  code
                  name
                }
              }
            }
          }
        }
      }
    `,
    {
      id: activeOrganization.id,
    }
  )

  const discounts = Relay.connectionToArray(organization?.discounts)

  return (
    <>
      <DiscoText variant={"body-md-600"} padding={1}>
        {"Select Discount"}
      </DiscoText>
      <DiscoSelect
        placeholder={"Select Discount"}
        value={form.state.discountId}
        onChange={handleSelect}
        disableClearable
        options={discounts.map((discount) => ({
          value: discount.id,
          title: discount.name,
          searchable: [discount.name, discount.code],
        }))}
        renderOption={(option) => {
          return (
            <DiscoText
              data-testid={`InvitationFormDiscountSelect.option.${option.title}`}
            >
              {option.title}
            </DiscoText>
          )
        }}
        disabled={discounts.length === 0}
      />
    </>
  )

  function handleSelect(v: string | null) {
    if (!v) return
    form.state.discountId = v
  }
}
export default observer(InvitationFormDiscountSelect)
