import { OrganizationAssetsOrderByFieldFilter } from "@/admin/media-library/__generated__/AdminMediaLibraryListPagePaginationQuery.graphql"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { MediaLibraryToolbarState } from "@/media/add/MediaLibraryFileFields"
import MediaListItem from "@/media/add/list/MediaListItem"
import {
  AssetFileType,
  MediaFileListTemplatePagePaginationQuery,
  OrderDirection,
} from "@/media/add/list/__generated__/MediaFileListTemplatePagePaginationQuery.graphql"
import { MediaFileListTemplateQuery } from "@/media/add/list/__generated__/MediaFileListTemplateQuery.graphql"
import { MediaFileListTemplate_PaginationFragment$key } from "@/media/add/list/__generated__/MediaFileListTemplate_PaginationFragment.graphql"
import Relay from "@/relay/relayUtils"
import FloppyDiskIcon from "@/core/ui/images/empty-state/floppy-disk.svg"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { MimeTypes } from "@components/dropzone/FileDropzone"
import { MediaResult } from "@components/media/upload/hooks/useMultipartUploadMediaToS3"
import { DiscoEmptyState, DiscoText, DiscoTextSkeleton } from "@disco-ui"
import DiscoGrid from "@disco-ui/layout/DiscoGrid"
import { Skeleton } from "@material-ui/lab"
import { TestIDProps } from "@utils/typeUtils"

import { useLazyLoadQuery, usePaginationFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface MediaFileListTemplateProps extends TestIDProps {
  onMediaSelect: (result: MediaResult) => void
  onClose: () => void
  toolbarState: MediaLibraryToolbarState
  fileType: AssetFileType
  allowedMimeTypes?: MimeTypes[]
}

const ITEMS_PER_PAGE = 16

function MediaFileListTemplate({
  onClose,
  onMediaSelect,
  toolbarState,
  fileType,
  allowedMimeTypes,
}: MediaFileListTemplateProps) {
  const activeOrganization = useActiveOrganization()!
  const classes = useStyles()
  const { viewType } = toolbarState
  // Choose the class based on toolbarState.viewType
  const assetContainerClass =
    toolbarState.viewType === "grid" && fileType !== "document"
      ? classes.assetContainerGrid
      : classes.assetContainerList

  const { organization } = useLazyLoadQuery<MediaFileListTemplateQuery>(
    graphql`
      query MediaFileListTemplateQuery(
        $id: ID!
        $fileType: AssetFileType
        $search: String
        $orderBy: OrganizationAssetsOrderByInput
        $first: Int!
        $after: String
        $mimeTypes: [String!]
      ) {
        organization: node(id: $id) {
          ... on Organization {
            ...MediaFileListTemplate_PaginationFragment
              @arguments(
                fileType: $fileType
                search: $search
                orderBy: $orderBy
                first: $first
                after: $after
                mimeTypes: $mimeTypes
              )
          }
        }
      }
    `,
    {
      id: activeOrganization.id,
      search: toolbarState.search,
      fileType,
      mimeTypes: allowedMimeTypes,
      orderBy: {
        field: toolbarState.sort.order.field as OrganizationAssetsOrderByFieldFilter,
        direction: toolbarState.direction as OrderDirection,
      },
      first: ITEMS_PER_PAGE,
    },
    { fetchPolicy: "store-and-network" }
  )

  const { data, loadNext, hasNext, isLoadingNext } = usePaginationFragment<
    MediaFileListTemplatePagePaginationQuery,
    MediaFileListTemplate_PaginationFragment$key
  >(
    graphql`
      fragment MediaFileListTemplate_PaginationFragment on Organization
      @refetchable(queryName: "MediaFileListTemplatePagePaginationQuery")
      @argumentDefinitions(
        fileType: { type: "AssetFileType" }
        search: { type: "String" }
        orderBy: { type: "OrganizationAssetsOrderByInput" }
        first: { type: "Int!" }
        after: { type: "String" }
        mimeTypes: { type: "[String!]" }
      ) {
        assets(
          fileType: $fileType
          search: $search
          orderBy: $orderBy
          first: $first
          after: $after
          mimeTypes: $mimeTypes
        ) @connection(key: "MediaFileListTemplate__assets") {
          __id
          totalCount
          edges {
            node {
              id
              fileType
              mimeType
              ...MediaListItemFragment
            }
          }
        }
      }
    `,
    organization
  )

  if (!data) return null

  const fileAssets = Relay.connectionToArray(data.assets)
  return (
    <div className={classes.headerAssetContainer}>
      <DiscoText
        color={"text.secondary"}
        variant={"body-xs-600-uppercase"}
        testid={`${fileType.charAt(0).toUpperCase() + fileType.slice(1)}FileList.title`}
      >
        {fileType === "image"
          ? "MY IMAGES"
          : fileType === "video"
          ? "MY VIDEOS"
          : fileType === "document"
          ? "MY DOCUMENTS"
          : "MY FILES"}
      </DiscoText>
      {fileAssets.length > 0 ? (
        <DiscoGrid
          data={fileAssets}
          customScrollParentSelector={"#media-library-popover"}
          item={(index, as) => {
            const a = as[index]
            return (
              <MediaListItem
                key={a.id}
                mediaItemKey={a}
                onMediaSelect={onMediaSelect}
                onClose={onClose}
                testid={`MediaListItem.${a.fileType}.${index}`}
                viewType={viewType}
              />
            )
          }}
          listClassName={assetContainerClass}
          refetch={{
            isLoading: isLoadingNext,
            hasNext,
            loadMore: () => loadNext(ITEMS_PER_PAGE),
          }}
        />
      ) : (
        <DiscoEmptyState
          testid={`${fileType.charAt(0).toUpperCase() + fileType.slice(1)}FileList`}
          icon={<FloppyDiskIcon width={"92px"} height={"96px"} />}
          subtitle={"Your files will start to appear here as you upload them to Disco."}
        />
      )}
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  headerAssetContainer: {
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(2, 0),
    paddingBottom: theme.spacing(2),
  },
  // Grid style view
  assetContainerGrid: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    gap: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
    },
    alignItems: "center",
    justifyContent: "stretch",
    marginTop: theme.spacing(2),
  },
  // List style view
  assetContainerList: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    alignItems: "left",
    justifyContent: "stretch",
    marginTop: theme.spacing(2),
  },
}))

export function FileListSkeleton() {
  const classes = useStyles()

  return (
    <div className={classes.headerAssetContainer}>
      <DiscoTextSkeleton variant={"body-xs-600-uppercase"} width={100} />
      <div className={classes.assetContainerGrid}>
        <Skeleton variant={"rect"} height={66} />
        <Skeleton variant={"rect"} height={66} />
        <Skeleton variant={"rect"} height={66} />
        <Skeleton variant={"rect"} height={66} />
        <Skeleton variant={"rect"} height={66} />
        <Skeleton variant={"rect"} height={66} />
        <Skeleton variant={"rect"} height={66} />
        <Skeleton variant={"rect"} height={66} />
      </div>
    </div>
  )
}

export default Relay.withSkeleton({
  component: MediaFileListTemplate,
  skeleton: FileListSkeleton,
})
