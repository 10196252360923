import { GlobalDrawerParams } from "@/core/context/GlobalDrawerProvider"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { DeleteMeetingProviderFormFragment$data } from "@/meeting-provider/common/delete/form/__generated__/DeleteMeetingProviderFormFragment.graphql"
import { isOccurrenceLive } from "@/occurrence/util/occurrenceUtils"
import Relay from "@/relay/relayUtils"
import { DiscoLink, DiscoText } from "@disco-ui"
import { setSearchParams } from "@utils/url/urlUtils"
import { useLocation } from "react-router-dom"

interface Props {
  connections: DeleteMeetingProviderFormFragment$data["occurrences"]
}

function DeleteMeetingProviderEventsFields(props: Props) {
  const { connections } = props
  const occurrences = Relay.connectionToArray(connections)
  const classes = useStyles()
  const location = useLocation()

  // We don't need to change the meeting provider if the event is live
  const futureOccurrences = occurrences.filter((o) => !isOccurrenceLive(o.datetimeRange))

  return (
    <>
      {futureOccurrences.map((occurrence) => (
        <div key={occurrence.id} className={classes.eventListItem}>
          <DiscoText variant={"body-md-600"} className={classes.eventTitle}>
            {occurrence.name}
          </DiscoText>
          <DiscoLink
            to={{
              ...location,
              search: setSearchParams<GlobalDrawerParams<"event">>("", {
                drawerEventTab: "settings",
                drawerOccurrenceId: occurrence.id,
              }),
            }}
            className={classes.settingsButton}
            // we could open drawer in same window, but it shows up below the modal
            // instead, just open in a new window until we have a better way to handle this issue
            target={"_blank"}
          >
            {"Event Settings"}
          </DiscoLink>
        </div>
      ))}
    </>
  )
}

const useStyles = makeUseStyles((theme) => ({
  eventListItem: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(3),
    gap: theme.spacing(1),
  },
  eventTitle: {
    lineHeight: "24px",
    fontWeight: 600,
  },
  settingsButton: {
    flexShrink: 0,
    color: theme.palette.primary.main,
  },
}))

export default DeleteMeetingProviderEventsFields
