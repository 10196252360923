/**
 * @generated SignedSource<<f5adbcbc889a5145e43411d484931ef3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ResetInvitationLinkButtonMutation$variables = {
  organizationId: string;
};
export type ResetInvitationLinkButtonMutation$data = {
  readonly response: {
    readonly node: {
      readonly key: string;
    } | null;
  };
};
export type ResetInvitationLinkButtonMutation = {
  variables: ResetInvitationLinkButtonMutation$variables;
  response: ResetInvitationLinkButtonMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ResetInvitationLinkButtonMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "ResetInvitationLinkResponse",
        "kind": "LinkedField",
        "name": "resetInvitationLink",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "InvitationLink",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ResetInvitationLinkButtonMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "ResetInvitationLinkResponse",
        "kind": "LinkedField",
        "name": "resetInvitationLink",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "InvitationLink",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4a5f455e2e2ebece976cdb18c2fb85af",
    "id": null,
    "metadata": {},
    "name": "ResetInvitationLinkButtonMutation",
    "operationKind": "mutation",
    "text": "mutation ResetInvitationLinkButtonMutation(\n  $organizationId: ID!\n) {\n  response: resetInvitationLink(organizationId: $organizationId) {\n    node {\n      key\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "499d08e18d4a2c1905e223d66be42002";

export default node;
