import { useAuthUser } from "@/core/context/AuthUserContext"
import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import NotificationCenterSubtabs from "@/notification/drawer/NotificationCenterSubtabs"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import {
  DiscoDrawer,
  DiscoDrawerHeader,
  DiscoIcon,
  DiscoIconButton,
  DiscoSpinner,
  DiscoTooltip,
} from "@disco-ui"
import lazyWithRetry from "@utils/lazy/lazyWithRetry"

const NotificationCenterDrawerContent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "notification-center-drawer-content" */ "@/notification/drawer/NotificationCenterDrawerContent"
    )
)

function NotificationCenterDrawer() {
  const drawer = useGlobalDrawer("notificationCenter")
  const { authUser } = useAuthUser()
  const profileDrawer = useGlobalDrawer("profileSettings")
  const classes = useStyles()

  return (
    <DiscoDrawer
      open={drawer.isOpen}
      size={"medium"}
      onClose={drawer.close}
      containerClasses={{
        drawerContainer: classes.drawer,
      }}
    >
      {/* Content */}
      <DiscoDrawerHeader
        className={classes.header}
        testid={"NotificationCenterDrawer"}
        title={"Notifications"}
        disableExpand
        headerActions={
          <DiscoTooltip
            content={"Update Your Email Notification Settings"}
            placement={"left"}
          >
            <DiscoIconButton
              testid={"NotificationCenterDrawer.email-notification"}
              size={"medium"}
              onClick={handleOpenProfileNotificationTab}
            >
              <DiscoIcon icon={"settings"} />
            </DiscoIconButton>
          </DiscoTooltip>
        }
        onClose={drawer.close}
        customSubTabs={<NotificationCenterSubtabs />}
      />
      <NotificationCenterDrawerContent />
    </DiscoDrawer>
  )

  function handleOpenProfileNotificationTab() {
    if (!authUser) return

    profileDrawer.open({
      drawerProfileId: authUser.id,
      profileSettingsTab: "notifications",
    })
  }
}

const useStyles = makeUseStyles((theme) => ({
  header: {
    padding: theme.spacing(3, 3, 0, 3),
  },
  drawer: {
    padding: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2),
    },
  },
}))

export default Relay.withSkeleton({
  component: NotificationCenterDrawer,
  skeleton: () => <DiscoSpinner />,
})
