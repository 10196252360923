import { useLabel } from "@/core/context/LabelsContext"
import MembershipPlanExperienceDetails from "@/membership-plan/list/MembershipPlanExperienceDetails"
import { MembershipPlanIncludedProductsFragment$key } from "@/membership-plan/register/__generated__/MembershipPlanIncludedProductsFragment.graphql"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoText } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface MembershipPlanIncludedProductsProps extends TestIDProps {
  membershipPlanKey: MembershipPlanIncludedProductsFragment$key
}

function MembershipPlanIncludedProducts({
  membershipPlanKey,
  testid = "MembershipPlanIncludedProducts",
}: MembershipPlanIncludedProductsProps) {
  const classes = useStyles()
  const experienceLabel = useLabel("experience")
  const membershipPlan = useFragment<MembershipPlanIncludedProductsFragment$key>(
    graphql`
      fragment MembershipPlanIncludedProductsFragment on Product
      @argumentDefinitions(
        hideDrafts: { type: "Boolean!" }
        hideNonPublic: { type: "Boolean!" }
      ) {
        id
        membershipBenefits(hideDrafts: $hideDrafts, hideNonPublic: $hideNonPublic) {
          edges {
            node {
              id
              product {
                ...MembershipPlanExperienceDetailsFragment
              }
            }
          }
        }
      }
    `,
    membershipPlanKey
  )

  const membershipBenefits = Relay.connectionToArray(membershipPlan.membershipBenefits)

  if (!membershipBenefits.length) return null

  return (
    <div>
      <DiscoText variant={"body-md-600"}>{experienceLabel.plural}</DiscoText>
      <div className={classes.conatiner}>
        {membershipBenefits.map((mb) => {
          if (!mb.product) return null
          return (
            <MembershipPlanExperienceDetails
              key={mb.id}
              productKey={mb.product}
              hideAlert
              testid={`${testid}.MembershipPlanExperienceDetails`}
            />
          )
        })}
      </div>
    </div>
  )
}

const useStyles = makeUseStyles({
  conatiner: {
    display: "flex",
    flexDirection: "column",
  },
})

export default MembershipPlanIncludedProducts
