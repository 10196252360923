import HiddenForRoutes from "@/core/route/hidden-for-routes/HiddenForRoutes"
import { SIDEBARS_HIDDEN_ROUTES } from "@/main/page/AppPageLayout"
import TemporarySideBar from "@/main/page/side-bar/temporary-sidebar/TemporarySideBar"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import AuthenticatedUserDropdown from "@components/authenticated-user-dropdown/AuthenticatedUserDropdown"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import { TestIDProps } from "@utils/typeUtils"

function HeaderMobileRhs({ testid = "HeaderMobileRhs" }: TestIDProps) {
  const classes = useStyles()
  const isMobile = useIsMobile()

  if (!isMobile) return null

  return (
    <div data-testid={testid} className={classes.container}>
      <div className={classes.iconGroup}>
        {/* Sidebar */}
        <HiddenForRoutes exact path={SIDEBARS_HIDDEN_ROUTES}>
          <TemporarySideBar />
        </HiddenForRoutes>
      </div>

      {/* Profile */}
      <AuthenticatedUserDropdown />
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "grid",
    gridAutoFlow: "column",
    alignItems: "center",
  },
  iconGroup: {
    display: "flex",
    paddingRight: theme.spacing(1),
  },
}))

export default HeaderMobileRhs
