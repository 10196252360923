import { CommunityAppBarProps } from "@/community/CommunityAppBar"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import NotificationBadge, {
  NotificationBadgeProps,
} from "@components/square-count-badge/NotificationBadge"
import { DiscoIconButton, DiscoIconButtonProps, DiscoText } from "@disco-ui"
import { getContrastRatio, useTheme } from "@material-ui/core"
import { useMemo } from "react"

type GlobalNavButtonProps = Pick<
  DiscoIconButtonProps,
  "testid" | "onClick" | "to" | "children" | "tooltip" | "linkProps"
> &
  Pick<CommunityAppBarProps, "variant"> & {
    isActive?: boolean
  } & { notificationConfig?: NotificationBadgeProps["config"] }

export default function GlobalNavButton(props: GlobalNavButtonProps) {
  const {
    children,
    variant,
    isActive = false,
    notificationConfig,
    tooltip,
    ...rest
  } = props

  const theme = useTheme()
  const themeProps = useMemo(() => {
    // The new global nav area uses the community's customizable background color, so we
    // need to ensure the button colors contrast with this instead of the usual "paper"
    const isDark = getContrastRatio("#fff", theme.palette.background.nav) > 3.5
    return {
      color: isDark ? "rgba(255, 255, 255, 0.6)" : "rgba(0, 0, 0, 0.6)",
      hoverColor: isDark ? theme.palette.common.white : theme.palette.common.black,
      hoverBackgroundColor: isDark ? "rgba(255, 255, 255, 0.2)" : "rgba(0, 0, 0, 0.1)",
      tooltipPlacement: variant === "vertical" ? ("right" as const) : ("top" as const),
    }
  }, [variant, theme])

  const activeOrganization = useActiveOrganization()!
  const showTitle = activeOrganization.showGlobalTabTitles && variant === "vertical"
  const classes = useStyles({
    color: themeProps.color,
    hoverColor: themeProps.hoverColor,
    hoverBackgroundColor: themeProps.hoverBackgroundColor,
    isActive,
    showTitle,
  })

  return (
    <DiscoIconButton
      {...rest}
      {...themeProps}
      color={isActive ? themeProps.hoverColor : themeProps.color}
      hoverBackgroundColor={"transparent"}
      size={"medium"}
      height={showTitle ? undefined : 40}
      width={showTitle ? "100%" : 40}
      tooltip={showTitle ? undefined : tooltip}
      classes={{ label: classes.root }}
    >
      <>
        <div className={classes.icon}>
          {children}
          {notificationConfig && (
            <NotificationBadge
              className={classes.notification}
              config={notificationConfig}
              size={16}
              fontSize={9}
            />
          )}
        </div>
        {showTitle && (
          <DiscoText variant={"body-xs-500"} className={classes.title}>
            {tooltip}
          </DiscoText>
        )}
      </>
    </DiscoIconButton>
  )
}

type StyleProps = {
  color?: string
  hoverColor?: string
  hoverBackgroundColor?: string
  isActive: boolean
  showTitle: boolean
}

const useStyles = makeUseStyles((theme) => ({
  root: ({ hoverColor, hoverBackgroundColor }: StyleProps) => ({
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& svg": {
      transition: "all 0.1s ease-out",
    },
    "&:hover": {
      "& $title": {
        color: hoverColor,
      },
      "& $icon": {
        backgroundColor: hoverBackgroundColor,
      },
      "& svg": {
        height: 26,
        width: 26,
      },
    },
  }),
  icon: ({ isActive, hoverBackgroundColor }: StyleProps) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    borderRadius: theme.measure.borderRadius.medium,
    backgroundColor: isActive ? hoverBackgroundColor : undefined,
    height: 40,
    width: 40,
    transition: "background-color 0.1s ease-out",
  }),
  title: ({ isActive, color, hoverColor }: StyleProps) => ({
    color: isActive ? hoverColor : color,
    textAlign: "center",
    transition: "color 0.1s ease-out",
  }),
  notification: ({ showTitle }: StyleProps) => ({
    position: "absolute",
    top: showTitle ? 4.5 : 4.5,
    right: showTitle ? 1 : 1,
    border: `1.5px solid ${theme.palette.background.nav}`,
  }),
}))
