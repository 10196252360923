import MemberOnboardingSlackInstructions from "@/member-onboarding/slack-flow/MemberOnboardingSlackInstructions"

function Desktop() {
  return (
    <MemberOnboardingSlackInstructions
      instructions={[
        {
          title: "Open Slack",
          steps: [
            {
              title: `Open Slack on your desktop`,
              bullets: [
                "Find and open the Slack desktop app or visit slack.com to download it.",
                "Sign in to your account.",
              ],
              note: "Your Slack account must use the same email or method of sign-up as used when creating your Disco account.",
            },
          ],
        },
        {
          title: "Find the community Slack workspace",
          steps: [
            {
              title: "Where to find the workspace",
              bullets: [
                "In the desktop app, this is found on the lefthand side of the Slack interface.",
              ],
            },
          ],
        },
        {
          title: `Locate the Disco App in the "Apps" section`,
          steps: [
            {
              title: "Where to find Slack Apps",
              bullets: [
                `Apps are usually found below the "Direct Messages" section, however this interface is customizable so they may appear further up the list depending on your set-up.`,
              ],
            },
          ],
        },
        {
          title: "Connect Disco to the Disco App",
          steps: [
            {
              title: "How to connect to the App",
              bullets: [
                `Click "Connect Now" to start the connection process.`,
                "If you are signed into your Disco account on your device, the connection will be created instantly.",
                "Simply sign in to Disco to complete the connection if you aren't signed in already.",
                "That's it - You've done it! Return to your community and explore all it has to offer!",
              ],
            },
          ],
        },
      ]}
    />
  )
}
export default Desktop
