import { CommunityAppBarProps } from "@/community/CommunityAppBar"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { isBrainSearchPath } from "@/core/route/util/routeUtils"
import GlobalNavButton from "@/main/page/global-nav/GlobalNavButton"
import { DiscoIcon } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"

interface BrainSearchNavButtonProps
  extends TestIDProps,
    Pick<CommunityAppBarProps, "variant"> {}

function BrainSearchNavButton({
  testid = "BrainSearchNavButton",
  variant,
}: BrainSearchNavButtonProps) {
  const isActive = isBrainSearchPath(location.pathname)

  return (
    <GlobalNavButton
      testid={testid}
      tooltip={"Ask AI"}
      isActive={isActive}
      variant={variant}
      to={ROUTE_NAMES.BRAIN.ROOT}
    >
      <DiscoIcon icon={"stars"} />
    </GlobalNavButton>
  )
}

export default BrainSearchNavButton
