import MetaTitle from "@/main/page/MetaTitle"
import React from "react"
import { Helmet } from "react-helmet-async"

export interface MetaSharingProps {
  url?: string
  title?: string
  description?: string
  coverPhoto?: string
}

export interface MetaDetailsProps {
  children?: React.ReactNode
  metaTitle?: string
  metaDetailsForSharing?: MetaSharingProps
}

function MetaDetails({ children, metaTitle, metaDetailsForSharing }: MetaDetailsProps) {
  return (
    <>
      <Helmet
        meta={generateMetaTags(metaDetailsForSharing)}
        link={
          metaDetailsForSharing
            ? [{ rel: "canonical", href: metaDetailsForSharing.url }]
            : undefined
        }
      />
      <MetaTitle>{metaTitle}</MetaTitle>
      {children}
    </>
  )
}

export function generateMetaTags(meta?: MetaSharingProps) {
  if (!meta) return []
  const { url, title, description, coverPhoto } = meta
  const imageUrl = coverPhoto || ""
  return [
    { name: "title", property: "og:title", content: title },
    { name: "description", property: "og:description", content: description },
    { property: "og:url", content: url },
    {
      name: "image",
      property: "og:image",
      content: imageUrl,
    },
    // twitter-specific
    { name: "twitter:card", content: "summary" },
    { name: "twitter:title", content: title },
    { name: "twitter:text:title", content: title },
    { name: "twitter:description", content: description },
    {
      name: "twitter:image",
      content: imageUrl,
    },
  ]
}

export default MetaDetails
