import { useLabel } from "@/core/context/LabelsContext"
import CreateInvitationForm, {
  CreateInvitationFormSkeleton,
} from "@/invitation/create/form/CreateInvitationForm"
import { ProductRole } from "@/invitation/create/form/__generated__/CreateInvitationFormMutation.graphql"
import { GlobalID } from "@/relay/RelayTypes"
import { DiscoModal } from "@disco-ui"

interface CreateInvitationModalProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: () => void
  productId?: GlobalID | null
  onlyAdminRoles?: boolean
  defaultProductRole?: ProductRole
  givenEmails?: string[]
}

const CreateInvitationModal: React.FC<CreateInvitationModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  productId = null,
  onlyAdminRoles,
  defaultProductRole,
  givenEmails,
}) => {
  const membersLabel = useLabel("admin_member")
  const experienceLabel = useLabel("admin_experience")

  return (
    <DiscoModal
      title={`${
        productId ? `Invite to ${experienceLabel.singular}` : `Invite to Community`
      }`}
      modalContentLabel={`Invite ${membersLabel.plural}`}
      isOpen={isOpen}
      width={"720px"}
      buttons
      body={
        isOpen ? (
          <CreateInvitationForm
            onCancel={onClose}
            onSubmit={onSubmit}
            productId={productId}
            onlyAdminRoles={onlyAdminRoles}
            defaultProductRole={defaultProductRole}
            givenEmails={givenEmails}
          />
        ) : (
          <CreateInvitationFormSkeleton />
        )
      }
      testid={"CreateInvitationModal"}
      onClose={onClose}
    />
  )
}

export default CreateInvitationModal
