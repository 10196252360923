import { CommunityAppBarProps } from "@/community/CommunityAppBar"
import { useLabel } from "@/core/context/LabelsContext"
import { useStreamChat } from "@/core/context/StreamChatContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import {
  isAdminConsolePath,
  isChatTabRoute,
  isEventsTabRoute,
  isProductsTabRoute,
} from "@/core/route/util/routeUtils"
import GlobalNavButton from "@/main/page/global-nav/GlobalNavButton"
import { DiscoIcon } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"

type CommunityButtonProps = TestIDProps & Pick<CommunityAppBarProps, "variant">

export default function CommunityButton(props: CommunityButtonProps) {
  const { testid = "CommunityButton", variant } = props
  const isActive = getIsActive()
  const { communityChannels } = useStreamChat()
  const sidebarCommunity = useLabel("sidebar_community")

  return (
    <GlobalNavButton
      testid={testid}
      to={ROUTE_NAMES.COMMUNITY.HOME.ROOT}
      tooltip={sidebarCommunity.singular}
      isActive={isActive}
      variant={variant}
      notificationConfig={{
        filter: {
          productId: null,
          kinds: ["course-content-publish"],
        },
        streamChannelIds: communityChannels.map((cc) => cc.externalChannelId),
      }}
    >
      <DiscoIcon icon={"home-alt"} active={isActive} />
    </GlobalNavButton>
  )

  // The best way I could come up with to determine if the Community button should be active
  // is if we're NOT on any route that would cause another button to be active.
  function getIsActive() {
    if (isProductsTabRoute(location.pathname)) return false
    if (isAdminConsolePath(location.pathname)) return false
    if (isEventsTabRoute(location.pathname)) return false
    if (isChatTabRoute(location.pathname)) return false
    return true
  }
}
