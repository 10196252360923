/**
 * @generated SignedSource<<d6ecec2c443479c3bb7ddcc1495e55f0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InvitationFormCopyLinkField__Product$data = {
  readonly id: string;
  readonly slug: string;
  readonly cover: string;
  readonly name: string;
  readonly description: string | null;
  readonly landingPage: {
    readonly metaImageUrl: string | null;
    readonly metaTitle: string | null;
    readonly metaDescription: string | null;
  } | null;
  readonly " $fragmentType": "InvitationFormCopyLinkField__Product";
};
export type InvitationFormCopyLinkField__Product$key = {
  readonly " $data"?: InvitationFormCopyLinkField__Product$data;
  readonly " $fragmentSpreads": FragmentRefs<"InvitationFormCopyLinkField__Product">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InvitationFormCopyLinkField__Product",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cover",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LandingPage",
      "kind": "LinkedField",
      "name": "landingPage",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "metaImageUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "metaTitle",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "metaDescription",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Product",
  "abstractKey": null
};

(node as any).hash = "bd2b33851245a11a65068f1d26228c00";

export default node;
