/**
 * @generated SignedSource<<605593081929f762df578b235c97bc78>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InvitationFormEmailsField__Product$data = {
  readonly id: string;
  readonly slug: string;
  readonly " $fragmentSpreads": FragmentRefs<"InvitationFormMemberFieldsFragment" | "InvitationFormRoleSelectFragment">;
  readonly " $fragmentType": "InvitationFormEmailsField__Product";
};
export type InvitationFormEmailsField__Product$key = {
  readonly " $data"?: InvitationFormEmailsField__Product$data;
  readonly " $fragmentSpreads": FragmentRefs<"InvitationFormEmailsField__Product">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InvitationFormEmailsField__Product",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InvitationFormMemberFieldsFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InvitationFormRoleSelectFragment"
    }
  ],
  "type": "Product",
  "abstractKey": null
};

(node as any).hash = "e8c6cc507747f4242a0d003b215c3a90";

export default node;
