/**
 * @generated SignedSource<<85d508f91aff208659695f802e81a601>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteMeetingProviderInput = {
  meetingProviderId: string;
};
export type DeleteMeetingProviderFormMutation$variables = {
  input: DeleteMeetingProviderInput;
};
export type DeleteMeetingProviderFormMutation$data = {
  readonly response: string;
};
export type DeleteMeetingProviderFormMutation = {
  variables: DeleteMeetingProviderFormMutation$variables;
  response: DeleteMeetingProviderFormMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": "response",
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "kind": "ScalarField",
    "name": "deleteMeetingProvider",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteMeetingProviderFormMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteMeetingProviderFormMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "aa811ead351ebfed6ba8d1f2abb51028",
    "id": null,
    "metadata": {},
    "name": "DeleteMeetingProviderFormMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteMeetingProviderFormMutation(\n  $input: DeleteMeetingProviderInput!\n) {\n  response: deleteMeetingProvider(input: $input)\n}\n"
  }
};
})();

(node as any).hash = "d8a4f235066d2d77aed7672bbf37059b";

export default node;
