import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useLabel } from "@/core/context/LabelsContext"
import RocketIcon from "@/core/ui/iconsax/linear/custom-rocket.svg"
import MembershipPlanSelectButton from "@/membership-plan/register/MembershipPlanSelectButton"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoButton, DiscoText } from "@disco-ui"
import DiscoTag from "@disco-ui/tag/DiscoTag"
import { useTheme } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"

interface Props extends TestIDProps {
  className?: string
}

function MembershipPlanSelectSection({
  testid = "MembershipPlanSelectSection",
  className,
}: Props) {
  const classes = useStyles()
  const experienceLabel = useLabel("experience")
  const theme = useTheme()
  const activeProduct = useActiveProduct()
  const activeOrganization = useActiveOrganization()
  const isSwitchingPlans = Boolean(
    activeOrganization?.viewerMembershipPlan && activeOrganization?.viewerMembership
  )

  return (
    <div className={classNames(classes.plansContainer, className)}>
      <DiscoTag
        leftIcon={<RocketIcon width={16} color={theme.palette.primary.main} />}
        name={"Membership"}
        color={theme.palette.groovy.blue[400]}
        backgroundColor={theme.palette.groovy.blue[100]}
        marginBottom={1.5}
      />

      <DiscoText variant={"body-md-600"} paddingBottom={0.5}>
        {isSwitchingPlans ? "Update Plan" : "Join Community"}
      </DiscoText>

      <DiscoText
        variant={"body-sm"}
        color={"text.secondary"}
        paddingBottom={1.5}
      >{`Join the community with a membership plan to get access to multiple ${experienceLabel.plural}.`}</DiscoText>

      <MembershipPlanSelectButton experienceId={activeProduct?.id}>
        {(buttonProps) => (
          <DiscoButton
            {...buttonProps}
            color={"grey"}
            variant={"outlined"}
            testid={`${testid}.select-button`}
            width={"100%"}
          >
            {"Select Membership"}
          </DiscoButton>
        )}
      </MembershipPlanSelectButton>
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  plansContainer: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(3),
    borderLeft: `1px solid ${theme.palette.groovy.neutral[100]}`,
    borderRadius: theme.measure.borderRadius.big,
    border: `1px solid ${theme.palette.groovy.neutral[100]}`,
    height: "fit-content",
    minWidth: "300px",
    width: "100%",
    maxWidth: "600px",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}))

export default MembershipPlanSelectSection
