/**
 * @generated SignedSource<<61de7dd6c3844a31e4023ef45f44f432>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MemberOnboardingSlackAutoConnectSuccessFragment$data = {
  readonly content: {
    readonly organization: {
      readonly slack: {
        readonly workspace: {
          readonly name: string | null;
          readonly url: string | null;
        } | null;
      } | null;
    };
  };
  readonly " $fragmentType": "MemberOnboardingSlackAutoConnectSuccessFragment";
};
export type MemberOnboardingSlackAutoConnectSuccessFragment$key = {
  readonly " $data"?: MemberOnboardingSlackAutoConnectSuccessFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"MemberOnboardingSlackAutoConnectSuccessFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MemberOnboardingSlackAutoConnectSuccessFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Content",
      "kind": "LinkedField",
      "name": "content",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SlackConnection",
              "kind": "LinkedField",
              "name": "slack",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SlackWorkspace",
                  "kind": "LinkedField",
                  "name": "workspace",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "url",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ContentUsage",
  "abstractKey": null
};

(node as any).hash = "b653dd537079b0bf974111dcd5117026";

export default node;
