import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useLabel } from "@/core/context/LabelsContext"
import FormStore from "@/core/form/store/FormStore"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { CreateInvitationFormState } from "@/invitation/create/form/CreateInvitationForm"
import CheckmarkIcon from "@assets/disco/icons/color-icons/checkmark-fill-light.svg"
import XIcon from "@assets/disco/icons/color-icons/cross-fill-light.svg"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoAlert, DiscoLink, DiscoText, DiscoTooltip } from "@disco-ui"
import DiscoScrolledIntoView from "@disco-ui/scrolled-into-view/DiscoScrolledIntoView"
import { Table, TableCell, TableRow } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { useState } from "react"
import { generatePath } from "react-router-dom"

interface Props {
  form: FormStore<CreateInvitationFormState>
}

function InvitationFormCSVPreview(props: Props) {
  const { form } = props
  const classes = useStyles()
  const activeOrganization = useActiveOrganization()
  const activeProduct = useActiveProduct()
  const [rowToRender, setRowToRender] = useState(0)
  const memberLabel = useLabel(form.state.productId ? "product_member" : "admin_member")
  const productLabel = useLabel("admin_experience")

  if (!form.state.csvMetadata?.data) return null
  const validatedData = form.state.csvMetadata.data
  if (validatedData.length === 0) return null
  const header = form.state.productId
    ? ["First Name", "Last Name", "Email", "Group"]
    : ["First Name", "Last Name", "Email"]

  const inviteLink =
    activeOrganization?.primaryDomain +
    (activeProduct
      ? generatePath(ROUTE_NAMES.PRODUCT.REGISTRATION.ROOT, {
          productSlug: activeProduct.slug,
        })
      : ROUTE_NAMES.COMMUNITY.JOIN.ROOT)

  return (
    <>
      <div className={classes.wrapper}>
        <Table>
          <TableRow classes={{ root: classes.header }}>
            {header.map((item) => (
              <TableCell key={item}>
                <DiscoText variant={"body-md-600"}>{item}</DiscoText>
              </TableCell>
            ))}
            {/** Empty cell for the checkmark column */}
            <TableCell />
          </TableRow>
          {validatedData
            .slice(0, rowToRender)
            .map(({ email, firstName, lastName, group }) => {
              const badRecord =
                email.error ||
                firstName?.error ||
                lastName?.error ||
                // Only include group error for product invites
                (group?.error && form.state.productId)
              return (
                <TableRow key={email.value}>
                  <DiscoTooltip content={firstName?.error}>
                    <TableCell classes={{ root: classes.cell }}>
                      <DiscoText
                        data-testid={`InvitationFormCSVPreview.${email.value}.firstName`}
                        color={firstName?.error ? "error.main" : undefined}
                      >
                        {firstName?.value || ""}
                      </DiscoText>
                    </TableCell>
                  </DiscoTooltip>
                  <DiscoTooltip content={lastName?.error}>
                    <TableCell classes={{ root: classes.cell }}>
                      <DiscoText
                        data-testid={`InvitationFormCSVPreview.${email.value}.lastName`}
                        color={lastName?.error ? "error.main" : undefined}
                      >
                        {lastName?.value || ""}
                      </DiscoText>
                    </TableCell>
                  </DiscoTooltip>
                  <DiscoTooltip content={email.error}>
                    <TableCell classes={{ root: classes.cell }}>
                      <DiscoText color={email.error ? "error.main" : undefined}>
                        {email.value}
                      </DiscoText>
                    </TableCell>
                  </DiscoTooltip>

                  {form.state.productId && (
                    <DiscoTooltip content={group?.error}>
                      <TableCell classes={{ root: classes.cell }}>
                        <DiscoText color={group?.error ? "error.main" : undefined}>
                          {group?.value || ""}
                        </DiscoText>
                      </TableCell>
                    </DiscoTooltip>
                  )}
                  <TableCell classes={{ root: classes.cell }}>
                    {badRecord ? (
                      <XIcon width={24} height={24} />
                    ) : (
                      <CheckmarkIcon width={24} height={24} />
                    )}
                  </TableCell>
                </TableRow>
              )
            })}
        </Table>
        <DiscoScrolledIntoView
          onScrolledIntoView={() => setRowToRender((prev) => prev + 10)}
        />
      </div>
      {form.errorsByField.invalidRows ? (
        <DiscoAlert
          message={form.errorsByField.invalidRows[0]}
          severity={"error"}
          marginBottom={2}
        />
      ) : (
        <DiscoText variant={"body-sm"} color={"text.secondary"}>
          {`Successfully imported ${validatedData.length} ${memberLabel.plural}! Those invited will receive an email 
          with a link to the `}
          <DiscoLink
            to={inviteLink}
            data-testid={`InvitationFormCopyLinkField.registration-page.link`}
            target={"_blank"}
            textVariant={"body-sm"}
          >
            {"Registration Page"}
          </DiscoLink>
          {` for this ${form.state.productId ? productLabel.singular : `Community`}.`}
        </DiscoText>
      )}
    </>
  )
}

const useStyles = makeUseStyles((theme) => ({
  wrapper: {
    height: "100%",
    maxHeight: "280px",
    overflow: "auto",
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    borderRadius: theme.measure.borderRadius.big,
    boxShadow: theme.palette.groovyDepths.insideCard,
  },
  header: {
    "& td": {
      backgroundColor: theme.palette.groovy.neutral[100],
      "&:first-child": {
        borderTopLeftRadius: theme.measure.borderRadius.big,
        borderBottomLeftRadius: theme.measure.borderRadius.big,
      },
      "&:last-child": {
        borderTopRightRadius: theme.measure.borderRadius.big,
        borderBottomRightRadius: theme.measure.borderRadius.big,
      },
    },
  },
  cell: {
    padding: theme.spacing(1.5, 1),
  },
}))

export default observer(InvitationFormCSVPreview)
