import MemberOnboardingSlackBackgroundWrapper from "@/member-onboarding/slack-flow/MemberOnboardingSlackBackgroundWrapper"
import SlackAccordionBackground from "@assets/images/member-onboarding/slack-accordion-background.png"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoText } from "@disco-ui"
import DiscoTabs from "@disco-ui/tabs/DiscoTabs"
import { TestIDProps } from "@utils/typeUtils"
import React from "react"

export type MemberOnboardingSlackTab = "desktop" | "browser" | "mobile"

type MemberOnboardingSlackTabsProps = TestIDProps & {
  tab: MemberOnboardingSlackTab
  setTab: React.Dispatch<React.SetStateAction<MemberOnboardingSlackTab>>
  content: Record<MemberOnboardingSlackTab, React.ReactNode>
  title: string
}

function MemberOnboardingSlackTabs({
  tab,
  setTab,
  content,
  title,
  testid = "MemberOnboardingSlackTabs",
}: MemberOnboardingSlackTabsProps) {
  const classes = useStyles()
  return (
    <>
      <DiscoText variant={"heading-md"} align={"center"} marginBottom={3}>
        {title}
      </DiscoText>
      <DiscoTabs
        testid={`${testid}.tabs`}
        tabVariant={"grey-track"}
        routes={[
          {
            label: "Desktop App",
            onClick: () => setTab("desktop"),
            active: tab === "desktop",
            testid: "desktop",
          },
          {
            label: "Browser",
            onClick: () => setTab("browser"),
            active: tab === "browser",
            testid: "browser",
          },
          {
            label: "Mobile",
            onClick: () => setTab("mobile"),
            active: tab === "mobile",
            testid: "mobile",
          },
        ]}
        className={classes.root}
      />

      <MemberOnboardingSlackBackgroundWrapper src={SlackAccordionBackground}>
        {content[tab]}
      </MemberOnboardingSlackBackgroundWrapper>
    </>
  )
}
const useStyles = makeUseStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2.25),
  },
}))

export default MemberOnboardingSlackTabs
