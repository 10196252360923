/**
 * @generated SignedSource<<88832f3b3b288e7b5855773ae412dc16>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MemberOnboardingCustomProfileFieldsQuery$variables = {
  userId: string;
  organizationId: string;
};
export type MemberOnboardingCustomProfileFieldsQuery$data = {
  readonly user: {
    readonly organizationMembership?: {
      readonly profileValues: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly profileField: {
              readonly id: string;
            };
            readonly " $fragmentSpreads": FragmentRefs<"MemberOnboardingCustomProfileFieldQuestionFragment_profileValue">;
          };
        }>;
      };
    } | null;
  } | null;
};
export type MemberOnboardingCustomProfileFieldsQuery = {
  variables: MemberOnboardingCustomProfileFieldsQuery$variables;
  response: MemberOnboardingCustomProfileFieldsQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "userId"
  }
],
v3 = [
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "ProfileField",
  "kind": "LinkedField",
  "name": "profileField",
  "plural": false,
  "selections": [
    (v4/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "MemberOnboardingCustomProfileFieldsQuery",
    "selections": [
      {
        "alias": "user",
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "OrganizationMembership",
                "kind": "LinkedField",
                "name": "organizationMembership",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProfileValueNodeConnection",
                    "kind": "LinkedField",
                    "name": "profileValues",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProfileValueNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProfileValue",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              (v5/*: any*/),
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "MemberOnboardingCustomProfileFieldQuestionFragment_profileValue"
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "User",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "MemberOnboardingCustomProfileFieldsQuery",
    "selections": [
      {
        "alias": "user",
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "OrganizationMembership",
                "kind": "LinkedField",
                "name": "organizationMembership",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProfileValueNodeConnection",
                    "kind": "LinkedField",
                    "name": "profileValues",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProfileValueNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProfileValue",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              (v5/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "WebFormAnswer",
                                "kind": "LinkedField",
                                "name": "webFormAnswer",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "body",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "isSkipped",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "selectedOptions",
                                    "storageKey": null
                                  },
                                  (v4/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "User",
            "abstractKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d6d960336521564d9ca5bb1846a80b92",
    "id": null,
    "metadata": {},
    "name": "MemberOnboardingCustomProfileFieldsQuery",
    "operationKind": "query",
    "text": "query MemberOnboardingCustomProfileFieldsQuery(\n  $userId: ID!\n  $organizationId: ID!\n) {\n  user: node(id: $userId) {\n    __typename\n    ... on User {\n      organizationMembership(organizationId: $organizationId) {\n        profileValues {\n          edges {\n            node {\n              id\n              profileField {\n                id\n              }\n              ...MemberOnboardingCustomProfileFieldQuestionFragment_profileValue\n            }\n          }\n        }\n        id\n      }\n    }\n    id\n  }\n}\n\nfragment MemberOnboardingCustomProfileFieldQuestionFragment_profileValue on ProfileValue {\n  webFormAnswer {\n    body\n    isSkipped\n    selectedOptions\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "5d1f9eb653cef62d20c04468525a5489";

export default node;
