import MemberOnboardingButtons from "@/member-onboarding/buttons/MemberOnboardingButtons"
import useMemberOnboardingFormStore from "@/member-onboarding/hooks/useMemberOnboardingFormStore"
import { MemberOnboardingConstants } from "@/member-onboarding/utils/memberOnboardingConstants"
import { MemberOnboardingCommunityAgreementFragment$key } from "@/member-onboarding/__generated__/MemberOnboardingCommunityAgreementFragment.graphql"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import DiscoEditor from "@components/editor/DiscoEditor"
import ScrollShadowContainer from "@components/scroll-shadow/ScrollShadowContainer"
import { TestIDProps } from "@utils/typeUtils"
import { graphql, useFragment } from "react-relay"

interface MemberOnboardingCommunityAgreementProps extends TestIDProps {
  usageKey: MemberOnboardingCommunityAgreementFragment$key
}

function MemberOnboardingCommunityAgreement({
  usageKey,
}: MemberOnboardingCommunityAgreementProps) {
  const { content, ...usage } =
    useFragment<MemberOnboardingCommunityAgreementFragment$key>(
      graphql`
        fragment MemberOnboardingCommunityAgreementFragment on ContentUsage {
          id
          module {
            id
          }
          content {
            id
            systemTaskKind
            richEditorDescriptionContent
          }
          ...useMemberOnboardingFormStoreFragment
        }
      `,
      usageKey
    )

  const { submitItemCompletion } = useMemberOnboardingFormStore({
    usageKey: usage,
  })

  const classes = useStyles()

  if (!usage || !content || content?.systemTaskKind !== "community_agreement") return null

  return (
    <>
      <ScrollShadowContainer
        classes={{
          scrollContainer: classes.scrollContainer,
          parentContainer: classes.parentContainer,
        }}
      >
        <DiscoEditor
          readOnly
          defaultValue={content.richEditorDescriptionContent}
          editorUsageData={{ contentId: content.id, contentUsageId: usage.id }}
          disableGutter
          backgroundColor={"transparent"}
          height={"fit-content"}
        />
      </ScrollShadowContainer>
      <MemberOnboardingButtons
        nextTitle={"Agree and continue"}
        onSubmit={submitItemCompletion}
      />
    </>
  )
}

const useStyles = makeUseStyles((theme) => ({
  scrollContainer: {
    maxWidth: MemberOnboardingConstants.CONTENT_MAX_WIDTH,
    backgroundColor: theme.palette.groovy.neutral[100],
    padding: theme.spacing(2),
  },
  parentContainer: {
    height: "unset",
    borderRadius: theme.measure.borderRadius.big,
  },
}))

export default MemberOnboardingCommunityAgreement
