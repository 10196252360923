import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useMemberShouldOnboardQuery } from "@/member-onboarding/hooks/__generated__/useMemberShouldOnboardQuery.graphql"
import { graphql, useLazyLoadQuery } from "react-relay"

export default function useMemberShouldOnboard() {
  const activeOrganization = useActiveOrganization()
  const viewerMembershipId = activeOrganization?.viewerMembership?.id

  const { membership } = useLazyLoadQuery<useMemberShouldOnboardQuery>(
    graphql`
      query useMemberShouldOnboardQuery($membershipId: ID!) {
        membership: node(id: $membershipId) {
          ... on OrganizationMembership {
            id
            hasSeenOnboarding
            viewerShouldCompleteMemberOnboarding
          }
        }
      }
    `,
    {
      membershipId: viewerMembershipId || "",
    }
  )

  return membership?.viewerShouldCompleteMemberOnboarding || false
}
