import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { DiscoLink, DiscoText } from "@disco-ui"
import DiscoImage from "@disco-ui/image/DiscoImage"
import React from "react"

interface OrganizationLogoWithFallbackNameProps {
  style?: React.CSSProperties
  disableLink?: boolean
}

const OrganizationLogoWithFallbackName: React.FC<
  OrganizationLogoWithFallbackNameProps
> = (props) => {
  const { style, disableLink = false } = props
  const activeOrganization = useActiveOrganization()
  const classes = useStyles()

  if (!activeOrganization) return null

  return (
    <DiscoLink
      data-testid={"OrganizationLogoWithFallbackName.link"}
      className={classes.link}
      to={
        disableLink
          ? undefined
          : activeOrganization?.viewerMembership
          ? ROUTE_NAMES.COMMUNITY.HOME.ROOT
          : ROUTE_NAMES.COMMUNITY.LANDING_PAGE.SITE
      }
    >
      {renderContent()}
    </DiscoLink>
  )

  function renderContent() {
    if (!activeOrganization) return null
    if (activeOrganization.logo) {
      return (
        <DiscoImage
          className={classes.logo}
          src={activeOrganization.logo}
          alt={`${activeOrganization.name} logo`}
          data-testid={"OrganizationLogoWithFallbackName.logo"}
        />
      )
    }

    return (
      <DiscoText
        style={style}
        data-testid={"OrganizationLogoWithFallbackName.text"}
        color={"text.primary"}
        truncateText={1}
      >
        {activeOrganization.name}
      </DiscoText>
    )
  }
}

const useStyles = makeUseStyles({
  link: {
    minWidth: 0,
  },
  logo: {
    display: "block",
    maxHeight: "32px",
    width: "auto",
    maxWidth: "210px",
  },
})

export default OrganizationLogoWithFallbackName
