/**
 * @generated SignedSource<<22f4b2f662bba7829a2d60fbd6f74bca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type WebFormQuestionType = "single_select" | "multiple_select" | "written_answer" | "link" | "rating" | "ranking" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type MemberOnboardingCustomProfileFieldQuestionFragment$data = {
  readonly id: string;
  readonly content: {
    readonly id: string;
    readonly profileField: {
      readonly id: string;
      readonly isPrivate: boolean;
      readonly webFormQuestion: {
        readonly id: string;
        readonly type: WebFormQuestionType;
        readonly richEditorBody: string;
        readonly options: ReadonlyArray<{
          readonly id: string;
          readonly label: string;
        }> | null;
      } | null;
    } | null;
  };
  readonly " $fragmentSpreads": FragmentRefs<"useMemberOnboardingFormStoreFragment">;
  readonly " $fragmentType": "MemberOnboardingCustomProfileFieldQuestionFragment";
};
export type MemberOnboardingCustomProfileFieldQuestionFragment$key = {
  readonly " $data"?: MemberOnboardingCustomProfileFieldQuestionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"MemberOnboardingCustomProfileFieldQuestionFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MemberOnboardingCustomProfileFieldQuestionFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Content",
      "kind": "LinkedField",
      "name": "content",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ProfileField",
          "kind": "LinkedField",
          "name": "profileField",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isPrivate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "WebFormQuestion",
              "kind": "LinkedField",
              "name": "webFormQuestion",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "type",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "richEditorBody",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "WebFormQuestionOption",
                  "kind": "LinkedField",
                  "name": "options",
                  "plural": true,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "label",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useMemberOnboardingFormStoreFragment"
    }
  ],
  "type": "ContentUsage",
  "abstractKey": null
};
})();

(node as any).hash = "dc9c25fb0b6d0c7cd4282a72d17a14be";

export default node;
