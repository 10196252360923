import { useStepperContext } from "@/core/context/StepperContext"
import { MemberOnboardingModalProps } from "@/member-onboarding/MemberOnboardingModal"
import { MemberOnboardingDefaultProfileFieldsFragment$key } from "@/member-onboarding/__generated__/MemberOnboardingDefaultProfileFieldsFragment.graphql"
import MemberOnboardingPreviousButton from "@/member-onboarding/buttons/MemberOnboardingPreviousButton"
import useMemberOnboardingFormStore from "@/member-onboarding/hooks/useMemberOnboardingFormStore"
import CompleteProfileCurriculumItemForm from "@/product/course/curriculum/section/drawer/items/profile/CompleteProfileCurriculumItemForm"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { TestIDProps } from "@utils/typeUtils"
import { graphql, useFragment } from "react-relay"
interface MemberOnboardingDefaultProfileFieldsProps extends TestIDProps {
  usageKey: MemberOnboardingDefaultProfileFieldsFragment$key
  mode?: MemberOnboardingModalProps["mode"]
}

function MemberOnboardingDefaultProfileFields({
  usageKey,
  mode,
}: MemberOnboardingDefaultProfileFieldsProps) {
  const { next } = useStepperContext() || {}
  const { content, ...usage } =
    useFragment<MemberOnboardingDefaultProfileFieldsFragment$key>(
      graphql`
        fragment MemberOnboardingDefaultProfileFieldsFragment on ContentUsage {
          content {
            type
            systemTaskKind
          }
          ...useMemberOnboardingFormStoreFragment
        }
      `,
      usageKey
    )

  const { submitItemCompletion } = useMemberOnboardingFormStore({
    usageKey: usage,
  })

  const classes = useStyles()

  if (!content || content.systemTaskKind !== "default_profile_fields") return null

  return (
    <CompleteProfileCurriculumItemForm
      buttons={<MemberOnboardingPreviousButton />}
      showCustomFields={false}
      onAfterSubmit={onAfterSubmit}
      submitButtonTitle={"Continue"}
      requireChangeToSubmit={false}
      classes={{ form: classes.form, buttonsContainer: classes.buttonsContainer }}
      skipSubmit={mode === "admin-preview"}
    />
  )
  async function onAfterSubmit() {
    const { didSave } = await submitItemCompletion()
    if (!didSave) return
    next?.()
  }
}

const useStyles = makeUseStyles({
  form: {
    width: "100%",
  },
  buttonsContainer: {
    justifyContent: "center",
  },
})

export default MemberOnboardingDefaultProfileFields
