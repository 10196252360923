import MemberOnboardingButtons from "@/member-onboarding/buttons/MemberOnboardingButtons"
import useMemberOnboardingFormStore from "@/member-onboarding/hooks/useMemberOnboardingFormStore"
import MemberOnboardingDownloadMobileAppContent from "@/member-onboarding/MemberOnboardingDownloadMobileAppContent"
import { MemberOnboardingDownloadMobileAppFragment$key } from "@/member-onboarding/__generated__/MemberOnboardingDownloadMobileAppFragment.graphql"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { TestIDProps } from "@utils/typeUtils"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface MemberOnboardingDownloadMobileAppProps extends TestIDProps {
  usageKey: MemberOnboardingDownloadMobileAppFragment$key
}

function MemberOnboardingDownloadMobileApp({
  usageKey,
}: MemberOnboardingDownloadMobileAppProps) {
  const classes = useStyles()

  const usage = useFragment<MemberOnboardingDownloadMobileAppFragment$key>(
    graphql`
      fragment MemberOnboardingDownloadMobileAppFragment on ContentUsage {
        id
        ...useMemberOnboardingFormStoreFragment
        ...MemberOnboardingDownloadMobileAppContentFragment
      }
    `,
    usageKey
  )

  const { submitItemCompletion } = useMemberOnboardingFormStore({
    usageKey: usage,
  })

  return (
    <div className={classes.container}>
      <MemberOnboardingDownloadMobileAppContent usageKey={usage} />

      <MemberOnboardingButtons nextTitle={"Continue"} onSubmit={submitItemCompletion} />
    </div>
  )
}

const useStyles = makeUseStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
})

export default MemberOnboardingDownloadMobileApp
