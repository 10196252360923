import useTrackContentCompletion from "@/content-usage/buttons/useTrackContentCompletion"
import { useLabel } from "@/core/context/LabelsContext"
import { useStepperContext } from "@/core/context/StepperContext"
import MemberOnboardingButtons, {
  MemberOnboardingButtonsProps,
} from "@/member-onboarding/buttons/MemberOnboardingButtons"
import useViewersCalendarConnection from "@/member-onboarding/calendar-connection/hooks/useViewersCalendarConnection"
import { MemberOnboardingCalendarContentFragment$key } from "@/member-onboarding/calendar-connection/__generated__/MemberOnboardingCalendarContentFragment.graphql"
import useMemberOnboardingFormStore from "@/member-onboarding/hooks/useMemberOnboardingFormStore"
import MemberOnboardingContentBodyWrapper from "@/member-onboarding/MemberOnboardingContentBodyWrapper"
import MemberOnboardingContentHeader from "@/member-onboarding/MemberOnboardingContentHeader"
import { MemberOnboardingModalProps } from "@/member-onboarding/MemberOnboardingModal"
import { openOutlookConnectionPage } from "@/user/settings/subtabs/connections/ConnectOutlookConnectionButton"
import CheckmarkIcon from "@assets/disco/icons/color-icons/checkmark-fill-light.svg"
import DiscoIntegrationIcon from "@assets/images/integration-icons/disco-integration-icon.svg"
import OutlookIntegrationIcon from "@assets/images/integration-icons/outlook-integration-icon.svg"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoIcon, DiscoText } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
import { useEffect } from "react"
import { graphql, useFragment } from "react-relay"

interface MemberOnboardingCalendarContentProps
  extends TestIDProps,
    Pick<MemberOnboardingButtonsProps, "onSuccess"> {
  usageKey: MemberOnboardingCalendarContentFragment$key
  mode: MemberOnboardingModalProps["mode"]
}

function MemberOnboardingCalendarContent({
  usageKey,
  mode,
}: MemberOnboardingCalendarContentProps) {
  const { next } = useStepperContext()!

  const classes = useStyles()

  const { viewer } = useViewersCalendarConnection()

  const memberLabel = useLabel("admin_member")

  const { content, ...usage } = useFragment<MemberOnboardingCalendarContentFragment$key>(
    graphql`
      fragment MemberOnboardingCalendarContentFragment on ContentUsage {
        viewerHasCompleted
        content {
          systemTaskKind
        }
        ...MemberOnboardingContentHeaderFragment
        ...useMemberOnboardingFormStoreFragment
        ...useTrackContentCompletionFragment
      }
    `,
    usageKey
  )

  const { submitItemCompletion } = useMemberOnboardingFormStore({
    usageKey: usage,
  })

  const completeContent = useTrackContentCompletion(usage)

  const completeItem = mode === "curriculum-item" ? completeContent : submitItemCompletion

  useEffect(() => {
    if (!viewer?.calendarConnection?.id) return

    // If the viewer has a calendar connection, mark the item as complete
    completeItem()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewer?.calendarConnection?.id])

  if (!content || content.systemTaskKind !== "calendar_connection") return null

  return (
    <MemberOnboardingContentBodyWrapper>
      <MemberOnboardingContentHeader
        usageKey={usage}
        icon={
          <>
            <DiscoIntegrationIcon width={58} height={58} />
            <DiscoIcon icon={"switch-horizontal"} />
            <div className={classes.outlookIcon}>
              <OutlookIntegrationIcon width={48} height={48} />
            </div>
          </>
        }
      />
      {viewer?.calendarConnection && (
        <div className={classes.status}>
          <CheckmarkIcon width={24} height={24} />
          <DiscoText variant={"body-sm"} color={"text.secondary"}>
            {"Successfully connected your Outlook calendar."}
          </DiscoText>
        </div>
      )}
      <MemberOnboardingButtons
        {...(usage.viewerHasCompleted && viewer?.calendarConnection
          ? {
              nextTitle: "Continue",
              onSubmit: completeItem,
              onCustomAction: next,
              customActionTitle: "Next Step",
            }
          : {
              nextTitle: "Get Started",
              onNext: openOutlookConnectionPage,
              onCustomAction: async () => {
                // Mark as complete when skipping, for users who don't have Outlook
                await completeItem()
                next()
              },
              customActionTitle: "Skip for now",
              // don't allow admins previewing onboarding to proceed to next Outlook step
              disableNext: mode === "admin-preview",
              disableNextTooltip: `${memberLabel.plural} will be able to connect Outlook in the next step. Outlook onboarding is not available in preview.`,
            })}
      />
    </MemberOnboardingContentBodyWrapper>
  )
}

const useStyles = makeUseStyles((theme) => ({
  outlookIcon: {
    padding: theme.spacing(0.5),
    display: "flex",
    alignItems: "center",
    border: `1px solid ${theme.palette.groovy.neutral[200]}`,
    borderRadius: theme.measure.borderRadius.large,
  },
  status: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}))

export default MemberOnboardingCalendarContent
