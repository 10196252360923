import { CommunityAppBarProps } from "@/community/CommunityAppBar"
import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import GlobalNavButton from "@/main/page/global-nav/GlobalNavButton"
import { DiscoIcon } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"

interface BookmarksNavButtonProps
  extends TestIDProps,
    Pick<CommunityAppBarProps, "variant"> {}

function BookmarksNavButton({
  testid = "BookmarksNavButton",
  variant,
}: BookmarksNavButtonProps) {
  const drawer = useGlobalDrawer("bookmarks")

  return (
    <GlobalNavButton
      testid={testid}
      onClick={handleOpenBookmarks}
      tooltip={"Bookmarks"}
      variant={variant}
    >
      <DiscoIcon icon={"bookmark"} />
    </GlobalNavButton>
  )

  function handleOpenBookmarks() {
    drawer.open({
      bookmarksDrawer: "1",
    })
  }
}

export default BookmarksNavButton
