/**
 * @generated SignedSource<<d3793e66a2cfb01b11f4083bbe4f9987>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeleteMeetingProviderButtonQuery$variables = {
  id: string;
};
export type DeleteMeetingProviderButtonQuery$data = {
  readonly meetingProvider: {
    readonly " $fragmentSpreads": FragmentRefs<"DeleteMeetingProviderFormFragment">;
  } | null;
};
export type DeleteMeetingProviderButtonQuery = {
  variables: DeleteMeetingProviderButtonQuery$variables;
  response: DeleteMeetingProviderButtonQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteMeetingProviderButtonQuery",
    "selections": [
      {
        "alias": "meetingProvider",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "DeleteMeetingProviderFormFragment"
              }
            ],
            "type": "MeetingProvider",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteMeetingProviderButtonQuery",
    "selections": [
      {
        "alias": "meetingProvider",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "upcomingOnly",
                    "value": true
                  }
                ],
                "concreteType": "OccurrenceNodeConnection",
                "kind": "LinkedField",
                "name": "occurrences",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OccurrenceNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Occurrence",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "datetimeRange",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Product",
                            "kind": "LinkedField",
                            "name": "product",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "slug",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "type",
                                "storageKey": null
                              },
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "occurrences(upcomingOnly:true)"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ZoomConnection",
                "kind": "LinkedField",
                "name": "zoomConnection",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "MeetingProvider",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7759e5e9083333b695760834b0e98bac",
    "id": null,
    "metadata": {},
    "name": "DeleteMeetingProviderButtonQuery",
    "operationKind": "query",
    "text": "query DeleteMeetingProviderButtonQuery(\n  $id: ID!\n) {\n  meetingProvider: node(id: $id) {\n    __typename\n    ... on MeetingProvider {\n      ...DeleteMeetingProviderFormFragment\n    }\n    id\n  }\n}\n\nfragment DeleteMeetingProviderFormFragment on MeetingProvider {\n  id\n  occurrences(upcomingOnly: true) {\n    edges {\n      node {\n        id\n        name\n        datetimeRange\n        product {\n          slug\n          type\n          id\n        }\n      }\n    }\n  }\n  zoomConnection {\n    email\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "53a23ed7b66aaf83107b1451b9944f57";

export default node;
