/**
 * @generated SignedSource<<a4612808feb69d4cace29c7be0b5b89f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ProductType = "course" | "membership_plan" | "community_event" | "pathway" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type MembershipPlanExperienceDetailsFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly startsAt: string | null;
  readonly endsAt: string | null;
  readonly type: ProductType;
  readonly slug: string;
  readonly cover: string;
  readonly isAutoJoined: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"ProductTypeTagFragment">;
  readonly " $fragmentType": "MembershipPlanExperienceDetailsFragment";
};
export type MembershipPlanExperienceDetailsFragment$key = {
  readonly " $data"?: MembershipPlanExperienceDetailsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"MembershipPlanExperienceDetailsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MembershipPlanExperienceDetailsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startsAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endsAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cover",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isAutoJoined",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProductTypeTagFragment"
    }
  ],
  "type": "Product",
  "abstractKey": null
};

(node as any).hash = "f17d91e98eadadb3c84e3f2109b53689";

export default node;
