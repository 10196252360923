import ROUTE_NAMES from "@/core/route/util/routeNames"
import { generateRedirectStateFromLocation } from "@/core/route/util/routeUtils"
import { DiscoLink, DiscoLinkProps } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
import { useLocation } from "react-router-dom"

function LoginButton({
  testid = "LoginButton.link",
  ...rest
}: Omit<DiscoLinkProps, "to"> & TestIDProps) {
  const location = useLocation()

  return (
    <DiscoLink
      data-testid={testid}
      to={{
        pathname: ROUTE_NAMES.AUTHENTICATION.LOGIN,
        state: generateRedirectStateFromLocation(location),
        search: location.search,
      }}
      {...rest}
    >
      {"Login"}
    </DiscoLink>
  )
}

export default LoginButton
