import { MemberOnboardingConstants } from "@/member-onboarding/utils/memberOnboardingConstants"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import ScrollShadowContainer from "@components/scroll-shadow/ScrollShadowContainer"
import { ClassNameMap } from "@material-ui/core/styles/withStyles"
import classNames from "classnames"

type Props = {
  children: React.ReactNode
  classes?: Partial<ClassNameMap<"scrollContainer" | "parentContainer" | "content">>
}
function MemberOnboardingContentBodyWrapper({ children, classes: customClasses }: Props) {
  const classes = useStyles()
  return (
    <ScrollShadowContainer
      classes={{
        scrollContainer: classNames(
          classes.scrollContainer,
          customClasses?.scrollContainer
        ),
        parentContainer: classNames(
          classes.parentContainer,
          customClasses?.parentContainer
        ),
      }}
    >
      <div className={classNames(classes.content, customClasses?.content)}>
        {children}
      </div>
    </ScrollShadowContainer>
  )
}

const useStyles = makeUseStyles((theme) => ({
  parentContainer: {
    width: "100%",
  },
  scrollContainer: {
    width: "100%",
  },
  content: {
    maxWidth: MemberOnboardingConstants.CONTENT_MAX_WIDTH,
    margin: theme.spacing(0, "auto"),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // needed to prevent hiding box shadow on some elements
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
  },
}))

export default MemberOnboardingContentBodyWrapper
