import { AssetFileType } from "@/admin/media-library/__generated__/AdminMediaLibraryListPagePaginationQuery.graphql"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import MediaLibraryFileFields from "@/media/add/MediaLibraryFileFields"
import GenerateImageView from "@/media/generate/GenerateImageView"
import UnsplashSearch from "@/media/unsplash/UnsplashSearch"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import AIMagicWand from "@components/ai/AIMagicWand"
import {
  FileDropzoneSuggestedDimensions,
  MimeTypes,
} from "@components/dropzone/FileDropzone"
import { ImageCropModalCropperProps } from "@components/image/crop-modal/util/imageCropModalTypes"
import { MediaResult } from "@components/media/upload/hooks/useMultipartUploadMediaToS3"
import { DiscoDivider, DiscoIcon, DiscoModalProps } from "@disco-ui"
import DiscoTabs from "@disco-ui/tabs/DiscoTabs"
import { Popover, PopoverOrigin } from "@material-ui/core"
import { ArrayUtils } from "@utils/array/arrayUtils"
import usePermissions from "@utils/hook/usePermissions"
import { useState } from "react"
import { DropzoneOptions } from "react-dropzone"

const ID = "media-library-modal"
export type MEDIA_LIBRARY_TABS = "my-files" | "unsplash" | "ai-image"

type Props = Pick<DiscoModalProps, "onClose"> & {
  testid?: string
  onClose: VoidFunction
  onUpload: (result: MediaResult, file: File) => void
  onMediaSelect: (result: MediaResult) => void
  cropperProps?: ImageCropModalCropperProps
  dropzoneOptions: Omit<DropzoneOptions, "onDrop">
  suggestedDimensions?: FileDropzoneSuggestedDimensions
  enforceSuggestedDimensions?: boolean
  message?: React.ReactElement | string
  allowedFileTypes?: AssetFileType[]
  defaultTab?: MEDIA_LIBRARY_TABS
  PopoverProps: Partial<{
    anchorEl: HTMLElement | null
    anchorOrigin: PopoverOrigin
    transformOrigin: PopoverOrigin
  }>
  includeOrganizationIdOnAsset?: boolean
  includeInMediaLibrary?: boolean
  allowedMimeTypes?: MimeTypes[]
}

export function getMediaLibraryPopoverElement() {
  return document.querySelector(`#${ID}`) as HTMLElement
}

function UploadMediaModal({
  testid = "UploadMediaModal",
  onClose,
  onUpload,
  onMediaSelect,
  cropperProps,
  dropzoneOptions,
  suggestedDimensions,
  enforceSuggestedDimensions,
  message,
  allowedFileTypes,
  PopoverProps,
  defaultTab = "my-files",
  includeOrganizationIdOnAsset,
  includeInMediaLibrary,
  allowedMimeTypes,
}: Props) {
  const [mediaTab, setMediaTab] = useState<MEDIA_LIBRARY_TABS>(defaultTab)
  const activeOrganization = useActiveOrganization()
  const permissions = usePermissions(activeOrganization)
  const canViewAssets = permissions.has("assets.read")
  const classes = useStyles({ canViewAssets })
  return (
    <Popover
      id={ID}
      anchorEl={PopoverProps.anchorEl}
      open={Boolean(PopoverProps.anchorEl)}
      onClick={(e) => e.stopPropagation()}
      onClose={handleOnClose}
      anchorOrigin={{
        vertical: PopoverProps.anchorOrigin?.vertical || "top",
        horizontal: PopoverProps.anchorOrigin?.horizontal || "left",
      }}
      transformOrigin={{
        vertical: PopoverProps.transformOrigin?.vertical || "top",
        horizontal: PopoverProps.transformOrigin?.horizontal || "center",
      }}
      PaperProps={{
        classes: {
          root: classes.popoverPaper,
        },
        id: "media-library-popover",
      }}
    >
      <div className={classes.tabContainer}>
        {canViewAssets && (
          <DiscoTabs
            routes={[
              {
                label: "My Files",
                onClick: () => {
                  setMediaTab("my-files")
                },
                testid: "my-files-tab",
                active: mediaTab === "my-files",
              },
              ...ArrayUtils.spreadIf(
                [
                  {
                    label: "Unsplash",
                    onClick: () => {
                      setMediaTab("unsplash")
                    },
                    testid: "unsplah-tab",
                    active: mediaTab === "unsplash",
                  },
                  {
                    label: "Generate",
                    onClick: () => {
                      setMediaTab("ai-image")
                    },
                    testid: "ai-image-tab",
                    active: mediaTab === "ai-image",
                    tabClassname:
                      mediaTab === "ai-image" ? classes.activeGenerateTab : undefined,
                    leftIcon:
                      mediaTab === "ai-image" ? (
                        <AIMagicWand />
                      ) : (
                        <DiscoIcon icon={"magic-wand"} />
                      ),
                  },
                ],
                allowedFileTypes?.includes("image")
              ),
            ]}
            testid={`${testid}.tabs`}
            tabVariant={"default"}
          />
        )}
        <DiscoDivider className={classes.divider} thickness={2} />
      </div>
      <div className={classes.bodyContainer}>
        {mediaTab === "my-files" ? (
          <MediaLibraryFileFields
            dropzoneOptions={dropzoneOptions}
            onUpload={onUpload}
            onMediaSelect={onMediaSelect}
            cropperProps={cropperProps}
            showSupportedFiles={true}
            suggestedDimensions={suggestedDimensions}
            enforceSuggestedDimensions={enforceSuggestedDimensions}
            message={message}
            allowedFileTypes={allowedFileTypes}
            canViewAssets={canViewAssets}
            onClose={onClose}
            includeOrganizationIdOnAsset={includeOrganizationIdOnAsset}
            includeInMediaLibrary={includeInMediaLibrary}
            allowedMimeTypes={allowedMimeTypes}
          />
        ) : mediaTab === "unsplash" ? (
          <UnsplashSearch
            onMediaSelect={onMediaSelect}
            onClose={onClose}
            cropperProps={cropperProps}
          />
        ) : (
          <GenerateImageView
            onMediaSelect={onMediaSelect}
            onClose={onClose}
            cropperProps={cropperProps}
          />
        )}
      </div>
    </Popover>
  )

  function handleOnClose(e: Event) {
    e.stopPropagation()
    onClose()
  }
}

interface StyleProps {
  canViewAssets: boolean
}

const useStyles = makeUseStyles((theme) => ({
  tabContainer: {
    position: "sticky",
    top: 0,
    zIndex: theme.zIndex.raise1,
    backgroundColor: theme.palette.background.paper,
  },
  activeGenerateTab: {
    whiteSpace: "nowrap",
    "&:after": {
      position: "absolute",
      bottom: "0px",
      left: 0,
      width: "100%",
      height: "4px",
      borderRadius: `${theme.measure.borderRadius.default}px ${theme.measure.borderRadius.default}px 0 0`,
      backgroundColor: "transparent",
      backgroundImage: theme.palette.aiGradient.aiDark,
    },
    "& span": {
      color: "unset",
      backgroundImage: theme.palette.aiGradient.aiDark,
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      MozBackgroundClip: "text",
      MozTextFillColor: "transparent",
    },
  },

  divider: {
    width: "608px",
    position: "relative",
    left: theme.spacing(-3),
    backgroundColor: theme.palette.background.default,
    marginTop: "0",
  },
  bodyContainer: {
    marginTop: theme.spacing(2.5),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
    },
  },

  popoverPaper: ({ canViewAssets }: StyleProps) => ({
    borderRadius: theme.measure.borderRadius.medium,
    padding: theme.spacing(0, 3, 3),
    boxShadow: theme.palette.groovyDepths.boxShadow,
    margin: 0,
    width: "600px",
    height: canViewAssets ? "700px" : "fit-content",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    scrollbarWidth: "none",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 2, 2),
      width: "348px",
    },
  }),
}))

export default UploadMediaModal
