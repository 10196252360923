/**
 * @generated SignedSource<<f645857cb002523457bc75c71bd9a3cf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MemberOnboardingDownloadMobileAppFragment$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"useMemberOnboardingFormStoreFragment" | "MemberOnboardingDownloadMobileAppContentFragment">;
  readonly " $fragmentType": "MemberOnboardingDownloadMobileAppFragment";
};
export type MemberOnboardingDownloadMobileAppFragment$key = {
  readonly " $data"?: MemberOnboardingDownloadMobileAppFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"MemberOnboardingDownloadMobileAppFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MemberOnboardingDownloadMobileAppFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useMemberOnboardingFormStoreFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MemberOnboardingDownloadMobileAppContentFragment"
    }
  ],
  "type": "ContentUsage",
  "abstractKey": null
};

(node as any).hash = "cc124f1652e52b378eff80a4ed9fcd0d";

export default node;
