import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import OrganizationLogoWithFallbackName from "@/main/page/header/OrganizationLogoWithFallbackName"
import AdminDashboardDropdown from "@/main/page/header/buttons/AdminDashboardDropdown"
import ProductBadge from "@/main/page/side-bar/temporary-sidebar/ProductBadge"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoText } from "@disco-ui"
import DiscoContainerButton from "@disco-ui/button/DiscoContainerButton"
import { generatePath, useHistory } from "react-router-dom"

/** Show the community logo or product badge & name, depending on activeProduct context */
function HeaderMobileLhs() {
  const activeProduct = useActiveProduct()
  const history = useHistory()
  const activeOrganization = useActiveOrganization()

  const classes = useStyles()
  return activeProduct ? (
    <DiscoContainerButton
      className={classes.productButton}
      onClick={() =>
        history.push(
          generatePath(ROUTE_NAMES.PRODUCT.DASHBOARD, {
            productSlug: activeProduct?.slug,
          })
        )
      }
    >
      <ProductBadge productKey={activeProduct} size={40} />
      <DiscoText
        truncateText={1}
        component={"span"}
        noWrap
        variant={"body-md-600"}
        marginLeft={1}
      >
        {activeProduct?.name}
      </DiscoText>
    </DiscoContainerButton>
  ) : (
    <div className={classes.lhs}>
      <OrganizationLogoWithFallbackName />
      {activeOrganization?.viewerIsOwnerOrAdmin && <AdminDashboardDropdown />}
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  productButton: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    alignItems: "center",
  },
  lhs: {
    display: "flex",
    gap: theme.spacing(1),
    minWidth: 0,
  },
}))

export default HeaderMobileLhs
