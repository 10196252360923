import InlineContentDescriptionSection from "@/content/inline/InlineContentDescriptionSection"
import InlineContentHeroSection from "@/content/inline/hero-section/InlineContentHeroSection"
import { MemberOnboardingGenericTaskFragment$key } from "@/member-onboarding/__generated__/MemberOnboardingGenericTaskFragment.graphql"
import MemberOnboardingButtons from "@/member-onboarding/buttons/MemberOnboardingButtons"
import useMemberOnboardingFormStore from "@/member-onboarding/hooks/useMemberOnboardingFormStore"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { TestIDProps } from "@utils/typeUtils"
import { graphql, useFragment } from "react-relay"

interface MemberOnboardingGenericTaskProps extends TestIDProps {
  usageKey: MemberOnboardingGenericTaskFragment$key
}

function MemberOnboardingGenericTask({ usageKey }: MemberOnboardingGenericTaskProps) {
  const { content, ...usage } = useFragment<MemberOnboardingGenericTaskFragment$key>(
    graphql`
      fragment MemberOnboardingGenericTaskFragment on ContentUsage {
        id
        content {
          type
          systemTaskKind
          ...InlineContentHeroSectionFragment
          ...InlineContentDescriptionSectionFragment
        }
        ...useMemberOnboardingFormStoreFragment
      }
    `,
    usageKey
  )

  const { submitItemCompletion } = useMemberOnboardingFormStore({
    usageKey: usage,
  })

  const classes = useStyles()

  if (!content || content.type !== "custom") return null

  return (
    <>
      <InlineContentHeroSection contentKey={content} contentUsageId={usage.id} />
      <InlineContentDescriptionSection
        contentKey={content}
        contentUsageId={usage.id}
        staticSectionClasses={{ root: classes.root }}
      />
      <MemberOnboardingButtons onSubmit={submitItemCompletion} />
    </>
  )
}

const useStyles = makeUseStyles({
  root: {
    height: "fit-content",
  },
})

export default MemberOnboardingGenericTask
