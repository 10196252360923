import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useLabel } from "@/core/context/LabelsContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { useStartViewAsMember } from "@/core/route/util/routeUtils"
import CreateInvitationButton from "@/invitation/create/button/CreateInvitationButton"
import {
  DiscoDivider,
  DiscoDropdown,
  DiscoIcon,
  DiscoIconButton,
  DiscoTooltip,
} from "@disco-ui"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import { useTheme } from "@material-ui/core"
import usePermissions from "@utils/hook/usePermissions"
import useSubscriptionStanding from "@utils/hook/useSubscriptionStanding"
import { TestIDProps } from "@utils/typeUtils"
import { ReactNode } from "react"

function AdminDashboardDropdown({ testid = "AdminDashboardDropdown" }: TestIDProps) {
  const activeOrganization = useActiveOrganization()
  const activeProduct = useActiveProduct()
  const isOrgAdmin = activeOrganization?.viewerIsOwnerOrAdmin
  const permissions = usePermissions(activeOrganization)
  const theme = useTheme()
  const membersLabel = useLabel("admin_member")
  const experienceLabel = useLabel("admin_experience")
  const subscriptionStanding = useSubscriptionStanding()
  const hasPlatformAccess = subscriptionStanding === "active"
  const startViewAsMember = useStartViewAsMember()

  const firstSectionItems = getFirstSectionItems()
  const secondSectionItems = getSecondSectionItems()
  const thirdSectionItems = getThirdSectionItems()
  if (
    !firstSectionItems.length &&
    !secondSectionItems.length &&
    !thirdSectionItems.length
  )
    return null

  return (
    <DiscoDropdown
      testid={testid}
      menuButton={({ onClick }) => (
        <DiscoTooltip content={isOrgAdmin ? "Dashboard" : ""} placement={"left"}>
          <DiscoIconButton
            onClick={onClick}
            testid={`${testid}.button`}
            size={"small"}
            width={"32px"}
            height={"32px"}
            color={theme.palette.groovy.neutral[500]}
          >
            <DiscoIcon icon={"arrow-down"} />
          </DiscoIconButton>
        </DiscoTooltip>
      )}
    >
      {firstSectionItems}
      {secondSectionItems}
      {thirdSectionItems}
    </DiscoDropdown>
  )

  function getFirstSectionItems(): ReactNode[] {
    const items = []
    if (isOrgAdmin)
      items.push(
        <DiscoDropdownItem
          key={"dashboard-link"}
          testid={`${testid}.dashboard-link`}
          icon={<DiscoIcon icon={"critical"} />}
          title={"Admin Dashboard"}
          to={ROUTE_NAMES.ADMIN.DASHBOARD}
        />
      )
    if (permissions.has("members.manage"))
      items.push(
        <DiscoDropdownItem
          key={"members-link"}
          testid={`${testid}.members-link`}
          icon={<DiscoIcon icon={"user"} />}
          title={membersLabel.plural}
          to={ROUTE_NAMES.ADMIN.MEMBERS.ROOT}
        />
      )
    if (permissions.has("content.manage"))
      items.push(
        <DiscoDropdownItem
          key={"content-link"}
          testid={`${testid}.content-link`}
          icon={<DiscoIcon icon={"book-open"} />}
          title={"Content"}
          to={ROUTE_NAMES.ADMIN.CONTENT.ROOT}
        />
      )
    if (permissions.has("events.manage"))
      items.push(
        <DiscoDropdownItem
          key={"events-link"}
          testid={`${testid}.events-link`}
          icon={<DiscoIcon icon={"calendar"} />}
          title={"Events"}
          to={ROUTE_NAMES.ADMIN.EVENTS.ROOT}
        />
      )
    if (permissions.has("products.manage"))
      items.push(
        <DiscoDropdownItem
          key={"products-link"}
          testid={`${testid}.products-link`}
          icon={<DiscoIcon icon={"3d-box"} />}
          title={experienceLabel.plural}
          to={ROUTE_NAMES.ADMIN.PRODUCTS.ROOT}
        />
      )
    if (permissions.has("automations.manage"))
      items.push(
        <DiscoDropdownItem
          key={"automations-link"}
          testid={`${testid}.automations-link`}
          icon={<DiscoIcon icon={"lightning"} />}
          title={"Automations"}
          to={ROUTE_NAMES.ADMIN.AUTOMATIONS}
        />
      )
    if (permissions.has("reports.read") || permissions.has("products_reports.read"))
      items.push(
        <DiscoDropdownItem
          key={"insights-link"}
          testid={`${testid}.insights-link`}
          icon={<DiscoIcon icon={"chart-bar"} />}
          title={"Insights"}
          to={ROUTE_NAMES.ADMIN.INSIGHTS.ROOT}
        />
      )
    if (permissions.has("integrations.manage"))
      items.push(
        <DiscoDropdownItem
          key={"integrations-link"}
          testid={`${testid}.integrations-link`}
          icon={<DiscoIcon icon={"grid"} />}
          title={"Integrations"}
          to={ROUTE_NAMES.ADMIN.INTEGRATIONS.ROOT}
        />,
        <DiscoDropdownItem
          key={"DiscoAI-link"}
          testid={`${testid}.DiscoAI-link`}
          icon={<DiscoIcon icon={"stars"} />}
          title={"Disco AI"}
          to={ROUTE_NAMES.ADMIN.AI.ROOT}
        />
      )
    if (permissions.has("organization_settings.manage"))
      items.push(
        <DiscoDropdownItem
          key={"settings-link"}
          testid={`${testid}.settings-link`}
          icon={<DiscoIcon icon={"settings"} />}
          title={"Settings"}
          to={ROUTE_NAMES.ADMIN.SETTINGS.ROOT}
        />
      )

    return items
  }

  function getSecondSectionItems(): ReactNode[] {
    const items = []
    if (permissions.has("organization_settings.manage"))
      items.push(
        <DiscoDropdownItem
          key={"appearance-link"}
          testid={`${testid}.appearance-link`}
          icon={<DiscoIcon icon={"palette"} />}
          title={"Customize Appearance"}
          to={ROUTE_NAMES.ADMIN.APPEARANCE.ROOT}
        />
      )

    if (items.length && firstSectionItems.length)
      items.unshift(<DiscoDivider key={"divider-1"} />)
    return items
  }

  function getThirdSectionItems(): ReactNode[] {
    const items = []
    if (permissions.has("invitations.manage") && hasPlatformAccess)
      items.push(
        <CreateInvitationButton key={"invite-members"}>
          {({ onClick }) => (
            <DiscoDropdownItem
              testid={`${testid}.invite-members`}
              icon={<DiscoIcon icon={"user-add"} />}
              title={"Invite"}
              onClick={onClick}
            />
          )}
        </CreateInvitationButton>
      )

    if (
      activeProduct?.viewerIsManagerOrInstructor ||
      (permissions.has("members.manage") && hasPlatformAccess)
    )
      items.push(
        <DiscoDropdownItem
          testid={`${testid}.view-as-member`}
          icon={<DiscoIcon icon={"view"} />}
          title={`View as ${membersLabel.singular}`}
          onClick={startViewAsMember}
        />
      )

    if (items.length && (firstSectionItems.length || secondSectionItems.length))
      items.unshift(<DiscoDivider key={"divider-2"} />)
    return items
  }
}

export default AdminDashboardDropdown
