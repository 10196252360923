import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import UnsplashSearchResults from "@/media/unsplash/UnsplashSearchResults"
import { UnsplashSearch_TrackDownloadMutation } from "@/media/unsplash/__generated__/UnsplashSearch_TrackDownloadMutation.graphql"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import CircularProgressWithLabel from "@components/circular-progress-with-label/CircularProgressWithLabel"
import { initialCropModalState } from "@components/dropzone/FileDropzone"
import ImageCropModal from "@components/image/crop-modal/ImageCropModal"
import { ImageCropModalCropperProps } from "@components/image/crop-modal/util/imageCropModalTypes"
import useMultipartUploadMediaToS3, {
  MediaResult,
} from "@components/media/upload/hooks/useMultipartUploadMediaToS3"
import { DiscoInput, DiscoInputSkeleton } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { urlToFile } from "@utils/file/fileUtils"
import useDebounce from "@utils/hook/useDebounce"
import { useState } from "react"
import { graphql, useMutation } from "react-relay"
import { v4 as uuidv4 } from "uuid"

interface Props {
  onMediaSelect: (result: MediaResult) => void
  onClose: () => void
  cropperProps?: ImageCropModalCropperProps
}

export type UnsplashImageSearchResponse = {
  id: string
  imageThumbUrl: string
  imageUrl: string
  downloadUrl: string
  photoName: string
  photographerName: string
  photographerUrl: string
}

function UnsplashSearch(props: Props) {
  const { onClose, onMediaSelect, cropperProps } = props
  const [search, setSearch] = useState("abstract art")
  const { uploadMediaToS3, uploadProgress } = useMultipartUploadMediaToS3()
  const theme = useTheme()
  const classes = useStyles()
  const activeOrganization = useActiveOrganization()!
  const activeProduct = useActiveProduct()
  const [downloadUrl, setDownloadUrl] = useState("")
  const [cropModalState, setCropModalState] = useState(initialCropModalState)

  const handleSearch = useDebounce((s: string) => {
    setSearch(s)
  }, 1000)

  const [trackDownload] = useMutation<UnsplashSearch_TrackDownloadMutation>(graphql`
    mutation UnsplashSearch_TrackDownloadMutation($input: UnsplashTrackDownloadInput!) {
      trackUnsplashDownload(input: $input) {
        data
      }
    }
  `)

  return (
    <div>
      {uploadProgress ? (
        <div style={{ backgroundColor: theme.palette.primary.light }}>
          <CircularProgressWithLabel
            color={theme.palette.primary.main}
            value={uploadProgress ?? 0}
            className={classes.overlayButton}
          />
        </div>
      ) : (
        <>
          <DiscoInput
            onChange={(e) => {
              handleSearch(e.target.value)
            }}
            placeholder={"Enter a search term"}
            className={classes.searchInput}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
          />
          <UnsplashSearchResults
            search={search}
            onImageSelect={async (i: UnsplashImageSearchResponse) => {
              const file = await urlToFile(i.imageUrl, `${uuidv4()}.jpg`, "image/jpeg")
              setDownloadUrl(i.downloadUrl)
              setCropModalState({
                isOpen: true,
                imgSrc: i.imageUrl,
                file,
              })
            }}
          />
        </>
      )}
      {cropModalState.isOpen && (
        <ImageCropModal
          isOpen={cropModalState.isOpen}
          onClose={onClose}
          onCancel={() => {
            setCropModalState(initialCropModalState)
          }}
          imageSrc={cropModalState.imgSrc}
          title={"Crop Image"}
          cropperProps={cropperProps}
          onCrop={handleCrop}
        />
      )}
    </div>
  )

  async function handleCrop(base64: string) {
    // Close crop modal and process upload
    setCropModalState(initialCropModalState)
    const result = await uploadMediaToS3({
      mediaFile: await urlToFile(
        base64,
        cropModalState.file!.name,
        cropModalState.file!.type
      ),
      source: "unsplash",
    })
    // we need to call out to Unsplash in order for them to count the "download" of the image
    if (downloadUrl)
      trackDownload({
        variables: {
          input: {
            organizationId: activeOrganization.id,
            productId: activeProduct?.id,
            downloadUrl,
          },
        },
      })
    onMediaSelect(result)
    onClose()
  }
}

const useStyles = makeUseStyles((theme) => ({
  overlayButton: {
    position: "absolute",
    top: "50%",
    right: "50%",
    transform: "translateY(-50%) translateX(50%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(1.5),
    zIndex: theme.zIndex.raise2,
  },
  searchInput: {
    width: "100%",
  },
}))

export default Relay.withSkeleton({
  component: UnsplashSearch,
  skeleton: () => <DiscoInputSkeleton />,
})
