import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useViewersSlackConnectionRefetchableQuery } from "@/member-onboarding/hooks/__generated__/useViewersSlackConnectionRefetchableQuery.graphql"
import Relay from "@/relay/relayUtils"
import { graphql } from "react-relay"

function useViewersSlackConnection() {
  const activeOrganization = useActiveOrganization()
  const viewerMembershipId = activeOrganization?.viewerMembership?.id

  const [data, refetch] =
    Relay.useRefetchableQuery<useViewersSlackConnectionRefetchableQuery>(
      graphql`
        query useViewersSlackConnectionRefetchableQuery($membershipId: ID!) {
          membership: node(id: $membershipId) {
            ... on OrganizationMembership {
              __typename
              id
              slackUserId
              viewerSlackUserInvite {
                id
              }
            }
          }
        }
      `,
      { membershipId: viewerMembershipId || "" },
      { refetchInBackground: true }
    )
  const membership = Relay.narrowNodeType(data.membership, "OrganizationMembership")
  return { membership, refetch: () => refetch({ membershipId: membership?.id || "" }) }
}

export default useViewersSlackConnection
