import {
  MediaListItemFragment$data,
  MediaListItemFragment$key,
} from "@/media/add/list/__generated__/MediaListItemFragment.graphql"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { MediaResult } from "@components/media/upload/hooks/useMultipartUploadMediaToS3"
import { DiscoText } from "@disco-ui"
import DiscoContainerButton from "@disco-ui/button/DiscoContainerButton"
import DiscoImage from "@disco-ui/image/DiscoImage"
import DiscoVideo from "@disco-ui/video/DiscoVideo"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import { DATE_FORMAT } from "@utils/time/timeConstants"
import { formatDateWithOptions } from "@utils/time/timeUtils"
import classNames from "classnames"
import size from "filesize"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props {
  mediaItemKey: MediaListItemFragment$key
  onMediaSelect?: (result: MediaResult) => void
  onClose?: () => void
  testid: string
  viewType?: string
}
function MediaListItem(props: Props) {
  const { mediaItemKey, onMediaSelect, onClose, viewType } = props
  const classes = useStyles()
  const isMobile = useIsMobile()

  const media = useFragment<MediaListItemFragment$key>(
    graphql`
      fragment MediaListItemFragment on Asset {
        id
        name
        url
        mimeType
        fileType
        sizeBytes
        createdByUser {
          fullName
        }
        createdAt
      }
    `,
    mediaItemKey
  )

  const fileExtension = media.name.split(".").slice(-1)[0]

  return (
    <DiscoContainerButton
      onClick={() => handleSelectMedia(media)}
      testid={props.testid}
      className={classNames(classes.mediaListItemContent, {
        [classes.listItemContent]: viewType === "list",
      })}
    >
      <div
        className={classNames(classes.previewMedia, {
          [classes.listItem]: viewType === "list",
        })}
      >
        {media.fileType === "image" ? (
          <DiscoImage src={media.url} alt={media.id} className={classes.preview} />
        ) : media.fileType === "video" ? (
          <DiscoVideo
            src={media.url}
            playButton={isMobile ? undefined : { width: 18, height: 18, size: "small" }}
            radius={"medium"}
            maxHeight={isMobile ? "unset" : "75px"}
            disablePlay
            className={classes.preview}
          />
        ) : (
          <div className={classes.fileContainer}>
            <div className={classes.fileType}>
              <DiscoText variant={"body-sm-700"} color={"text.secondary"}>
                {fileExtension.toUpperCase()}
              </DiscoText>
            </div>
          </div>
        )}
      </div>
      {(viewType === "list" || media.fileType === "document") && (
        <div className={classes.mediaListItemDetailsContainer}>
          <div className={classes.mediaListItemNameContainer}>
            <DiscoText
              variant={"body-md-600"}
              color={"text.primary"}
              truncateText={1}
              className={classes.mediaListItemName}
              title={media.name}
            >
              {media.name}
            </DiscoText>
            <DiscoText
              variant={"body-md-500"}
              color={"text.secondary"}
              className={classes.fileSize}
            >
              {size.partial({ spacer: "", round: 1 })(media.sizeBytes).toUpperCase()}
            </DiscoText>
          </div>
          {viewType === "list" && (
            <DiscoText variant={"body-sm-500"} color={"text.secondary"}>
              {"added on "}
              {formatDateWithOptions({
                format: DATE_FORMAT.DEFAULT,
              })(new Date(media.createdAt))}
              {media.createdByUser?.fullName && (
                <>
                  {" by "}
                  {media.createdByUser?.fullName}
                </>
              )}
            </DiscoText>
          )}
        </div>
      )}
    </DiscoContainerButton>
  )

  function handleSelectMedia(result: MediaListItemFragment$data) {
    onMediaSelect?.(result)
    onClose?.()
  }
}

const useStyles = makeUseStyles((theme) => ({
  mediaListItemContent: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  listItemContent: {
    width: "100%",
  },

  mediaListItemDetailsContainer: {
    flexDirection: "column",
    marginLeft: theme.spacing(1),
    height: "100%",
    width: "100%",
    overflow: "hidden",
  },
  mediaListItemNameContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  mediaListItemName: {
    flexShrink: 1,
    flexGrow: 0,
  },
  fileSize: {
    flexShrink: 0,
    flexGrow: 0,
    backgroundColor: theme.palette.groovy.neutral[100],
    borderRadius: theme.measure.borderRadius.default,
    padding: theme.spacing(0.5, 0.5),
    marginLeft: theme.spacing(2),
  },
  previewMedia: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexShrink: 0,
    width: "132px",
    height: "75px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
    },
    borderRadius: theme.measure.borderRadius.medium,
    backgroundColor: theme.palette.groovy.neutral[100],
    "&:hover:not(:disabled)": {
      backgroundColor: theme.palette.groovy.neutral[100],
      border: "none",
    },
  },
  listItem: {
    width: "96px",
    height: "48px",
  },
  preview: {
    maxWidth: "100%",
    maxHeight: "100%",
    width: "auto",
    borderRadius: theme.measure.borderRadius.medium,
    margin: "auto",
  },
  fileContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
  },
  fileType: {
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.measure.borderRadius.big,
    color: theme.palette.text.secondary,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: 0,
    height: "48px",
    width: "48px",
    marginRight: 0,
  },
}))
export default MediaListItem
