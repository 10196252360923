import { useFormStore } from "@/core/form/store/FormStore"
import { CancelMembershipPlanButtonFragment$key } from "@/membership-plan/cancel/button/__generated__/CancelMembershipPlanButtonFragment.graphql"
import { CancelMembershipPlanButtonMutation } from "@/membership-plan/cancel/button/__generated__/CancelMembershipPlanButtonMutation.graphql"
import { calculateNextBillingDate } from "@/pricing/pricingUtils"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoTooltip } from "@disco-ui"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
} from "@disco-ui/button/OverridableDiscoButton"
import DiscoConfirmationModal from "@disco-ui/modal/DiscoConfirmationModal"
import { formatDateWithOptions } from "@utils/time/timeUtils"
import { useState } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

type Props = {
  children: OverridableDiscoButtonChildren
  membershipPlanKey: CancelMembershipPlanButtonFragment$key
}

function CancelMembershipPlanButton({ children, membershipPlanKey }: Props) {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const membershipPlan = useFragment<CancelMembershipPlanButtonFragment$key>(
    graphql`
      fragment CancelMembershipPlanButtonFragment on Product {
        id
        name
        isFreeMembership
        pricing {
          frequency
        }
        viewerCanCancelMembershipPlan {
          value
          message
        }
      }
    `,
    membershipPlanKey
  )

  const form = useFormStore<CancelMembershipPlanButtonMutation>(
    graphql`
      mutation CancelMembershipPlanButtonMutation($input: CancelMembershipPlanInput!) {
        response: cancelMembershipPlan(input: $input) {
          cancelledMembershipPlanId
          unenrolledProductMembershipId
          product {
            id
            viewerCanCancelMembershipPlan {
              value
              message
            }
          }
          errors {
            field
            message
          }
        }
      }
    `,
    { id: membershipPlan.id }
  )

  const { viewerCanCancelMembershipPlan } = membershipPlan
  const disabled = !viewerCanCancelMembershipPlan.value

  const nextBillingDate = calculateNextBillingDate(membershipPlan.pricing!.frequency)
  const description = nextBillingDate
    ? `You will continue getting access to the content until the end of the cycle - ${formatDateWithOptions()(
        nextBillingDate
      )}`
    : "You will continue getting access to the content."

  return (
    <>
      <DiscoTooltip
        disabled={viewerCanCancelMembershipPlan.value}
        content={viewerCanCancelMembershipPlan.message}
      >
        <OverridableDiscoButton onClick={openModal} disabled={disabled}>
          {children}
        </OverridableDiscoButton>
      </DiscoTooltip>

      <DiscoConfirmationModal
        testid={"CancelMembershipPlanConfirmationModal"}
        isOpen={isModalOpen}
        onClose={closeModal}
        title={`Are you sure you want to cancel this plan?`}
        description={description}
        typeToConfirmText={membershipPlan.name}
        confirmButtonProps={{
          onClick: cancelMembershipPlan,
          shouldDisplaySpinner: form.isSubmitting,
          children: "Yes, cancel plan",
        }}
        modalContentLabel={"Cancel Membership Plan"}
      />
    </>
  )

  function openModal() {
    setIsModalOpen(true)
  }

  function closeModal() {
    setIsModalOpen(false)
  }

  async function cancelMembershipPlan() {
    const { didSave } = await form.submit(form.state)

    if (!didSave) return
    displaySuccessToast({
      testid: `CancelMembershipPlanButton.success-toast`,
      message: "Successfully cancelled plan.",
    })
    closeModal()
  }
}

export default CancelMembershipPlanButton
