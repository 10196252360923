import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useStepperContext } from "@/core/context/StepperContext"
import { MemberOnboardingCustomProfileFieldQuestionFragment$key } from "@/member-onboarding/__generated__/MemberOnboardingCustomProfileFieldQuestionFragment.graphql"
import { MemberOnboardingCustomProfileFieldQuestionFragment_profileValue$key } from "@/member-onboarding/__generated__/MemberOnboardingCustomProfileFieldQuestionFragment_profileValue.graphql"
import MemberOnboardingButtons from "@/member-onboarding/buttons/MemberOnboardingButtons"
import useMemberOnboardingFormStore from "@/member-onboarding/hooks/useMemberOnboardingFormStore"
import ProfileSettingsPrivacyChip from "@/user/settings/subtabs/profile/ProfileSettingsPrivacyChip"
import WebFormInput from "@/web-form/filler/WebFormInput"
import { WEB_FORM_QUESTION_TYPE_PROMPTS } from "@/web-form/utils/webFormFillerUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import DiscoEditor from "@components/editor/DiscoEditor"
import {
  DiscoChip,
  DiscoFormControl,
  DiscoSection,
  DiscoText,
  GroovyTextColorKind,
} from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import { forwardRef } from "react"
import { graphql, useFragment } from "react-relay"
interface MemberOnboardingCustomProfileFieldQuestionProps extends TestIDProps {
  usageKey: MemberOnboardingCustomProfileFieldQuestionFragment$key
  profileValueKey?: MemberOnboardingCustomProfileFieldQuestionFragment_profileValue$key
}

export default forwardRef<
  HTMLDivElement,
  MemberOnboardingCustomProfileFieldQuestionProps
>(function MemberOnboardingCustomProfileFieldQuestion(
  { usageKey, testid = "MemberOnboardingCustomProfileFieldQuestion", profileValueKey },
  ref
) {
  const {
    steps: [activeStep, totalSteps],
  } = useStepperContext()!

  const activeOrganization = useActiveOrganization()!

  const usage = useFragment<MemberOnboardingCustomProfileFieldQuestionFragment$key>(
    graphql`
      fragment MemberOnboardingCustomProfileFieldQuestionFragment on ContentUsage {
        id
        content {
          id
          profileField {
            id
            isPrivate
            webFormQuestion {
              id
              type
              richEditorBody
              options {
                id
                label
              }
            }
          }
        }
        ...useMemberOnboardingFormStoreFragment
      }
    `,
    usageKey
  )

  const profileValue =
    useFragment<MemberOnboardingCustomProfileFieldQuestionFragment_profileValue$key>(
      graphql`
        fragment MemberOnboardingCustomProfileFieldQuestionFragment_profileValue on ProfileValue {
          webFormAnswer {
            body
            isSkipped
            selectedOptions
          }
        }
      `,
      profileValueKey || null
    )

  const profileField = usage?.content?.profileField

  const { form, submitItemCompletion } = useMemberOnboardingFormStore({
    usageKey: usage,
    customProfileFieldInput: {
      body: profileValue?.webFormAnswer?.body || "",
      selectedOptions: (profileValue?.webFormAnswer?.selectedOptions || []) as string[],
      webFormQuestionId: profileField?.webFormQuestion?.id || "",
    },
  })

  const classes = useStyles()
  const theme = useTheme()

  if (!usage || !profileField || !profileField.webFormQuestion) return null

  const questionNum = activeStep + 1

  return (
    <div ref={ref} data-testid={`${testid}.container`}>
      <DiscoSection className={classes.question} padding={2} marginBottom={2.25}>
        <div className={classes.header}>
          <DiscoText
            variant={"body-xs-500-uppercase"}
            color={"groovy.neutral.500"}
            component={"p"}
          >{`Question ${questionNum} of ${totalSteps}`}</DiscoText>
          {activeOrganization?.isMemberOnboardingRequired && (
            <DiscoChip label={"Required"} />
          )}
        </div>
        <div className={classes.questionNum}>
          <DiscoText component={"span"} color={"primary.contrastText"}>
            {questionNum}
          </DiscoText>
        </div>
        <div className={classes.questionContent}>
          <DiscoEditor
            defaultValue={profileField.webFormQuestion.richEditorBody}
            readOnly
            disableGutter
            backgroundColor={"transparent"}
          />
        </div>
      </DiscoSection>
      <div>
        <div className={classes.answerHeader}>
          <DiscoText
            variant={"body-sm-500"}
            color={getContrastColor("groovy.neutral.400")}
          >
            {WEB_FORM_QUESTION_TYPE_PROMPTS[profileField.webFormQuestion.type]}
          </DiscoText>
          <ProfileSettingsPrivacyChip isPrivate={profileField.isPrivate} />
        </div>
        <DiscoFormControl>
          {form.state.customProfileFieldInput && (
            <WebFormInput
              question={profileField.webFormQuestion}
              answer={form.state.customProfileFieldInput}
            />
          )}
        </DiscoFormControl>
      </div>
      <MemberOnboardingButtons onSubmit={submitItemCompletion} />
    </div>
  )

  function getContrastColor(color: GroovyTextColorKind): GroovyTextColorKind {
    const isDark = theme.palette.type === "dark"
    return isDark ? "groovy.onDark.200" : color
  }
})

const useStyles = makeUseStyles((theme) => ({
  question: {
    backgroundColor: "transparent",
    display: "grid",
    gridTemplateAreas: `'h h h'
    'n c c'`,
    gridTemplateColumns: "min-content 1fr 1fr",
    columnGap: theme.spacing(1.5),
    border: `1px solid ${theme.palette.groovy.neutral[300]}`,
  },
  header: {
    gridArea: "h",
    marginBottom: theme.spacing(1),
    display: "flex",
    gap: theme.spacing(1.5),
    alignItems: "center",
  },
  questionNum: {
    gridArea: "n",
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.measure.borderRadius.medium,
    padding: theme.spacing(1),
    height: "40px",
    minWidth: "40px",
    maxWidth: "fit-content",
    display: "grid",
    placeItems: "center",
  },
  questionContent: {
    gridArea: "c",
  },
  answerHeader: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    alignItems: "center",
    gap: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))
