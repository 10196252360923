import { useStepperContext } from "@/core/context/StepperContext"
import { MemberOnboardingButtonsProps } from "@/member-onboarding/buttons/MemberOnboardingButtons"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoButton, DiscoText, GroovyTextColorKind } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
type MemberOnboardingPreviousButtonProps = TestIDProps &
  Pick<MemberOnboardingButtonsProps, "previousTitle"> & {
    customPreviousAction?: VoidFunction
  }

function MemberOnboardingPreviousButton({
  testid = "MemberOnboardingPreviousButton",
  previousTitle = "Back to previous",
  customPreviousAction,
}: MemberOnboardingPreviousButtonProps) {
  const { previous, hasPrevious } = useStepperContext() || {}
  const classes = useStyles()
  const theme = useTheme()

  // Render nothing if there is no previous step
  if (!hasPrevious) return null
  return (
    <DiscoButton
      testid={testid}
      onClick={customPreviousAction || previous}
      color={"transparent"}
    >
      <DiscoText
        color={getContrastColor("groovy.neutral.400")}
        variant={"body-md-600"}
        className={classes.button}
      >
        {previousTitle}
      </DiscoText>
    </DiscoButton>
  )
  function getContrastColor(color: GroovyTextColorKind): GroovyTextColorKind {
    const isDark = theme.palette.type === "dark"
    return isDark ? "groovy.onDark.200" : color
  }
}
const useStyles = makeUseStyles({
  button: {
    minWidth: "max-content",
  },
})

export default MemberOnboardingPreviousButton
