import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useAuthUser } from "@/core/context/AuthUserContext"
import StepperContextProvider, {
  StepperContext,
  useStepperContext,
} from "@/core/context/StepperContext"
import { MemberOnboardingModalProps } from "@/member-onboarding/MemberOnboardingModal"
import { MemberOnboardingCustomProfileFieldsFragment$key } from "@/member-onboarding/__generated__/MemberOnboardingCustomProfileFieldsFragment.graphql"
import { MemberOnboardingCustomProfileFieldsQuery } from "@/member-onboarding/__generated__/MemberOnboardingCustomProfileFieldsQuery.graphql"
import MemberOnboardingButtons from "@/member-onboarding/buttons/MemberOnboardingButtons"
import useMemberOnboardingFormStore from "@/member-onboarding/hooks/useMemberOnboardingFormStore"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoEmptyState } from "@disco-ui"
import { Grid, Slide } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import { useEffect } from "react"
import { graphql, useFragment, useLazyLoadQuery } from "react-relay"
import MemberOnboardingCustomProfileFieldQuestion from "./MemberOnboardingCustomProfileFieldQuestion"

interface MemberOnboardingCustomProfileFieldsProps extends TestIDProps {
  usageKey: MemberOnboardingCustomProfileFieldsFragment$key
  mode: MemberOnboardingModalProps["mode"]
}

function MemberOnboardingCustomProfileFields({
  usageKey,
  mode,
}: MemberOnboardingCustomProfileFieldsProps) {
  const activeOrganization = useActiveOrganization()
  const { authUser } = useAuthUser({ required: true })
  const { next, previous, direction } = useStepperContext()!
  const { content, ...moduleUsage } =
    useFragment<MemberOnboardingCustomProfileFieldsFragment$key>(
      graphql`
        fragment MemberOnboardingCustomProfileFieldsFragment on ContentUsage
        @argumentDefinitions(includeDraft: { type: "Boolean", defaultValue: null }) {
          id
          ...useMemberOnboardingFormStoreFragment
          content {
            systemTaskKind
            children(includeDraft: $includeDraft) {
              edges {
                node {
                  id
                  status
                  viewerHasCompleted
                  content {
                    id
                    profileField {
                      id
                      ordering
                    }
                  }
                  ...MemberOnboardingCustomProfileFieldQuestionFragment
                }
              }
            }
          }
        }
      `,
      usageKey
    )

  const { submitItemCompletion: submitModuleCompletion } = useMemberOnboardingFormStore({
    usageKey: moduleUsage,
  })

  const { user } = useLazyLoadQuery<MemberOnboardingCustomProfileFieldsQuery>(
    graphql`
      query MemberOnboardingCustomProfileFieldsQuery($userId: ID!, $organizationId: ID!) {
        user: node(id: $userId) {
          ... on User {
            organizationMembership(organizationId: $organizationId) {
              profileValues {
                edges {
                  node {
                    id
                    profileField {
                      id
                    }
                    ...MemberOnboardingCustomProfileFieldQuestionFragment_profileValue
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      userId: authUser.id,
      organizationId: activeOrganization?.id || "",
    },
    { fetchPolicy: "network-only" }
  )

  const classes = useStyles()

  let usages = Relay.connectionToArray(content.children)
  if (mode === "admin-preview") usages = usages.filter((u) => u.status === "published")
  usages = usages.sort(
    (a, b) => a.content.profileField!.ordering - b.content.profileField!.ordering
  )

  useEffect(() => {
    /**
     * altho the module will be completed automatically on BE when the last field is answered, we can't query back the module completion from it's child.
     * Instead, manually submit the completion here so the hasViewerCompleted state is correct in store
     * (don't update optimistically for edge case where another field has been added since the module was loaded - we don't want to misfire confetti cannon)
     */
    if (!usages.find((u) => !u.viewerHasCompleted)) submitModuleCompletion()
  }, [usages, submitModuleCompletion])

  if (!moduleUsage || !content || content.systemTaskKind !== "custom_profile_field")
    return null

  const profileValues = Relay.connectionToArray(
    user?.organizationMembership?.profileValues
  )

  const fillableFields: {
    usage: (typeof usages)[0]
    profileValue?: (typeof profileValues)[0]
  }[] = usages.map((u) => ({
    usage: u,
    // profileValue may not exist if the user has never submitted the profile form before
    profileValue: profileValues.find(
      (v) => v.profileField.id === u.content.profileField!.id
    ),
  }))

  if (!fillableFields.length)
    return (
      <DiscoEmptyState
        subtitle={"Looks like there's nothing to complete here."}
        testid={"MemberOnboardingCustomProfileFields"}
        buttons={<MemberOnboardingButtons onSubmit={submitModuleCompletion} />}
      />
    )

  return (
    <StepperContextProvider
      direction={direction}
      steps={[
        direction === "next" ? 0 : fillableFields.length - 1,
        fillableFields.length,
      ]}
      onAfterLast={next}
      onBeforeFirst={previous}
    >
      <Grid container className={classes.container}>
        {fillableFields.map(({ usage, profileValue }, index) => (
          <StepperContext.Consumer key={usage.id}>
            {(ctx) => {
              const {
                steps: [activeStep],
                direction: pfDirection,
              } = ctx!

              return (
                <Slide
                  in={activeStep === index}
                  direction={
                    activeStep === index
                      ? pfDirection === "next"
                        ? "left"
                        : "right"
                      : pfDirection === "next"
                      ? "right"
                      : "left"
                  }
                  timeout={700}
                  mountOnEnter
                  unmountOnExit
                >
                  <Grid item className={classes.item}>
                    <MemberOnboardingCustomProfileFieldQuestion
                      usageKey={usage}
                      profileValueKey={profileValue}
                    />
                  </Grid>
                </Slide>
              )
            }}
          </StepperContext.Consumer>
        ))}
      </Grid>
    </StepperContextProvider>
  )
}

const useStyles = makeUseStyles({
  container: {
    display: "grid",
  },
  item: {
    // stack every item on top of each other so that they can slide in and out without vertical shifting
    gridRowStart: 1,
    gridColumnStart: 1,
  },
})

export default Relay.withSkeleton({
  skeleton: () => null,
  component: MemberOnboardingCustomProfileFields,
})
