/**
 * @generated SignedSource<<d7968065f56acd1b6823e47f47be2161>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ContentSystemTaskKind = "slack_connection" | "calendar_connection" | "custom_profile_field" | "community_agreement" | "default_profile_fields" | "onboarding_complete" | "onboarding_welcome" | "download_mobile_app" | "complete_profile" | "slack_invite" | "add_events_to_calendar" | "connect_slack" | "%future added value";
export type ContentType = "assignment" | "assignment_submission" | "post" | "occurrence" | "comment" | "module" | "text" | "video" | "attachment" | "embed" | "quiz" | "custom" | "survey" | "confirmation" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type MemberOnboardingContentFragment$data = {
  readonly id: string;
  readonly content: {
    readonly id: string;
    readonly type: ContentType;
    readonly systemTaskKind: ContentSystemTaskKind | null;
  };
  readonly " $fragmentSpreads": FragmentRefs<"MemberOnboardingContentHeaderFragment" | "MemberOnboardingGenericTaskFragment" | "MemberOnboardingCustomProfileFieldsFragment" | "MemberOnboardingSlackContentFragment" | "MemberOnboardingCalendarContentFragment" | "MemberOnboardingCommunityAgreementFragment" | "MemberOnboardingDefaultProfileFieldsFragment" | "MemberOnboardingCompleteFragment" | "MemberOnboardingDownloadMobileAppFragment">;
  readonly " $fragmentType": "MemberOnboardingContentFragment";
};
export type MemberOnboardingContentFragment$key = {
  readonly " $data"?: MemberOnboardingContentFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"MemberOnboardingContentFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "includeDraft"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "MemberOnboardingContentFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Content",
      "kind": "LinkedField",
      "name": "content",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "systemTaskKind",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MemberOnboardingContentHeaderFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MemberOnboardingGenericTaskFragment"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "includeDraft",
          "variableName": "includeDraft"
        }
      ],
      "kind": "FragmentSpread",
      "name": "MemberOnboardingCustomProfileFieldsFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MemberOnboardingSlackContentFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MemberOnboardingCalendarContentFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MemberOnboardingCommunityAgreementFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MemberOnboardingDefaultProfileFieldsFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MemberOnboardingCompleteFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MemberOnboardingDownloadMobileAppFragment"
    }
  ],
  "type": "ContentUsage",
  "abstractKey": null
};
})();

(node as any).hash = "35f16e466c6ac6783dc247341391ec33";

export default node;
