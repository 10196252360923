import makeUseStyles from "@assets/style/util/makeUseStyles"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"

function MemberOnboardingButtonsContainer({
  children,
  testid = "MemberOnboardingButtonsContainer",
  className,
}: React.PropsWithChildren<TestIDProps & { className?: string }>) {
  const classes = useStyles()
  return (
    <div
      className={classNames(classes.root, className)}
      data-testid={`${testid}.buttons-container`}
    >
      {children}
    </div>
  )
}
const useStyles = makeUseStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row-reverse",
    alignItems: "flex-start",
    justifyContent: "center",
    gap: theme.spacing(1),

    width: "fit-content",
    maxWidth: "50%",

    margin: theme.spacing(3, "auto"),

    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
}))

export default MemberOnboardingButtonsContainer
