import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useStepperContext } from "@/core/context/StepperContext"
import MemberOnboardingButtonsContainer from "@/member-onboarding/buttons/MemberOnboardingButtonsContainer"
import MemberOnboardingCustomActionButton from "@/member-onboarding/buttons/MemberOnboardingCustomActionButton"
import MemberOnboardingPreviousButton from "@/member-onboarding/buttons/MemberOnboardingPreviousButton"
import { DiscoButton, DiscoTooltip } from "@disco-ui"
import { ClassNameMap } from "@material-ui/core/styles/withStyles"
import { TestIDProps } from "@utils/typeUtils"
import { useState } from "react"

export interface MemberOnboardingButtonsProps extends TestIDProps {
  nextTitle?: string
  onNext?: VoidFunction
  previousTitle?: string
  customActionTitle?: string
  onSubmit?: () => Promise<{ didSave: boolean } | void>
  onSuccess?: VoidFunction
  onCustomAction?: VoidFunction
  customPreviousAction?: VoidFunction
  classes?: Partial<ClassNameMap<"buttonContainer">>
  disableNext?: boolean
  disableNextTooltip?: string
  allowAdminPreview?: boolean
}

function MemberOnboardingButtons({
  nextTitle = "Continue",
  onNext,
  previousTitle,
  customActionTitle,
  testid = "MemberOnboardingButtons",
  onSubmit,
  onSuccess,
  onCustomAction,
  customPreviousAction,
  classes: customClasses,
  disableNext = false,
  disableNextTooltip = "",
  allowAdminPreview = false,
}: MemberOnboardingButtonsProps) {
  const isOrgAdmin = useActiveOrganization()?.viewerIsOwnerOrAdmin
  const { next, hasPrevious, onBeforeFirst } = useStepperContext() || {}
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  return (
    <MemberOnboardingButtonsContainer
      testid={`${testid}.container`}
      className={customClasses?.buttonContainer}
    >
      <DiscoTooltip content={disableNext ? disableNextTooltip : ""}>
        <DiscoButton
          testid={`${testid}.next`}
          onClick={onNext || handleNext}
          shouldDisplaySpinner={isSubmitting}
          disabled={disableNext || isSubmitting}
        >
          {nextTitle}
        </DiscoButton>
      </DiscoTooltip>
      {onCustomAction && Boolean(customActionTitle) && (
        <MemberOnboardingCustomActionButton
          testid={`${testid}.customAction`}
          onCustomAction={onCustomAction}
          customActionTitle={customActionTitle!}
        />
      )}
      {(onBeforeFirst || hasPrevious || customPreviousAction) && (
        <MemberOnboardingPreviousButton
          testid={`${testid}.previous`}
          previousTitle={previousTitle}
          customPreviousAction={customPreviousAction}
        />
      )}
    </MemberOnboardingButtonsContainer>
  )

  async function handleNext() {
    setIsSubmitting(true)

    // skip completions as an org admin previewing this flow
    if (isOrgAdmin && !allowAdminPreview) {
      setIsSubmitting(false)
      return next?.()
    }

    const { didSave } = (await onSubmit?.()) || {}

    // Don't proceed if we failed to save
    // Note: custom submit function doesn't need to return didSave
    if (onSubmit && typeof didSave === "boolean" && !didSave) {
      return setIsSubmitting(false)
    }

    onSuccess?.()

    // otherwise, proceed to next step
    next?.()
    setIsSubmitting(false)
  }
}

export default MemberOnboardingButtons
