import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import GlobalSearchButton from "@/main/page/global-nav/GlobalSearchButton"
import NotificationCenterButton from "@/main/page/header/buttons/NotificationCenterButton"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import DiscoImage from "@disco-ui/image/DiscoImage"
import { useTheme } from "@material-ui/core/styles"
import useFeatureFlags from "@utils/hook/useFeatureFlags"

export default function GlobalTopBar() {
  const classes = useStyles()
  const activeOrganization = useActiveOrganization()
  const { globalSearch } = useFeatureFlags()
  const theme = useTheme()

  return (
    <div className={classes.topBar}>
      <div>
        {theme.palette.type === "dark" && activeOrganization?.darkLogo ? (
          <DiscoImage
            className={classes.logo}
            src={activeOrganization.darkLogo}
            alt={`${activeOrganization.name} dark logo`}
            data-testid={"GlobalTopBar.dark-logo"}
          />
        ) : (
          activeOrganization?.logo && (
            <DiscoImage
              className={classes.logo}
              src={activeOrganization.logo}
              alt={`${activeOrganization.name} logo`}
              data-testid={"GlobalTopBar.logo"}
            />
          )
        )}
      </div>
      <div className={classes.searchContainer}>
        {globalSearch && <GlobalSearchButton />}
      </div>
      <div className={classes.buttonsContainer}>
        <NotificationCenterButton testid={"GlobalTopBar.notification-center"} />
      </div>
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  topBar: {
    height: "72px",
    flexShrink: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  searchContainer: {
    width: "50%",
  },
  buttonsContainer: {
    display: "flex",
    gap: theme.spacing(0.5),
    marginRight: theme.spacing(1),
  },
  logo: {
    display: "block",
    maxHeight: "40px",
    width: "auto",
    maxWidth: "240px",
  },
}))
