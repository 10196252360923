import { DiscoButton, DiscoText } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
interface MemberOnboardingCustomActionButtonProps extends TestIDProps {
  onCustomAction: VoidFunction
  customActionTitle: string
}

function MemberOnboardingCustomActionButton({
  onCustomAction,
  customActionTitle,
  testid = "MemberOnboardingCustomActionButton",
}: MemberOnboardingCustomActionButtonProps) {
  return (
    <DiscoButton
      testid={testid}
      onClick={onCustomAction}
      variant={"outlined"}
      color={"grey"}
    >
      <DiscoText variant={"body-md-600"}>{customActionTitle}</DiscoText>
    </DiscoButton>
  )
}

export default MemberOnboardingCustomActionButton
