import {
  MobilePlatform,
  useDownloadMobileAppLink,
} from "@/apps/util/hooks/useDownloadMobileAppLink"
import InlineContentHeroSection from "@/content/inline/hero-section/InlineContentHeroSection"
import { MemberOnboardingDownloadMobileAppContentFragment$key } from "@/member-onboarding/__generated__/MemberOnboardingDownloadMobileAppContentFragment.graphql"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoLink, DiscoText } from "@disco-ui"
import DiscoTabs from "@disco-ui/tabs/DiscoTabs"
import { useTheme } from "@material-ui/core"
import { useState } from "react"
import QRCode from "react-qr-code"
import { graphql, useFragment } from "react-relay"

interface Props {
  usageKey: MemberOnboardingDownloadMobileAppContentFragment$key
}

function MemberOnboardingDownloadMobileAppContent({ usageKey }: Props) {
  const [activeTab, setActiveTab] = useState<MobilePlatform>("ios")
  const classes = useStyles()
  const theme = useTheme()
  const { iosLink, androidLink } = useDownloadMobileAppLink()

  const usage = useFragment<MemberOnboardingDownloadMobileAppContentFragment$key>(
    graphql`
      fragment MemberOnboardingDownloadMobileAppContentFragment on ContentUsage {
        id
        content {
          ...InlineContentHeroSectionFragment
        }
      }
    `,
    usageKey
  )

  return (
    <div className={classes.container}>
      <InlineContentHeroSection contentKey={usage.content} contentUsageId={usage.id} />

      <DiscoTabs
        testid={"MemberOnboardingDownloadMobileApp.tabs"}
        tabVariant={"grey-track"}
        className={classes.tabContainer}
        tabClassname={classes.tab}
        routes={[
          {
            label: "iOS",
            onClick: () => setActiveTab("ios"),
            active: activeTab === "ios",
            testid: "ios",
          },
          {
            label: "Android",
            onClick: () => setActiveTab("android"),
            active: activeTab === "android",
            testid: "android",
          },
        ]}
      />

      <QRCode
        size={200}
        value={activeTab === "ios" ? iosLink : androidLink}
        className={classes.qrCode}
      />

      <DiscoText>{"QR code did not work? Click the link below to download "}</DiscoText>

      <DiscoText component={"span"}>
        <DiscoLink
          color={theme.palette.primary.main}
          textDecoration={"underline"}
          href={iosLink}
          target={"_blank"}
        >
          {"Disco for iOS"}
        </DiscoLink>

        {" or "}

        <DiscoLink
          color={theme.palette.primary.main}
          textDecoration={"underline"}
          href={androidLink}
          target={"_blank"}
        >
          {"Disco for Android"}
        </DiscoLink>
      </DiscoText>
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  tabContainer: {
    marginTop: theme.spacing(4),
  },
  tab: {
    minWidth: "100px",
  },
  qrCode: {
    margin: theme.spacing(4, 0),
    padding: theme.spacing(1),
    border: `4px solid ${theme.palette.common.black}`,
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.measure.borderRadius.big,
  },
}))

export default MemberOnboardingDownloadMobileAppContent
