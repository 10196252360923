/**
 * @generated SignedSource<<e5079ff0895f57c37f3c8ea1cd4578c6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type useViewersSlackConnectionRefetchableQuery$variables = {
  membershipId: string;
};
export type useViewersSlackConnectionRefetchableQuery$data = {
  readonly membership: {
    readonly __typename: "OrganizationMembership";
    readonly id: string;
    readonly slackUserId: string | null;
    readonly viewerSlackUserInvite: {
      readonly id: string;
    } | null;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
};
export type useViewersSlackConnectionRefetchableQuery = {
  variables: useViewersSlackConnectionRefetchableQuery$variables;
  response: useViewersSlackConnectionRefetchableQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "membershipId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "membershipId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slackUserId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "SlackUserInvite",
  "kind": "LinkedField",
  "name": "viewerSlackUserInvite",
  "plural": false,
  "selections": [
    (v3/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useViewersSlackConnectionRefetchableQuery",
    "selections": [
      {
        "alias": "membership",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "type": "OrganizationMembership",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useViewersSlackConnectionRefetchableQuery",
    "selections": [
      {
        "alias": "membership",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "type": "OrganizationMembership",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "dbb3849772b915727c685ccd6dd7373d",
    "id": null,
    "metadata": {},
    "name": "useViewersSlackConnectionRefetchableQuery",
    "operationKind": "query",
    "text": "query useViewersSlackConnectionRefetchableQuery(\n  $membershipId: ID!\n) {\n  membership: node(id: $membershipId) {\n    __typename\n    ... on OrganizationMembership {\n      __typename\n      id\n      slackUserId\n      viewerSlackUserInvite {\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "9687d5be788ce7d200e7f6f0cd030e12";

export default node;
