import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import FormStore from "@/core/form/store/FormStore"
import { CreateInvitationFormState } from "@/invitation/create/form/CreateInvitationForm"
import {
  OrganizationRole,
  ProductRole,
} from "@/invitation/create/form/__generated__/CreateInvitationFormMutation.graphql"
import { InvitationFormRoleSelectFragment$key } from "@/invitation/create/form/fields/__generated__/InvitationFormRoleSelectFragment.graphql"
import { useOrganizationRoleLabel, useProductRoleLabel } from "@/role/roleUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoFormControl, DiscoSelect, SelectOption } from "@disco-ui"
import usePermissions from "@utils/hook/usePermissions"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { graphql, useFragment } from "react-relay"

type Props = TestIDProps & {
  form: FormStore<CreateInvitationFormState>
  productKey: InvitationFormRoleSelectFragment$key | null
  onlyAdminRoles?: boolean
}

function InvitationFormRoleSelect(props: Props) {
  const { testid = "InvitationFormRoleSelect", form, productKey, onlyAdminRoles } = props
  const orgMemberLabel = useOrganizationRoleLabel("member")
  const adminLabel = useOrganizationRoleLabel("admin")
  const ownerLabel = useOrganizationRoleLabel("owner")
  const productMemberLabel = useProductRoleLabel("member")
  const instructorLabel = useProductRoleLabel("instructor")
  const managerLabel = useProductRoleLabel("manager")
  const classes = useStyles()
  const orgPermissions = useActiveOrganization()!.viewerPermissions

  const product = useFragment<InvitationFormRoleSelectFragment$key>(
    graphql`
      fragment InvitationFormRoleSelectFragment on Product {
        id
        status
        ...usePermissionsFragment
      }
    `,
    productKey
  )
  const productPermissions = usePermissions(product)
  const fieldName = product ? "productRole" : "organizationRole"

  return (
    <DiscoFormControl
      error={Boolean(form.errorsByField[fieldName])}
      errorMessages={form.errorsByField[fieldName]}
      marginTop={0}
      marginBottom={0}
      disableFullWidth
      className={classes.formControl}
    >
      <DiscoSelect
        testid={`${testid}.role-select`}
        autoComplete={false}
        disableClearable
        options={getOptions()}
        value={form.state[fieldName]}
        onChange={(value) => {
          if (product) {
            form.state.productRole = value as ProductRole
          } else {
            form.state.organizationRole = value as OrganizationRole
          }
        }}
        classes={{ root: classes.select }}
        autoWidth
      />
    </DiscoFormControl>
  )

  function getOptions(): SelectOption<OrganizationRole | ProductRole>[] {
    const roles: SelectOption<OrganizationRole | ProductRole>[] = []

    // Product roles
    if (product) {
      if (!onlyAdminRoles) {
        roles.push({
          value: "member",
          title: productMemberLabel.singular,
          disabled: product?.status === "draft",
        })
      }
      if (productPermissions.has("product_instructors.manage")) {
        roles.push({ value: "instructor", title: instructorLabel.singular })
      }
      if (productPermissions.has("product_managers.manage")) {
        roles.push({ value: "manager", title: managerLabel.singular })
      }
      return roles
    }

    // Organization roles
    if (!onlyAdminRoles) {
      roles.push({ value: "member", title: orgMemberLabel.singular })
    }
    if (orgPermissions.has("organization_admins.manage")) {
      roles.push({ value: "admin", title: adminLabel.singular })
    }
    if (orgPermissions.has("organization_owners.manage")) {
      roles.push({ value: "owner", title: ownerLabel.singular })
    }
    return roles
  }
}

const useStyles = makeUseStyles({
  formControl: {
    flexShrink: 0,
  },
  select: {
    flexShrink: 0,
    width: "auto",
  },
})

export default observer(InvitationFormRoleSelect)
