import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoIcon, DiscoText } from "@disco-ui"
import { Grid } from "@material-ui/core"

function MemberOnboardingSlackRefreshInfo() {
  const classes = useStyles()

  return (
    <Grid
      container
      wrap={"nowrap"}
      justifyContent={"center"}
      alignItems={"center"}
      className={classes.container}
    >
      <DiscoIcon icon={"refresh-cw-02"} className={classes.refreshIcon} />
      <DiscoText variant={"body-xs-500"} color={"groovy.neutral.500"} marginLeft={1}>
        {"This screen will refresh when you've been added."}
      </DiscoText>
    </Grid>
  )
}
const useStyles = makeUseStyles((theme) => ({
  refreshIcon: {
    color: theme.palette.groovy.neutral[500],
  },
  container: {
    marginTop: theme.spacing(2),
  },
}))

export default MemberOnboardingSlackRefreshInfo
