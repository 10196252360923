/**
 * @generated SignedSource<<d309561ba2ac91a3f3b51bc6553dc958>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProductRegistrationAvailability = "public" | "private" | "hidden" | "%future added value";
export type ProductStatus = "draft" | "published" | "archived" | "unlisted" | "%future added value";
export type ProductType = "course" | "membership_plan" | "community_event" | "pathway" | "%future added value";
export type CreateInvitationFormQuery$variables = {
  productId: string;
};
export type CreateInvitationFormQuery$data = {
  readonly product: {
    readonly slug?: string;
    readonly name?: string;
    readonly type?: ProductType;
    readonly status?: ProductStatus;
    readonly registrationAvailability?: ProductRegistrationAvailability;
    readonly tmpAllowExternalRegistrations?: boolean;
    readonly " $fragmentSpreads": FragmentRefs<"InvitationFormCopyLinkField__Product" | "InvitationFormEmailsField__Product" | "usePermissionsFragment">;
  } | null;
};
export type CreateInvitationFormQuery = {
  variables: CreateInvitationFormQuery$variables;
  response: CreateInvitationFormQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "productId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "productId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registrationAvailability",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tmpAllowExternalRegistrations",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "viewerPermissions",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateInvitationFormQuery",
    "selections": [
      {
        "alias": "product",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "InvitationFormCopyLinkField__Product"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "InvitationFormEmailsField__Product"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "usePermissionsFragment"
              }
            ],
            "type": "Product",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateInvitationFormQuery",
    "selections": [
      {
        "alias": "product",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v8/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cover",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "LandingPage",
                "kind": "LinkedField",
                "name": "landingPage",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "metaImageUrl",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "metaTitle",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "metaDescription",
                    "storageKey": null
                  },
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "registrationType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "RegistrationPricing",
                "kind": "LinkedField",
                "name": "registrationPricing",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "basePrice",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "InlineFragment",
                    "selections": (v9/*: any*/),
                    "type": "Organization",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v9/*: any*/),
                    "type": "Product",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v9/*: any*/),
                    "type": "Event",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v9/*: any*/),
                    "type": "Comment",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v9/*: any*/),
                    "type": "Feed",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v9/*: any*/),
                    "type": "Post",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v9/*: any*/),
                    "type": "ContentUsage",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v9/*: any*/),
                    "type": "ChatChannel",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v9/*: any*/),
                    "type": "Dashboard",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v9/*: any*/),
                    "type": "AssignmentSubmission",
                    "abstractKey": null
                  }
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ],
            "type": "Product",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5cf13580fbd4054c7b0859d4b7d95404",
    "id": null,
    "metadata": {},
    "name": "CreateInvitationFormQuery",
    "operationKind": "query",
    "text": "query CreateInvitationFormQuery(\n  $productId: ID!\n) {\n  product: node(id: $productId) {\n    __typename\n    ... on Product {\n      slug\n      name\n      type\n      status\n      registrationAvailability\n      tmpAllowExternalRegistrations\n      ...InvitationFormCopyLinkField__Product\n      ...InvitationFormEmailsField__Product\n      ...usePermissionsFragment\n    }\n    id\n  }\n}\n\nfragment InvitationFormCopyLinkField__Product on Product {\n  id\n  slug\n  cover\n  name\n  description\n  landingPage {\n    metaImageUrl\n    metaTitle\n    metaDescription\n    id\n  }\n}\n\nfragment InvitationFormEmailsField__Product on Product {\n  id\n  slug\n  ...InvitationFormMemberFieldsFragment\n  ...InvitationFormRoleSelectFragment\n}\n\nfragment InvitationFormMemberFieldsFragment on Product {\n  id\n  registrationType\n  registrationPricing {\n    basePrice\n  }\n  slug\n}\n\nfragment InvitationFormRoleSelectFragment on Product {\n  id\n  status\n  ...usePermissionsFragment\n}\n\nfragment usePermissionsFragment on Node {\n  __isNode: __typename\n  __typename\n  ... on Organization {\n    viewerPermissions\n  }\n  ... on Product {\n    viewerPermissions\n  }\n  ... on Event {\n    viewerPermissions\n  }\n  ... on Comment {\n    viewerPermissions\n  }\n  ... on Feed {\n    viewerPermissions\n  }\n  ... on Post {\n    viewerPermissions\n  }\n  ... on ContentUsage {\n    viewerPermissions\n  }\n  ... on ChatChannel {\n    viewerPermissions\n  }\n  ... on Dashboard {\n    viewerPermissions\n  }\n  ... on AssignmentSubmission {\n    viewerPermissions\n  }\n}\n"
  }
};
})();

(node as any).hash = "3e818e9f5fade1283f1f96e67c815606";

export default node;
