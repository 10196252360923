import DiscoExpand from "@/expand/DiscoExpand"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoAlert, DiscoDivider, DiscoSection, DiscoText } from "@disco-ui"
import DiscoUnorderedList from "@disco-ui/list/unordered-list/DiscoUnorderedList"
import React from "react"

function MemberOnboardingSlackInstructions({
  instructions,
}: {
  instructions: Pick<InstructionCardProps, "steps" | "title">[]
}) {
  const classes = useInstructionStyles()
  return (
    <DiscoSection groovyDepths={"insideCard"} padding={0} className={classes.root}>
      {instructions.map((instruction, index) => (
        <React.Fragment key={instruction.title}>
          <InstructionCard {...instruction} number={index + 1} />
          {index !== instructions.length - 1 && (
            <DiscoDivider marginTop={0} marginBottom={0} />
          )}
        </React.Fragment>
      ))}
    </DiscoSection>
  )
}

const useInstructionStyles = makeUseStyles({
  root: {
    width: "100%",
  },
})

type InstructionCardProps = {
  number: number
  title: string
  steps: Step[]
}

function InstructionCard({ number, title, steps }: InstructionCardProps) {
  const classes = useCardStyles()
  return (
    <DiscoSection>
      <DiscoExpand
        header={
          <div className={classes.header}>
            <div className={classes.number}>
              <DiscoText color={"primary.contrastText"} variant={"body-xs"}>
                {number}
              </DiscoText>
            </div>
            <DiscoText variant={"heading-sm"}>{title}</DiscoText>
          </div>
        }
        body={steps.map((step, index) => (
          <DiscoSection
            key={step.title}
            marginBottom={index === steps.length - 1 ? undefined : 1.5}
            padding={0}
          >
            <Step {...step} />
          </DiscoSection>
        ))}
        isDefaultExpanded={false}
      />
    </DiscoSection>
  )
}

const useCardStyles = makeUseStyles((theme) => ({
  number: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "50%",
    width: 18,
    height: 18,
    display: "grid",
    placeItems: "center",
  },
  header: {
    display: "grid",
    alignItems: "center",
    gridTemplateColumns: "auto 1fr",
    columnGap: theme.spacing(1.5),
  },
}))

type Step = {
  title: string
  bullets: string[]
  note?: React.ReactNode
}

function Step({ title, bullets, note }: Step) {
  const classes = useStepStyles()
  return (
    <>
      <DiscoText variant={"body-md-600"} marginBottom={1.5}>
        {title}
      </DiscoText>
      <DiscoUnorderedList indent={4} listItems={bullets} />
      {Boolean(note) && (
        <DiscoAlert severity={"info"} message={note} classes={{ root: classes.root }} />
      )}
    </>
  )
}

const useStepStyles = makeUseStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1.5),
  },
}))

export default MemberOnboardingSlackInstructions
