import { DeleteMeetingProviderButtonQuery } from "@/meeting-provider/common/delete/button/__generated__/DeleteMeetingProviderButtonQuery.graphql"
import DeleteMeetingProviderForm from "@/meeting-provider/common/delete/form/DeleteMeetingProviderForm"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoButtonSkeleton, DiscoModal } from "@disco-ui"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
} from "@disco-ui/button/OverridableDiscoButton"
import { FC, useState } from "react"
import { useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"

interface Props {
  meetingProviderId: GlobalID
  children: OverridableDiscoButtonChildren
}

const DeleteMeetingProviderButton: FC<Props> = ({ meetingProviderId, children }) => {
  const [showModal, setShowModal] = useState(false)
  const { meetingProvider } = useLazyLoadQuery<DeleteMeetingProviderButtonQuery>(
    graphql`
      query DeleteMeetingProviderButtonQuery($id: ID!) {
        meetingProvider: node(id: $id) {
          ... on MeetingProvider {
            ...DeleteMeetingProviderFormFragment
          }
        }
      }
    `,
    { id: meetingProviderId },
    {
      fetchPolicy: "network-only",
    }
  )
  const classes = useStyles()

  if (!meetingProvider) return null

  return (
    <>
      <OverridableDiscoButton testid={"DeleteMeetingProviderButton"} onClick={openModal}>
        {children}
      </OverridableDiscoButton>
      <DiscoModal
        isOpen={showModal}
        onClose={closeModal}
        testid={"DisconnectZoomIntegrationModal"}
        modalContentLabel={"DisconnectZoomIntegrationModal"}
        title={"Disconnect Zoom Integration"}
        buttons
        body={
          <DeleteMeetingProviderForm
            meetingProviderKey={meetingProvider}
            onClose={closeModal}
          />
        }
        portalClassName={classes.modalPortal}
      />
    </>
  )

  function closeModal() {
    setShowModal(false)
  }

  function openModal() {
    setShowModal(true)
  }
}

const useStyles = makeUseStyles((theme) => ({
  modalPortal: {
    zIndex: theme.zIndex.modal + 1,
  },
}))

export default Relay.withSkeleton({
  component: DeleteMeetingProviderButton,
  skeleton: () => <DiscoButtonSkeleton width={"200px"} />,
})
