/**
 * @generated SignedSource<<ae91f7504e3ff26c756b24c949be6281>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PricingFrequency = "weekly" | "monthly" | "annually" | "%future added value";
export type PricingKind = "one_time" | "recurring" | "free" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type MembershipPlanDetailsCardFragment$data = {
  readonly id: string;
  readonly slug: string;
  readonly cover: string;
  readonly name: string;
  readonly description: string | null;
  readonly membershipBenefits: {
    readonly totalCount: number;
  } | null;
  readonly pricing: {
    readonly amountCents: number;
    readonly frequency: PricingFrequency | null;
    readonly kind: PricingKind;
    readonly " $fragmentSpreads": FragmentRefs<"pricingUtils_usePricingDisplayValue">;
  } | null;
  readonly " $fragmentType": "MembershipPlanDetailsCardFragment";
};
export type MembershipPlanDetailsCardFragment$key = {
  readonly " $data"?: MembershipPlanDetailsCardFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"MembershipPlanDetailsCardFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "hideDrafts"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "hideNonPublic"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "MembershipPlanDetailsCardFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cover",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "hideDrafts",
          "variableName": "hideDrafts"
        },
        {
          "kind": "Variable",
          "name": "hideNonPublic",
          "variableName": "hideNonPublic"
        }
      ],
      "concreteType": "MembershipBenefitNodeConnection",
      "kind": "LinkedField",
      "name": "membershipBenefits",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Pricing",
      "kind": "LinkedField",
      "name": "pricing",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "amountCents",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "frequency",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kind",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "pricingUtils_usePricingDisplayValue"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Product",
  "abstractKey": null
};

(node as any).hash = "6fe09fab2ba0d8130d3ec36823d702d2";

export default node;
