import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { isAdminConsolePath } from "@/core/route/util/routeUtils"
import usePageSidebar from "@/main/page/side-bar/usePageSidebar"
import HamburgerIcon from "@assets/iconsax/linear/menu.svg"
import { DiscoIconButton } from "@disco-ui"
import useDisclosure from "@utils/hook/useDisclosure"
import useSubscriptionStanding from "@utils/hook/useSubscriptionStanding"
import { createElement, MouseEventHandler } from "react"
import { useLocation } from "react-router-dom"

export interface SideBarRenderProps {
  open: boolean
  onClose: () => void
  menuListProps: { onClick: MouseEventHandler }
  variant: "temporary"
}

function TemporarySideBar() {
  const activeOrganization = useActiveOrganization()
  const location = useLocation()
  const isOnAdminPath = isAdminConsolePath(location.pathname)
  const subscriptionStanding = useSubscriptionStanding()
  const hasPlatformAccess = subscriptionStanding === "active"
  const {
    isOpen: isSideBarOpen,
    onToggle: toggleSideBar,
    onClose: onCloseSidebar,
  } = useDisclosure(false)
  const pageSidebar = usePageSidebar()

  // Prevent org admins without platform access from using the community side bar in mobile
  // never show the CommunitySideBar for users without community membership
  if ((!isOnAdminPath && !hasPlatformAccess) || !activeOrganization?.viewerMembership)
    return null

  return (
    <>
      <DiscoIconButton
        testid={`TemporarySideBar.MenuButton`}
        onClick={toggleSideBar}
        disableRipple
      >
        <HamburgerIcon width={24} height={24} />
      </DiscoIconButton>
      {pageSidebar &&
        createElement(pageSidebar, {
          onClose: onCloseSidebar,
          menuListProps: {
            onClick: onCloseSidebar,
          },
          open: isSideBarOpen,
          variant: "temporary",
        })}
    </>
  )
}

export default TemporarySideBar
