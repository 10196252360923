import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useLabel } from "@/core/context/LabelsContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import CopyIcon from "@/core/ui/iconsax/linear/copy.svg"
import defaultThumbnail from "@/core/ui/images/public-pages/default-thumbnail.png"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import ResetInvitationLinkButton from "@/invitation/create/form/fields/ResetInvitationLinkButton"
import { InvitationFormInviteLinkFieldQuery } from "@/invitation/create/form/fields/__generated__/InvitationFormInviteLinkFieldQuery.graphql"
import Relay from "@/relay/relayUtils"
import LinkPreview from "@components/link-preview/LinkPreview"
import { DiscoLink, DiscoText, DiscoTextButton } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { useCopyToClipboard } from "@utils/dom/domUtils"
import { observer } from "mobx-react-lite"
import { graphql } from "relay-runtime"

function InvitationFormInviteLinkField() {
  const activeOrganization = useActiveOrganization()!

  const [{ organization }, refetch] =
    Relay.useRefetchableQuery<InvitationFormInviteLinkFieldQuery>(
      graphql`
        query InvitationFormInviteLinkFieldQuery($id: ID!) {
          organization: node(id: $id) {
            ... on Organization {
              cover
              name
              description
              marketingPage {
                metaTitle
                metaDescription
                metaImageUrl
              }
              invitationLink {
                key
              }
            }
          }
        }
      `,
      {
        id: activeOrganization.id,
      }
    )

  const copyToClipboard = useCopyToClipboard()

  const membersLabel = useLabel("admin_member")
  const classes = useStyles()
  const theme = useTheme()

  if (!organization) return <></>

  const inviteLink = `${activeOrganization.primaryDomain}${
    ROUTE_NAMES.COMMUNITY.JOIN.ROOT
  }?inviteKey=${organization.invitationLink!.key}`

  const refetchInvitationLink = () => {
    refetch({ id: activeOrganization.id })
  }

  return (
    <>
      <div className={classes.linkDescriptionContainer}>
        <div className={classes.linkDescription}>
          <DiscoText variant={"body-sm"} color={"text.secondary"}>
            {"This invite link has no usage limit and does not expire."}
          </DiscoText>
        </div>
        <div className={classes.resetLink}>
          <ResetInvitationLinkButton
            organizationId={activeOrganization.id}
            onSubmit={refetchInvitationLink}
          />
        </div>
      </div>
      <DiscoTextButton
        testid={"InvitationFormInviteLinkField.copy-link-button"}
        onClick={() => copyToClipboard(inviteLink)}
        rightIcon={<CopyIcon />}
        classes={{
          root: classes.copyRoot,
          label: classes.copyLabel,
        }}
        color={theme.palette.text.primary}
      >
        {inviteLink}
      </DiscoTextButton>
      <LinkPreview
        image={
          organization?.marketingPage?.metaImageUrl ||
          organization?.cover ||
          defaultThumbnail
        }
        title={organization.marketingPage?.metaTitle || organization.name || ""}
        description={
          organization.marketingPage?.metaDescription || organization.description
        }
        url={inviteLink}
      />
      <div className={classes.linkContainer}>
        <DiscoText variant={"body-sm"} color={"text.secondary"}>
          {"Copied link will go to the "}
          <DiscoLink
            to={inviteLink}
            data-testid={`InvitationFormInviteLinkField.registration-page.link`}
            target={"_blank"}
            className={classes.registrationPageLink}
          >
            {" Registration Page"}
          </DiscoLink>

          {` for this Community and prompt registration as a ${membersLabel.singular}.`}
        </DiscoText>
      </div>
    </>
  )
}

const useStyles = makeUseStyles((theme) => ({
  copyRoot: {
    display: "flex",
    justifyContent: "space-between",
    cursor: "pointer",
    backgroundColor: theme.palette.groovy.neutral[100],
    borderRadius: theme.measure.borderRadius.big,
    padding: theme.spacing(1.5),
    marginBottom: theme.spacing(2.5),
    width: "100%",
    "&:hover": {
      backgroundColor: theme.palette.groovy.neutral[100],
    },
  },
  copyLabel: {
    textAlign: "left",
    wordBreak: "break-all",
    lineHeight: 1.25,
  },
  linkContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(2.5),
  },
  linkDescriptionContainer: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2.5),
  },
  registrationPageLink: {
    fontSize: "14px",
  },
  resetLink: {
    float: "right",
    width: "10%",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("xs")]: {
      width: "30%",
    },
  },
  linkDescription: {
    float: "left",
    width: "90%",
    [theme.breakpoints.down("xs")]: {
      width: "70%",
    },
  },
}))

export default observer(InvitationFormInviteLinkField)
