import { ResetInvitationLinkButtonMutation } from "@/invitation/create/form/fields/__generated__/ResetInvitationLinkButtonMutation.graphql"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { displayErrorToast, displayToast } from "@components/toast/ToastProvider"
import { DiscoButton, DiscoLink, DiscoModal } from "@disco-ui"
import { useState } from "react"
import { graphql } from "relay-runtime"

interface Props {
  organizationId: string
  onSubmit: () => void
  testid?: string
}

function ResetInvitationLinkButton(props: Props) {
  const { organizationId, onSubmit, testid = "ResetInvitationLinkButton" } = props
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showModal, seShowModal] = useState(false)

  const classes = useStyles()

  const mutation = Relay.useAsyncMutation<ResetInvitationLinkButtonMutation>(graphql`
    mutation ResetInvitationLinkButtonMutation($organizationId: ID!) {
      response: resetInvitationLink(organizationId: $organizationId) {
        node {
          key
        }
      }
    }
  `)

  return (
    <>
      <DiscoLink onClick={openModal} className={classes.resetLink}>
        {"Reset Link"}
      </DiscoLink>

      <DiscoModal
        testid={`${testid}.delete-modal`}
        isOpen={showModal}
        modalContentLabel={"Reset Invite Link"}
        onClose={closeModal}
        title={"Reset Invite Link?"}
        minHeight={"initial"}
        body={
          "Are you sure you want to reset the invite link? Resetting the link will generate a new link and the old one can no longer be used to gain access to your Community."
        }
        buttons={
          <>
            <DiscoButton
              color={"grey"}
              variant={"outlined"}
              onClick={closeModal}
              testid={`${testid}.cancel-button`}
            >
              {"Cancel"}
            </DiscoButton>
            <DiscoButton
              onClick={handleDelete}
              disabled={isSubmitting}
              color={"error"}
              shouldDisplaySpinner={isSubmitting}
              testid={`${testid}.confirm-button`}
            >
              {"Reset Link"}
            </DiscoButton>
          </>
        }
      />
    </>
  )

  function openModal() {
    seShowModal(true)
  }

  function closeModal() {
    seShowModal(false)
  }

  async function handleDelete() {
    setIsSubmitting(true)
    const res = await mutation({
      organizationId,
    })

    if (res) {
      displayToast({ message: "Invite link reset successfully." })
      onSubmit()
      closeModal()
    } else {
      displayErrorToast("Something went wrong, please try again later.")
    }
    setIsSubmitting(false)
  }
}

const useStyles = makeUseStyles(() => ({
  resetLink: {
    fontSize: "14px",
  },
}))

export default ResetInvitationLinkButton
