import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useLabel } from "@/core/context/LabelsContext"
import FormStore from "@/core/form/store/FormStore"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { CreateInvitationFormState } from "@/invitation/create/form/CreateInvitationForm"
import InvitationFormMemberFields from "@/invitation/create/form/fields/InvitationFormMemberFields"
import InvitationFormRoleSelect from "@/invitation/create/form/fields/InvitationFormRoleSelect"
import { InvitationFormEmailsField__Product$key } from "@/invitation/create/form/fields/__generated__/InvitationFormEmailsField__Product.graphql"
import OrganizationMemberEmailMultiSelect, {
  OrganizationMemberEmailMultiSelectProps,
} from "@/organization/member/common/OrganizationMemberEmailMultiSelect"
import { GlobalID } from "@/relay/RelayTypes"
import {
  useOrganizationRoleDescription,
  useProductRoleDescription,
} from "@/role/roleUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoFormControl, DiscoInput, DiscoLink, DiscoText } from "@disco-ui"
import { toJS } from "mobx"
import { observer } from "mobx-react-lite"
import { useFragment } from "react-relay"
import { generatePath } from "react-router-dom"
import { graphql } from "relay-runtime"
interface Props {
  form: FormStore<CreateInvitationFormState>
  productKey?: InvitationFormEmailsField__Product$key | null
  testid?: string
  onlyAdminRoles?: boolean
  hideRoleSelect?: boolean
}

function InvitationFormEmailsField(props: Props) {
  const {
    form,
    productKey = null,
    testid = "InvitationFormEmailsField",
    onlyAdminRoles,
    hideRoleSelect = false,
  } = props
  const classes = useStyles()
  const activeOrganization = useActiveOrganization()!
  const experienceLabel = useLabel("admin_experience")
  const orgRoleDescription = useOrganizationRoleDescription(form.state.organizationRole)
  const productRoleDescription = useProductRoleDescription(form.state.productRole)

  const product = useFragment<InvitationFormEmailsField__Product$key>(
    graphql`
      fragment InvitationFormEmailsField__Product on Product {
        id
        slug
        ...InvitationFormMemberFieldsFragment
        ...InvitationFormRoleSelectFragment
      }
    `,
    productKey
  )

  const emailsOnly = form.state.organizationRole === "member"
  const isMemberInvitation =
    (form.state.organizationRole || form.state.productRole) === "member"

  return (
    <>
      <DiscoFormControl
        label={
          <DiscoText variant={"body-sm"} color={"text.secondary"}>
            {emailsOnly
              ? "Enter an email address followed by the enter key"
              : "Find a name or enter an email address followed by the enter key"}
          </DiscoText>
        }
        error={Boolean(form.errorsByField.emails)}
        errorMessages={form.errorsByField.emails}
        testid={`${testid}.multiselect`}
        marginTop={0}
        marginBottom={0}
      >
        <div className={classes.emailContainer}>
          <OrganizationMemberEmailMultiSelect
            testid={`${testid}.community-member-emails-textarea`}
            emails={toJS(form.state.emails)}
            userIds={toJS(form.state.userIds)}
            onChange={handleOnChange}
            roles={getRoles()}
            excludeProductMemberships={getExcludeProductMemberships()}
            // When inviting community member, show an email multi-select without the community
            // member options, and for other roles show member & email mutli-select
            emailsOnly={emailsOnly}
          />
          {!hideRoleSelect && (
            <InvitationFormRoleSelect
              testid={testid}
              form={form}
              productKey={product}
              onlyAdminRoles={onlyAdminRoles}
            />
          )}
        </div>
      </DiscoFormControl>
      {renderDescription()}
      <DiscoFormControl
        label={
          <DiscoText variant={"body-sm"} color={"text.secondary"}>
            {"Personalize the invite"}
          </DiscoText>
        }
        error={Boolean(form.errorsByField.message)}
        errorMessages={form.errorsByField.message}
        marginTop={3}
      >
        <DiscoInput
          data-testid={`${testid}.personalizedMessage-textarea`}
          name={"personalizedMessage-textarea"}
          value={form.state.message}
          onChange={(e) => (form.state.message = e.currentTarget.value)}
          placeholder={"Enter a personalized message"}
          multiline
          minHeight={"120px"}
        />
      </DiscoFormControl>
      {isMemberInvitation && (
        <InvitationFormMemberFields form={form} productKey={product} testid={testid} />
      )}
    </>
  )

  function handleOnChange(emails: string[], userIds?: GlobalID[]) {
    form.state.emails.replace(emails)
    if (userIds) form.state.userIds.replace(userIds)
  }

  function getExcludeProductMemberships(): OrganizationMemberEmailMultiSelectProps["excludeProductMemberships"] {
    // Only those who aren't already in the product or have a lesser role
    switch (form.state.productRole) {
      case "manager":
        return {
          productId: form.state.productId!,
          roles: ["manager"],
        }
      case "instructor":
        return {
          productId: form.state.productId!,
          roles: ["manager", "instructor"],
        }
      case "member":
        return { productId: form.state.productId! }
    }
    return undefined
  }

  function getRoles(): OrganizationMemberEmailMultiSelectProps["roles"] {
    // For community invites, only include people with a lesser role
    switch (form.state.organizationRole) {
      case "owner":
        return ["member", "admin"]
      case "admin":
        return ["member"]
    }
    return undefined
  }

  function renderDescription() {
    if (hideRoleSelect) return undefined
    if (product && isMemberInvitation)
      return (
        <DiscoText variant={"body-xs"} color={"text.secondary"}>
          {"Those invited will receive an email with a link to the "}
          <DiscoLink
            to={
              activeOrganization.primaryDomain +
              generatePath(ROUTE_NAMES.PRODUCT.REGISTRATION.ROOT, {
                productSlug: product.slug,
              })
            }
            data-testid={`InvitationFormCopyLinkField.registration-page.link`}
            target={"_blank"}
            textVariant={"body-xs"}
          >
            {"Registration Page"}
          </DiscoLink>
          {` for this ${product ? experienceLabel.singular : `Community`}.`}
        </DiscoText>
      )
    return (
      <DiscoText variant={"body-xs"} color={"text.secondary"}>
        {productRoleDescription || orgRoleDescription}
      </DiscoText>
    )
  }
}

const useStyles = makeUseStyles((theme) => ({
  emailContainer: {
    display: "flex",
    gap: theme.spacing(1),
    "& > :first-child": {
      flexGrow: 1,
    },
  },
}))

export default observer(InvitationFormEmailsField)
