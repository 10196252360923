import useSearchClient from "@/apps/util/hooks/useSearchClient"
import { useSearchContext } from "@/core/context/SearchContext"
import SearchHits from "@/search/search-hits/SearchHits"
import SearchFilters from "@/search/SearchFilters"
import SearchInput from "@/search/SearchInput"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoIcon, DiscoText } from "@disco-ui"
import DiscoContainerButton from "@disco-ui/button/DiscoContainerButton"
import { getContrastRatio, Popover } from "@material-ui/core"
import { useEffect, useRef, useState } from "react"

export default function GlobalSearchButton() {
  const [anchorEl, setAnchorEl] = useState<null | Element>(null)
  const { openSearch, closeSearch, isSearchOpen } = useSearchContext()
  const [width, setWidth] = useState<number | undefined>(0)
  const search = useSearchClient()
  const classes = useStyles({ width })
  const buttonRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (buttonRef.current) {
      const test = buttonRef.current
      const observer = new ResizeObserver((enteries) => {
        setWidth(enteries[0].contentRect.width)
      })
      observer.observe(test)
      return () => observer.unobserve(test)
    }
    return undefined
  }, [buttonRef])

  useEffect(() => {
    setAnchorEl(buttonRef.current)
  }, [buttonRef])

  if (!search) return null

  return (
    <>
      <div ref={buttonRef}>
        <DiscoContainerButton
          testid={"GlobalSearchButton"}
          onClick={openSearch}
          className={classes.button}
        >
          <DiscoIcon icon={"search"} className={classes.icon} />
          <DiscoText variant={"body-md-500"} className={classes.text}>
            {"Search..."}
          </DiscoText>
        </DiscoContainerButton>
      </div>
      <Popover
        open={isSearchOpen}
        classes={{ paper: classes.popoverPaper }}
        onClose={closeSearch}
        disablePortal
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <div>
          <SearchInput />
          <SearchFilters />
          <SearchHits />
        </div>
      </Popover>
    </>
  )
}

type StyleProps = {
  width: number | undefined
}

const useStyles = makeUseStyles((theme) => {
  const isDarkBg = getContrastRatio("#fff", theme.palette.background.nav) > 3.5
  return {
    button: {
      height: 40,
      width: "100%",
      padding: theme.spacing(0, 1.5),
      display: "flex",
      gap: theme.spacing(1.5),
      alignItems: "center",
      borderRadius: theme.measure.borderRadius.big,
      border: `1px solid ${isDarkBg ? "rgba(255, 255, 255, 0.2)" : "rgba(0, 0, 0, 0.1)"}`,
      backgroundColor: isDarkBg ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.04)",
      transition: "background-color 0.2s ease",
      "&:hover": {
        backgroundColor: isDarkBg ? "rgba(255, 255, 255, 0.2)" : "rgba(0, 0, 0, 0.1)",
      },
    },
    icon: {
      color: isDarkBg ? "rgba(255, 255, 255, 0.5)" : "rgba(0, 0, 0, 0.3)",
    },
    text: {
      color: isDarkBg ? "rgba(255, 255, 255, 0.5)" : "rgba(0, 0, 0, 0.8)",
    },
    popoverPaper: ({ width }: StyleProps) => ({
      width: width || 0,
      maxHeight: "80%",
      transition: "unset !important",
      borderRadius: theme.measure.borderRadius.big,
      boxShadow: theme.palette.groovyDepths.raisedBoxShadow,
      zIndex: theme.zIndex.modal,
      border:
        theme.palette.type === "dark"
          ? `1.5px solid ${theme.palette.groovy.onDark[400]}`
          : "",
    }),
  }
})
