import { ContentSystemTaskKind } from "@/member-onboarding/__generated__/MemberOnboardingBannerQuery.graphql"
import { useQueryParams } from "@utils/url/urlUtils"

export type MemberOnboardingQueryParams = {
  memberOnboardingStep?: Extract<
    ContentSystemTaskKind,
    | "slack_connection"
    | "calendar_connection"
    | "custom_profile_field"
    | "community_agreement"
    | "default_profile_fields"
    | "onboarding_complete"
    | "onboarding_welcome"
  >
}
export function useMemberOnboardingQueryParams() {
  return useQueryParams<MemberOnboardingQueryParams>()
}
